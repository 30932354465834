import { StrAnyDict } from "../../iface/StrAnyDict";


export const build_info_config: StrAnyDict = {

    // TODO 1737 - remove props

    env: {
        type: 'string',
        defaultValue: null
    },

    playerName: {
        type: 'string',
        defaultValue: null
    },

    playerVersion: {
        type: 'string',
        defaultValue: null
    },

    buildTime: {
        type: 'string',
        defaultValue: null
    }
};
