import { StrAnyDict } from '../../iface/StrAnyDict';
import { ad_break_info_config } from './ad_break_info_config';
import { ad_item_config } from './ad_item_config';
import { build_info_config } from './build_info_config';
import { content_playback_state_config } from './content_playback_state_config';
import { player_dom_config } from './player_dom_config';
import { player_options_config } from './player_options_config';
import { presentation_state_config } from './presentation_state_config';


const config: StrAnyDict = {
    build_info_config: build_info_config,
    ad_break_info_config: ad_break_info_config,
    ad_item_config: ad_item_config,
    content_playback_state_config: content_playback_state_config,
    player_dom_config: player_dom_config,
    player_options_config: player_options_config,
    presentation_state_config: presentation_state_config,
}

export { config };
