
export enum Device {
    /** Unspecified Desktop */
    DESKTOP = 'Desktop',

    /** Android™ Phone */
    ANDROID_PHONE = 'Android Phone',

    /** Windows™ Phone */
    WINDOWS_PHONE = 'Windows Phone',

    /** Apple iPhone™ */
    IPHONE = 'iPhone',

    /** Apple iPod™ */
    IPOD = 'iPod',

    /** Apple iPad™ */
    IPAD = 'iPad',

    /** Amazon Kindle™ */
    KINDLE = 'Kindle',

    /** Unspecified Tablet */
    TABLET = 'Tablet',

    /** Unspecified Mobile */
    MOBILE = 'Mobile',

    /** Unspecified Embedded */
    EMBEDDED = 'Embedded',

    /** > Only Web0S 3.5 and above is supported */
    LG_SMART_TV = 'LG Smart Tv',

    /** Only Vizio Smartcast Devices */
    VIZIO_SMART_TV = 'Vizio Smart Tv',

    /** Samsung Tizen Devices */
    SAMSUNG_SMART_TV = 'Samsung Smart Tv',

    /** Comcast X1 Devices */
    COMCAST_X1 = 'Comcast X1',

    /** Xbox One X one S Devices */
    XBOX_ONE = 'Xbox One',

    /** Unspecified or unknown */
    UNKNOWN = 'UNKNOWN'
}
