import { Autoplay } from '../../enum/Autoplay';
import { LogLevel } from '../../enum/LogLevel';
import { PerformanceMode } from '../../enum/PerformanceMode';
import { StrAnyDict } from '../../iface/StrAnyDict';


export const player_options_config: StrAnyDict = {

    container: {
        type: ['object', 'string'],
        defaultValue: null,
    },

    dataTransformers: {
        type: 'object',
        defaultValue: null,
    },

    plugins: {
        type: 'object',
        defaultValue: null,
    },

    autoplay: {
        type: 'string',
        defaultValue: Autoplay.NONE
    },

    offsetTextOnControlsVisible: {
        type: 'boolean',
        defaultValue: false
    },

    id: {
        type: 'string',
        defaultValue: null
    },

    networkErrorRecovery: {
        type: 'object',
        defaultValue: null
    },

    performanceMode: {
        type: 'string',
        defaultValue: PerformanceMode.AUTO
    },

    renderTextTrackNatively: {
        type: 'boolean',
        defaultValue: true
    },

    nativePlugins: {
        type: 'object',
        defaultValue: null
    },

    playsInline: {
        type: 'boolean',
        defaultValue: true
    },

    uiConfig: {
        type: 'object',
        defaultValue: null
    },

    useNativeControls: {
        type: 'boolean',
        defaultValue: false
    },

    overrides: {
        type: 'object',
        defaultValue: {}
    },

    logLevel: {
        type: 'string',
        defaultValue: LogLevel.OFF
    },

    language: {
        type: 'string',
        defaultValue: null
    }
};
