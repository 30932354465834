import { AccessibilityAction } from '../enum/AccessibilityAction';
import { DiagnosticAction } from '../enum/DiagnosticAction';
import { NonPrintingKey } from '../enum/NonPrintingKey';
import { PluginPriority } from '../enum/PluginPriority';
import { AppMessageInterface, KeyCommandDef } from '../iface';
import { PluginConfigInterface } from '../iface/PluginConfigInterface';
import { KeyCode } from '../util/KeyCode';


export class AppResources {

    protected constructor() { }

    static blankVideoUrl: string = 'https://vidtech.cbsinteractive.com/h5/blanks/uvp_blank.mp4';

    static userKeyCmdDefs: KeyCommandDef[] = [
        {
            action: DiagnosticAction.DIAGNOSTIC_PLUGIN,
            primaryKey: 'd',
            keyCode: KeyCode.D,
            requireCtrl: true,
            requireShift: true
        },
        {
            action: DiagnosticAction.CONFIG_TO_CLIPBOARD,
            primaryKey: 'c',
            keyCode: KeyCode.C,
            requireCtrl: true,
            requireShift: true
        },
        {
            action: DiagnosticAction.CONFIG_TO_TEST_PAGE,
            primaryKey: 't',
            keyCode: KeyCode.T,
            requireCtrl: true,
            requireShift: true
        },
    ];

    static accessibilityKeyCmdDefs: KeyCommandDef[] = [
        {
            action: AccessibilityAction.ENTER_FULLSCREEN,
            primaryKey: 'f',
            keyCode: KeyCode.F,
        },
        {
            action: AccessibilityAction.INCREASE_VOLUME,
            primaryKey: NonPrintingKey.ARROW_UP,
            keyCode: KeyCode.UP_ARROW,
        },
        {
            action: AccessibilityAction.INCREASE_VOLUME,
            primaryKey: null,
            keyCode: KeyCode.MEDIA_INCREASE_VOLUME,
        },
        {
            action: AccessibilityAction.INCREASE_VOLUME,
            primaryKey: null,
            keyCode: KeyCode.MEDIA_INCREASE_VOLUME_ALT,
        },
        {
            action: AccessibilityAction.DECREASE_VOLUME,
            primaryKey: NonPrintingKey.ARROW_DOWN,
            keyCode: KeyCode.DOWN_ARROW,
        },
        {
            action: AccessibilityAction.DECREASE_VOLUME,
            primaryKey: null,
            keyCode: KeyCode.MEDIA_DECREASE_VOLUME,
        },
        {
            action: AccessibilityAction.DECREASE_VOLUME,
            primaryKey: null,
            keyCode: KeyCode.MEDIA_DECREASE_VOLUME_ALT,
        },
        {
            action: AccessibilityAction.SEEK_BACK,
            primaryKey: NonPrintingKey.ARROW_LEFT,
            keyCode: KeyCode.LEFT_ARROW,
        },
        {
            action: AccessibilityAction.SEEK_FORWARD,
            primaryKey: NonPrintingKey.ARROW_RIGHT,
            keyCode: KeyCode.RIGHT_ARROW,
        },
        {
            action: AccessibilityAction.TOGGLE_PLAY,
            primaryKey: NonPrintingKey.SPACE,
            keyCode: KeyCode.SPACE,
        },
        {
            action: AccessibilityAction.TOGGLE_PLAY,
            primaryKey: null,
            keyCode: KeyCode.MEDIA_TOGGLE_PLAY,
        },
        {
            action: AccessibilityAction.TOGGLE_MUTE,
            primaryKey: 'm',
            keyCode: KeyCode.M,
        },
        {
            action: AccessibilityAction.TOGGLE_MUTE,
            primaryKey: '-',
            keyCode: KeyCode.SUBTRACT,
        },
        {
            action: AccessibilityAction.TOGGLE_MUTE,
            primaryKey: null,
            keyCode: KeyCode.MEDIA_TOGGLE_MUTE,
        },
        {
            action: AccessibilityAction.TOGGLE_TT,
            primaryKey: 'c',
            keyCode: KeyCode.C,
        },
        {
            action: AccessibilityAction.STOP,
            primaryKey: null,
            keyCode: KeyCode.MEDIA_STOP
        },
        {
            action: AccessibilityAction.PLAYLIST_NEXT,
            primaryKey: null,
            keyCode: KeyCode.MEDIA_PLAYLIST_NEXT
        },
        {
            action: AccessibilityAction.PLAYLIST_PREV,
            primaryKey: null,
            keyCode: KeyCode.MEDIA_PLAYLIST_PREV
        },
    ];

    static nativePluginConfig: Record<string, PluginConfigInterface> = {
        diagnostic: {
            name: 'VtgDiagnosticPlugin',
            url: 'https://vidtech.cbsinteractive.com/cv-plugins/diagnostic/{{VER}}/diagnostic.min.js',
            qualifiedClassName: 'window.VtgDiagnosticPlugin',
            priority: PluginPriority.HIGH,
            options: null
        },
        replay: {
            name: 'VtgReplayPlugin',
            url: 'https://vidtech.cbsinteractive.com/cv-plugins/replay/{{VER}}/replay.min.js',
            qualifiedClassName: 'window.VtgReplayPlugin',
            priority: PluginPriority.LOW
        }
    }

    static messages: AppMessageInterface = {
        ABR_UNAVAILABLE: "Single bitrate stream detected, adaptive bitrate system is unavailable",
        ABR_MANUAL_SWITCHING_REACHED_LOWER_BOUNDS: "You have reached the lowest available quality for this presentation.",
        ABR_MANUAL_SWITCHING_REACHED_UPPER_BOUNDS: "You have reached the highest available quality for this presentation at this screen size",
        ABR_MIN_BITRATE_RESTRICTION_UNAVAILABLE: 'Min bitrate restrictions are not offered with this streaming library',
        AD_STALLED: 'Stalled ad detected',
        ADAPTER_UNAVAILABLE: 'Unable to play the requested resource - mediaUrl not recognized',
        ADAPTER_LIB_UNAVAILABLE: 'Unable to play the requested resource - External library is not loading',
        CAP_LEVEL_MAXBITRATE: 'Max bitrate setting will be ignored since capQualityToPlayerSize is enabled',
        CMS_RESOURCE_UNAVAILABLE: 'Resource unavailable',
        CMS_UNKNOWN: 'CMS not supported',
        CONTENT_PLAYBACK_SETTING_IGNORED: 'This API is only available after the CONTENT_START event has been dispatched. This setting will be ignored.',
        DAI_MISSING_ASSET_URL: 'Unexpected condition: valid assetUrl not found in DAI stream data',
        DAI_SDK_UNAVAILABLE: 'A DAI SDK is required for playback, but is not found',
        ENVIRONMENT_NOT_SUPPORTED: 'Unable to locate a global "window" object or suitable alternative',
        FAIRPLAY_LICENSE_ERROR: 'Issue with fairplay DRM and the provided license acquisition url',
        FAIRPLAY_APP_CERT_ERROR: 'Issue creating a fairplay keysession with the provided application certificate',
        FAIRPLAY_NO_CONTENT_ID: 'A valid content or asset id was not detected when creating fairplay drm session',
        FAIRPLAY_WEBKIT_ERROR: 'Issue with webkit and keysession creations while using fairplay drm.',
        FATAL_PLAYBACK_MEDIA_ERROR: 'Fatal media error, no option for recovery',
        FATAL_PLAYBACK_NETWORK_ERROR: 'Fatal network error, no option for recovery',
        IMA_SDK_MISSING: 'IMA SDK not found.',
        INVALID_PLAYLIST_INDEX: 'Index provided to Playlist is out of range',
        INVALID_RESOURCE: 'Resource configuration appears to be invalid',
        MANUAL_ABR_SWITCHING_UNAVAILABLE: 'Native HTML5 Video does not allow manual bitrate switching',
        PAUSING_LINEAR_LIVE_STREAM_NOT_ALLOWED: 'StreamType.LIVE is detected. Redirecting to the stop method since a DVR window is not detected.',
        PLUGIN_LOAD_ERROR: "Unable to load plugin from url",
        RESOURCE_NOT_SUPPORTED: 'Resource is not recognized or is currently unsupported',
        RETRY_PLAYBACK_MEDIA_ERROR: 'Media error detected',
        RETRY_PLAYBACK_NETWORK_ERROR: 'Network error detected',
        UNEXPECTED_CONDITION: 'An unexpected condition was encountered',
        UNSPECIFIED_ERROR: 'Unspecified error',
        VIDEO_PLAYBACK_UNAVAILABLE: 'Unable to locate a video element or suitable alternative',
        WEBKIT_KEY_ADDED_SUCCESS: "Webkit key added successfully",
        WEBKIT_MEDIA_KEYS_NOT_SUPPORTED: 'WebKitMediaKeys are not supported for this type of media on this user agent'
    }
}

