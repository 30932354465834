/* Copyright(c) 2006-2018 Futurescale, Inc. */
/* TypeScript port by Video Technology Group, CBSi Inc. */

import { ModelName } from "../enum/ModelName";
import { ProxyName } from "../enum/ProxyName";
import { ServiceName } from "../enum/ServiceName";
import { MediatorInterface, NotificationInterface, SystemServiceInterface } from "../iface";
import { ModelCollectionProxy } from '../model/ModelCollectionProxy';
import { Notifier } from "./Notifier";

export abstract class AbstractMediator extends Notifier implements MediatorInterface {

    private pViewControl: any;
    private readonly mediatorName: string;

    protected constructor(name: string, viewControl?: any) {
        super();

        this.pViewControl = null;
        this.mediatorName = name;

        viewControl && (this.viewControl = viewControl);
    }

    get name(): string {
        return this.mediatorName;
    }

    set viewControl(viewControl: any) {
        this.pViewControl = viewControl;
    }

    get viewControl(): any {
        return this.pViewControl;
    }

    get systemService(): SystemServiceInterface {
        return <SystemServiceInterface>this.facade.retrieveService(ServiceName.System);
    }

    getService<T>(name: ServiceName): T | null {
        return this.facade ? <T><unknown>(this.facade.retrieveService(name)) : null;
    }

    getModel<T>(name: ModelName): T {
        return <T>(this.getModelCollection().getModel(name));
    }

    getProxy<T>(name: ProxyName): T {
        return this.facade ? <T><unknown>(this.facade.retrieveProxy(name)) : null;
    }

    getModelCollection(): ModelCollectionProxy {
        return this.facade ? <ModelCollectionProxy>this.facade.retrieveProxy(ProxyName.ModelCollectionProxy) : null;
    }

    listNotificationInterests(): string[] {
        return [];
    }

    abstract handleNotification(notification: NotificationInterface): void;

    onRegister(): void {
        /* implementation optional */
    }

    onRemove(): void {
        this.pViewControl = null;
        super.onRemove();
    }
}
