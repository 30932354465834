import { AdViewabilityTrackingEventInterface, MoatInfoInterface, MoatTrackerInterface } from '../iface';
import { StrAnyDict } from '../iface/StrAnyDict';


interface ImaMoatEvent {
    start: string;
    firstquartile: string;
    midpoint: string;
    thirdquartile: string;
    complete: string;
    pause: string;
    resume: string;
    skip: string;
    volumechange: string;
    mute: string;
}

interface DaiMoatEvent {
    started: string;
    firstquartile: string;
    midpoint: string;
    thirdquartile: string;
    complete: string;
    controlpause: string;
    controlplay: string;
    controlvolumeset: string;
    controlmute: string;
    controlunmute: string;
}

interface MoatEventMap extends StrAnyDict {
    ima: ImaMoatEvent;
    dai_sdk: DaiMoatEvent;
}

interface MoatParamsInterface {
    level1: string;
    level2: string;
    level3: string;  // derived from getWrapperAdIds() || getAdId()
    level4: string;  // derived from getWrapperCreativeIds() || getCreativeId()
    slicer1: string;
    slicer2: string;
    slicer3: string;
    moatVASTSystem: string;
    moatVASTAdID: string;
    zMoatVGUID?: string;
    zMoatPT?: string;
}

interface MoatEventData {
    type: string;
    adVolume: number;
}

interface MoatTrackingObj {
    dispatchEvent(d: MoatEventData): void;
    adData: StrAnyDict;
}

export class MoatTracker implements MoatTrackerInterface {

    private MOAT_PARTNER_CODE: string = 'cbsiimajsint708425247896';

    private trackingObj!: MoatTrackingObj;

    // values are vpaid events
    private adContextToVpaidEventMap: MoatEventMap = {
        ima: {
            start: 'AdVideoStart',
            firstquartile: 'AdVideoFirstQuartile',
            midpoint: 'AdVideoMidpoint',
            thirdquartile: 'AdVideoThirdQuartile',
            complete: 'AdVideoComplete',
            pause: 'AdPaused',
            resume: 'AdPlaying',
            skip: 'AdSkipped',
            volumechange: 'AdVolumeChange',
            mute: 'AdVolumeChange'
        },
        dai_sdk: {
            started: 'AdVideoStart',
            firstquartile: 'AdVideoFirstQuartile',
            midpoint: 'AdVideoMidpoint',
            thirdquartile: 'AdVideoThirdQuartile',
            complete: 'AdVideoComplete',
            controlpause: 'AdPaused',
            controlplay: 'AdPlaying',
            controlvolumeset: 'AdVolumeChange',
            controlmute: 'AdVolumeChange',
            controlunmute: 'AdVolumeChange'
        }
    };

    constructor() { }

    destroy(): void {
        this.adContextToVpaidEventMap = null;
        this.trackingObj = null;
    }

    track(info: MoatInfoInterface): void {
        const context = info.context,
            adData = info.adInfo;

        const ids: MoatParamsInterface = {
            level1: '',
            level2: '',
            level3: adData.wrapperAdId,
            level4: adData.wrapperCreativeId,
            slicer1: 'vaw-can',
            slicer2: info.partner,
            slicer3: info.pageType,
            moatVASTSystem: context,
            moatVASTAdID: adData.wrapperAdId,
        };

        info.viewGuid !== '' && (ids.zMoatVGUID = info.viewGuid);
        info.pageType !== '' && (ids.zMoatPT = info.pageType);

        this.initMoat(
            info.adVideoContainer,
            ids,
            adData.adDuration,
            this.MOAT_PARTNER_CODE,
            null
        );
    }

    untrack(): void {
        this.trackingObj = null;
    }

    trackEvent(data: AdViewabilityTrackingEventInterface): void {
        const vpEvt: string = this.adContextToVpaidEventMap[data.context][data.eventName.toLowerCase()];

        if (!vpEvt || !this.trackingObj) {
            return;
        }

        this.trackingObj.dispatchEvent({
            type: vpEvt,
            adVolume: data.volume
        });
    }

    /**
     * Code supplied by Moat, slightly massaged for typescript
     * c ad container
     * d ids (includes partner code, etc)
     * e duration of ad
     * k partner code
     * l url to the video asset used to display the ad
    */
    private initMoat(c: HTMLElement, d: StrAnyDict, e: number, k: string, l: string = null): void {
        const g = document.createElement('script');
        let a: any = [];
        let f, h, sfx;

        const q: MoatTrackingObj = {
            adData: { ids: d, duration: e, url: l },
            dispatchEvent: function (b: MoatEventData) {
                this.sendEvent ? (a && (a.push(b), b = a, a = !1), this.sendEvent(b)) : a.push(b);
            }
        };
        sfx = '_moatApi' + Math.floor(1E8 * Math.random());

        try {
            f = c.ownerDocument;
            h = f.defaultView || (<any>f).parentWindow;
        }
        catch (b) {
            h = window;
        }

        h[sfx] = q;
        g.type = 'text/javascript';
        c && c.insertBefore(g, c.childNodes[0] || null);
        g.src = 'https://z.moatads.com/' + k + '/moatvideo.js#' + sfx;

        this.trackingObj = q;
    }

}
