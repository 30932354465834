
/**
 * Errors Events that produce error codes.
 * 
 * @see [[PlayerEvent.RESOURCE_ERROR]]
 * @see [[PlayerEvent.VIDEO_START_ERROR]]
 * @see [[PlayerEvent.VIDEO_PLAYBACK_ERROR]]
 * @see [[PlayerEvent.AD_ERROR]]
 */
export enum ErrorCode {

    /*
        ConcurrencyLimitViolation
        DomainBlocked
        Expired
        GeoLocationBlocked
        IpBlocked
        NotAvailableRestriction
        SatelliteProviderBlocked
        UnknownServerError
        UserAgentBlocked
    */

    //********************
    // RESOURCE ERROR
    //********************

    /**
     * Resource validation error, retrieval error/CMS error.
     * 
     * @see [[PlayerEvent.RESOURCE_ERROR]]
     */
    INVALID_RESOURCE_FORMAT =  '1000',
    // CMS-specific errors parsed from MPX response XML.

    /**
     * MPX: Responds with a 400 error typically 404.
     * - Retry logic is not performed.
     * - This error is fatal and playback will not begin.
     * 
     * @see [[PlayerEvent.RESOURCE_ERROR]]
     */
    RESOURCE_UNAVAILABLE = '1100',

    /**
     * MPX: Geo-restricted content.
     * - Retry logic is not performed.
     * - This error is fatal and playback will not begin.
     * 
     * @see [[PlayerEvent.RESOURCE_ERROR]]
     */
    GEO_LOCATION_BLOCKED =  '1101',

    /**
     * MPX: Domain is blocked from content access.
     * - Retry logic is not performed.
     * - This error is fatal and playback will not begin.
     * 
     * @see [[PlayerEvent.RESOURCE_ERROR]]
     */
    DOMAIN_BLOCKED = '1102',

    /**
     * MPX:  IP is blocked from content access.
     * - Retry logic is not performed.
     * - This error is fatal and playback will not begin.
     * 
     * @see [[PlayerEvent.RESOURCE_ERROR]]
     */
    IP_BLOCKED = '1103',

    /**
     * MPX:  Provider is blocked from content access.
     * - Retry logic is not performed.
     * - This error is fatal and playback will not begin.
     * 
     * @see [[PlayerEvent.RESOURCE_ERROR]]
     */
    SATELLITE_PROVIDER_BLOCKED = '1104',

    /**
     * MPX:  Provider is blocked from content access.
     * - Retry logic is not performed.
     * - This error is fatal and playback will not begin.
     * 
     * @see [[PlayerEvent.RESOURCE_ERROR]]
     */
    EXPIRED = '1105',

    /**
    * MPX: No response received; typically a failed XHR call with no explicit error.
    * - Retry logic is not performed.
    * - This error is fatal and playback will not begin.
    * 
    * @see [[PlayerEvent.RESOURCE_ERROR]]
    */
    SELECTOR_CALL_ERROR = '1106', 
    
    /**
    * The SDK is either absent or its loading has been blocked.
    * - Retry logic is not performed.
    * - This error is fatal and playback will not begin.
    * 
    * @see [[PlayerEvent.RESOURCE_ERROR]]
    */
    IMA_SDK_MISSING = '1200',

    /**
    * The SDK is either absent or its loading has been blocked.
    * - Retry is performed based on player options settings for networkErrorRecovery.
    * - This error is fatal and playback will not begin.
    * 
    * @see [[PlayerEvent.RESOURCE_ERROR]]    
    */
    DAI_SDK_MISSING = '1201',

    /**
    * Dash low level streaming engine code is not compiled into CVP.  
    * It is loaded "just in time".
    * - Retry is performed based on player options settings for networkErrorRecovery.
    * - This error is fatal and playback will not begin.
    * 
    * @see [[PlayerEvent.RESOURCE_ERROR]]
    */
    DASH_SDK_MISSING = '1202',

    /**
    * HLS low level streaming engine code is not compiled into CVP.  It is loaded "just in time". 
    * - Retry is performed based on player options settings for networkErrorRecovery.
    * - This error is fatal and playback will not begin.
    * 
    * @see [[PlayerEvent.RESOURCE_ERROR]]
    */
    HLS_SDK_MISSING = '1203',

    /**
    * Shaka Player low level streaming engine code is not compiled into CVP.  It is loaded "just in time". 
    * - Retry is performed based on player options settings for networkErrorRecovery.
    * - This error is fatal and playback will not begin.
    * 
    * @see [[PlayerEvent.RESOURCE_ERROR]]
    */
    SHAKA_SDK_MISSING = '1204',

    /**
    * Twitch Low Latency streaming engine code is not compiled into CVP.  It is loaded "just in time". 
    * - Retry is performed based on player options settings for networkErrorRecovery.
    * - This error is fatal and playback will not begin.
    * 
    * @see [[PlayerEvent.RESOURCE_ERROR]]
    */
    TWITCH_SDK_MISSING = '1205',

    //********************
    // VIDEO_START_ERROR
    //********************


    /**
     * HLS parser can not parse the manifest for some reason.
     * - Retry logic is not performed.
     * - This error is fatal and playback will not begin.
     * 
     * @see [[PlayerEvent.VIDEO_START_ERROR]]
     */
    HLSJS_PARSE_ERROR =  '2101',

    /**
     * DASH parser can not parse the manifest for some reason.
     * - Retry logic is not performed.
     * - This error is fatal and playback will not begin.
     * 
     * @see [[PlayerEvent.VIDEO_START_ERROR]]
     */
    DASHJS_PARSE_ERROR =  '2102',

    /**
     * Shaka Player parser can not parse the manifest for some reason.
     * - Retry logic is not performed.
     * - This error is fatal and playback will not begin.
     * 
     * @see [[PlayerEvent.VIDEO_START_ERROR]]
     */
    SHAKA_PARSE_ERROR =  '2103',

    /**
     * Twitch parser can not parse the manifest for some reason.
     * - Retry logic is not performed.
     * - This error is fatal and playback will not begin.
     * 
     * @see [[PlayerEvent.VIDEO_START_ERROR]]
     */
    TWITCH_PARSE_ERROR =  '2104',

    /**
     * Video.play() invoked illegally when a user click is 
     * required to initiate play
     * 
     * @see [[PlayerEvent.VIDEO_START_ERROR]]
     */
    USER_GESTURE_REQUIRED =  '2300',

    //********************
    // VIDEO_PLAYBACK_ERROR
    //********************
    /**
     * Native HTML5 playback network error of any type.  
     * Could not download something needed for playback. 
     * - Retry logic is not performed.
     * - This error is fatal and playback will stop.
     * 
     * @see [[PlayerEvent.VIDEO_PLAYBACK_ERROR]]
     */
    HTML5_NETWORK_ERROR = '3001',

    /**
     * HLS low level lib network error. Could not download 
     * something needed for playback.
     * - Thrown only after all retry logic has been performed.
     * - This error is fatal and playback will stop.
     * 
     * @see [[PlayerEvent.VIDEO_PLAYBACK_ERROR]]
     */
    HLSJS_NETWORK_ERROR = '3002',
    
    /**
     * DASH low level lib network error. Could not download
     * something needed for playback.
     * - Thrown only after all retry logic has been performed.
     * - This error is fatal and playback will stop.
     * 
     * @see [[PlayerEvent.VIDEO_PLAYBACK_ERROR]]
     */
    DASHJS_NETWORK_ERROR = '3003',
    
    /**
     * PlayStation WEBMAF lib network error. Could not download
     * something needed for playback.
     * - Retry logic is not performed.
     * - This error is fatal and playback will stop.
     * 
     * @see [[PlayerEvent.VIDEO_PLAYBACK_ERROR]]
     */
    WEBMAF_NETWORK_ERROR = '3004',

    /**
     * Shaka Player lib network error. Could not download
     * something needed for playback.
     * - Retry logic is not performed.
     * - This error is fatal and playback will stop.
     * 
     * @see [[PlayerEvent.VIDEO_PLAYBACK_ERROR]]
     */
    SHAKA_NETWORK_ERROR = '3005',

    /**
     * Twitch low level lib network error. Could not download
     * something needed for playback.
     * - Thrown only after all retry logic has been performed.
     * - This error is fatal and playback will stop.
     * 
     * @see [[PlayerEvent.VIDEO_PLAYBACK_ERROR]]
     */
    TWITCH_NETWORK_ERROR = '3006',
    
    /**
     * The fetching process for the media resource was aborted 
     * by the user agent at the user's request.
     * 
     * @see [[PlayerEvent.VIDEO_PLAYBACK_ERROR]]
     */    
    MEDIA_ABORTED = '3100',

    /**
     * Native HTML5 media resource was not suitable. 
     * - Thrown when media is not supported due to codec or other reason.
     * - Retry logic is not performed.
     *  - This error is fatal and playback will stop.
     * 
     * @see [[PlayerEvent.VIDEO_PLAYBACK_ERROR]]
     */
    HTML5_SRC_NOT_SUPPORTED = '3101',

    /**
     * HLS media resource was not suitable.
     * - Thrown when media is not supported due to codec or other reason.
     * - Retry logic is not performed.
     *  - This error is fatal and playback will stop.
     * 
     * @see [[PlayerEvent.VIDEO_PLAYBACK_ERROR]]
     */
    HLSJS_SRC_NOT_SUPPORTED = '3102',

    /**
     * DASH media resource was not suitable.
     * - Thrown when media is not supported due to codec or other reason.
     * - Retry logic is not performed.
     *  - This error is fatal and playback will stop.
     * 
     * @see [[PlayerEvent.VIDEO_PLAYBACK_ERROR]]
     */
    DASHJS_SRC_NOT_SUPPORTED = '3103',

    /**
     * Any media resource was not suitable.
     * - Thrown when media is not supported due to codec or other reason.
     * - Retry logic is not performed.
     *  - This error is fatal and playback will stop.
     * 
     * @see [[PlayerEvent.VIDEO_PLAYBACK_ERROR]]
     */
    WEBMAF_SRC_NOT_SUPPORTED = '3104',

    /**
     * Any media resource was not suitable.
     * - Thrown when media is not supported due to codec or other reason.
     * - Retry logic is not performed.
     * - This error is fatal and playback will stop.
     * 
     * @see [[PlayerEvent.VIDEO_PLAYBACK_ERROR]]
     */
    SHAKA_SRC_NOT_SUPPORTED = '3105',

    /**
     * Twitch media resource was not suitable.
     * - Thrown when media is not supported due to codec or other reason.
     * - Retry logic is not performed.
     *  - This error is fatal and playback will stop.
     * 
     * @see [[PlayerEvent.VIDEO_PLAYBACK_ERROR]]
     */
    TWITCH_SRC_NOT_SUPPORTED = '3106',
    
    /**
     * Native HTML5 media error.  An error occurred while decoding the media resource, 
     * after the resource was established to be usable.  
     * - Thrown when corrupted chunks of media are encountered.
     * - Retry logic is not performed.
     * - This error is fatal and playback will stop.
     * 
     * @see [[PlayerEvent.VIDEO_PLAYBACK_ERROR]]
     */
    HTML5_MEDIA_ERROR = '3200',

    /**
     * HLS media error. An error occurred while decoding the media resource,
     * after the resource was established to be usable.
     * - Thrown when corrupted chunks of media are encountered.
     * - Thrown only after all retry logic has been performed.
     * - This error is fatal and playback will stop.
     * 
     * @see [[PlayerEvent.VIDEO_PLAYBACK_ERROR]]
     */
    HLSJS_MEDIA_ERROR = '3201',

    /**
     * HLS.js trans-mux error.  
     * - Thrown when when memory allocation fails during remuxing
     * - Retry logic is not performed.
     * - This error is fatal and playback will stop.
     * 
     * @see [[PlayerEvent.VIDEO_PLAYBACK_ERROR]]
     */
    HLSJS_MUX_ERROR = '3202',

    /**
     * DASH media error. An error occurred while decoding the media resource,
     * after the resource was established to be usable.
     * - Thrown when corrupted chunks of media are encountered.
     * - Retry logic is not performed.
     * - This error is fatal and playback will stop.
     * 
     * @see [[PlayerEvent.VIDEO_PLAYBACK_ERROR]]
     */
    DASHJS_MEDIA_ERROR = '3203',

    /**
     * **NOT IMPLEMENTED YET**
     * 
     * PlayStation WEBMAF media error. An error occurred while decoding the media resource,
     * after the resource was established to be usable.
     * - Thrown when corrupted chunks of media are encountered.
     * - Retry logic is not performed.
     * - This error is fatal and playback will stop.
     * 
     * @see [[PlayerEvent.VIDEO_PLAYBACK_ERROR]]
     */
    WEBMAF_MEDIA_ERROR = '3204',

    /**
     * Shaka Player media error. An error occurred while decoding the media resource,
     * after the resource was established to be usable.
     * - Thrown when corrupted chunks of media are encountered.
     * - Retry logic is not performed.
     * - This error is fatal and playback will stop.
     * 
     * @see [[PlayerEvent.VIDEO_PLAYBACK_ERROR]]
     */
    SHAKA_MEDIA_ERROR = '3205',

    /**
     * Twitch media error. An error occurred while decoding the media resource,
     * after the resource was established to be usable.
     * - Thrown when corrupted chunks of media are encountered.
     * - Retry logic is not performed.
     * - This error is fatal and playback will stop.
     * 
     * @see [[PlayerEvent.VIDEO_PLAYBACK_ERROR]]
     */
    TWITCH_MEDIA_ERROR = '3206',

    /**
    * Fairplay Application Certificate DRM error.
    * - Thrown when any issue is found with the application cert url, the request or certificate decoding.
    * - Retry logic is not performed.
    * - This error is fatal and playback will not begin.
    * 
    * @see [[PlayerEvent.VIDEO_PLAYBACK_ERROR]]
    */
    FAIRPLAY_APP_CERT_ERROR = '3300',

    /**
     * Fairplay License Acquisition DRM error.
     * - Thrown when any issue is found with the License Server url, request or setting the license on the key system.
     * - Retry logic is not performed.
     * - This error is fatal and playback will not begin.
     * 
     * @see [[PlayerEvent.VIDEO_PLAYBACK_ERROR]]
     */
    FAIRPLAY_LIC_ERROR = '3301', 

    /**
     * Widevine or Playready DRM error.
     * - Thrown for any reason DRM failed during the initialization process.  
     * - Thrown only after all retry logic has been performed.
     * - This error is fatal and playback will not begin.
     * 
     * @see [[PlayerEvent.VIDEO_PLAYBACK_ERROR]]
     */
    DASHJS_DRM_ERROR = '3302',

    /**
     * Playready DRM error.
     * - Thrown for any reason DRM failed during the initialization process.
     * - Retry logic is not performed.
     * - This error is fatal and playback will not begin.
     * 
     * @see [[PlayerEvent.VIDEO_PLAYBACK_ERROR]]
     */
    WEBMAF_DRM_ERROR = '3303',

    /**
     * Widevine or Playready DRM error.
     * - Thrown for any reason DRM failed during the initialization process.
     * - Retry logic is not performed.
     * - This error is fatal and playback will not begin.
     * 
     * @see [[PlayerEvent.VIDEO_PLAYBACK_ERROR]]
     */
    SHAKA_DRM_ERROR = '3304',

    /**
     * All other HTML5 Video errors or any unknown error.
     * - Retry logic is not performed.
     * - This error is fatal and playback will stop.
     * 
     * @see [[PlayerEvent.VIDEO_PLAYBACK_ERROR]]
     */
    UNSPECIFIED_VIDEO_PLAYBACK_ERROR = '3400',

    /**
     * All other HLS errors or any unknown error.
     * - Retry logic is not performed.
     * - This error is fatal and playback will stop.
     * 
     * @see [[PlayerEvent.VIDEO_PLAYBACK_ERROR]]
     */
    UNSPECIFIED_HLSJS_ERROR = '3401',

    /**
     * All other DASH Video errors or any unknown error.
     * - Retry logic is not performed.
     * - This error is fatal and playback will stop.
     * 
     * @see [[PlayerEvent.VIDEO_PLAYBACK_ERROR]]
     */
    UNSPECIFIED_DASHJS_ERROR = '3402',

    /**
     * All other PlayStation WEBMAF errors or any unknown error.
     * - Retry logic is not performed.
     * - This error is fatal and playback will stop.
     * 
     * @see [[PlayerEvent.VIDEO_PLAYBACK_ERROR]]
     */
    UNSPECIFIED_WEBMAF_ERROR = '3403',

    /**
     * All other Shaka errors or any unknown error.
     * - Retry logic is not performed.
     * - This error is fatal and playback will stop.
     * 
     * @see [[PlayerEvent.VIDEO_PLAYBACK_ERROR]]
     */
    UNSPECIFIED_SHAKA_ERROR = '3404',

    /**
     * All other Twitch errors or any unknown error.
     * - Retry logic is not performed.
     * - This error is fatal and playback will stop.
     * 
     * @see [[PlayerEvent.VIDEO_PLAYBACK_ERROR]]
     */
    UNSPECIFIED_TWITCH_ERROR = '3405',

    //********************
    // AD_ERROR
    //********************

    /**
     * Applies to IMA only; if ad progress fails to advance for period of 5 seconds, 
     * the ad is considered 'stalled', the ad break is discarded and content play resumes if applicable.
     * 
     * @see [[PlayerEvent.AD_ERROR]]
     * @see [[PlayerEvent.AD_STALLED]]
     */
    AD_STALLED = '4000',        

    /**
     * An attempt to initialize the IMA AdsManager object resulted in an error. 
     * In this case, no ads will play for the session.  
     * 
     * **This is a rare error.**
     * 
     * @see [[PlayerEvent.AD_ERROR]]
     */
    IMA_INIT_FAILURE = '4100',

    /**
     * An error from the IMA SDK. There are numerous causes for an ad error; 
     * the AdErrorEvent emitted by the JS player can be interrogated for details.
     * 
     * @see [[PlayerEvent.AD_ERROR]]
     */
    IMA_AD_ERROR =  '4101',

    /**
     * An error from the IMA SDK. There are numerous causes for an ad error; 
     * the AdErrorEvent emitted by the JS player can be interrogated for details.
     * 
     * @see [[PlayerEvent.AD_ERROR]]
     */
    DAI_DATA_ERROR =  '4200',

    /**
     * A response was not received from the DAI back end.
     * 
     * @see [[PlayerEvent.AD_ERROR]]
     */
    DAI_NETWORK_ERROR = '4201',

    /**
     * DOC-TODO
     */
    AD_BLOCKING_DETECTED = '4300',
    
    //********************
    // UNEXPECTED
    //********************

    /**
     * Cases of developer error or other errors that do not fall into a well-described category.
     * @see [[PlayerEvent.RESOURCE_ERROR]]
     * @see [[PlayerEvent.VIDEO_START_ERROR]]
     * @see [[PlayerEvent.VIDEO_PLAYBACK_ERROR]]
     * @see [[PlayerEvent.AD_ERROR]]
     */
    UNEXPECTED_CONDITION =  '9999',
}