import { AppResources } from '../app/AppResources';
import { Util } from '../core/Util';
import { ErrorCode } from '../enum/ErrorCode';
import { ModelName } from '../enum/ModelName';
import { NotificationName } from '../enum/NotificationName';
import { NotificationType } from '../enum/NotificationType';
import { NotificationInterface } from '../iface';
import { PlayerOptionsInterface } from '../iface/PlayerOptionsInterface';
import { ResourceConfigurationInterface } from '../iface/ResourceConfigurationInterface';
import { StrAnyDict } from '../iface/StrAnyDict';
import { LogAwareSimpleCommand } from './LogAwareSimpleCommand';


export class TransformResourceCommand extends LogAwareSimpleCommand {

    execute(notification: NotificationInterface): void {
        const r: ResourceConfigurationInterface = notification.body.resource,
            data = notification.body.data || null,
            playerOpts = <PlayerOptionsInterface>this.getModel(ModelName.PlayerOptions),
            dtx: any = playerOpts.dataTransformers && playerOpts.dataTransformers[r.location.cms] && playerOpts.dataTransformers[r.location.cms].objectRef;

        Promise.resolve()
            .then(() => {
                let resource = r;

                if (dtx) {
                    const tx = Util.isFunction(dtx.transform) ? dtx : new dtx();
                    resource = (tx.transform && tx.transform(data, r)) || r;
                }

                return resource;
            })
            .then((resource) => {
                if (!resource || resource.error) {
                    throw new Error(resource && resource.data);
                }

                this.sendNotification(NotificationName.REGISTER_RESOURCE, { resource }, NotificationType.INTERNAL);
            })
            .catch((error) => {
                const code = this.getCodeForError(error);
                const message = error.message || AppResources.messages.CMS_RESOURCE_UNAVAILABLE;
                const fatal = true;
                this.sendNotification(NotificationName.RESOURCE_ERROR, { code, message, fatal }, NotificationType.INTERNAL);
            })

    }

    getCodeForError(obj: StrAnyDict): ErrorCode {
        if (obj.cms === 'mpx') {
            return this.getCodeForMpxError(obj);
        }
        else {
            return ErrorCode.RESOURCE_UNAVAILABLE;
        }
    }

    getCodeForMpxError(obj: StrAnyDict): ErrorCode {
        const exc = obj ? obj.exception : null;

        switch (exc) {
            case 'DomainBlocked':
                return ErrorCode.DOMAIN_BLOCKED;

            case 'GeoLocationBlocked':
                return ErrorCode.GEO_LOCATION_BLOCKED;

            case 'IpBlocked':
                return ErrorCode.IP_BLOCKED;

            case 'SatelliteProviderBlocked':
                return ErrorCode.SATELLITE_PROVIDER_BLOCKED;

            case 'Expired':
                return ErrorCode.EXPIRED;

            default:
                return ErrorCode.RESOURCE_UNAVAILABLE;
        }
    }
}
