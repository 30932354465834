import {AbstractMacroCommand} from '../../mvc/AbstractMacroCommand';
import {PrepModelCommand} from "./PrepModelCommand";
import {PrepViewCommand} from "./PrepViewCommand";
import {PrepServiceCommand} from "./PrepServiceCommand";
import {CreateDomCommand} from "./CreateDomCommand";
import {PrepTimerCommand} from './PrepTimerCommand';
import {PrepPluginsCommand} from './PrepPluginsCommand';

export class BootCommand extends AbstractMacroCommand {

    constructor() {
        super()
    }

    initializeMacroCommand(): void {
        this.addSubCommand(PrepModelCommand);
        this.addSubCommand(CreateDomCommand);
        this.addSubCommand(PrepServiceCommand);
        this.addSubCommand(PrepTimerCommand);
        this.addSubCommand(PrepViewCommand);
        this.addSubCommand(PrepPluginsCommand);
    }
}
