import axios from 'axios';
import { Util } from '../core/Util';
import { AsyncDataRequestOptionsInterface } from "../iface/AsyncDataRequestOptionsInterface";
import { StrAnyDict } from '../iface/StrAnyDict';
import { StrStrDict } from "../iface/StrStrDict";
import { XhrResponseInterface } from "../iface/XhrResponseInterface";
import { RecoveryEnabledRequest } from './RecoveryEnabledRequest';

export type AsyncDataLoadOptions = Omit<AsyncDataRequestOptionsInterface, 'onComplete'>;

/*
XHR package
    https://github.com/axios/axios
*/

export class AsyncDataRequest extends RecoveryEnabledRequest {

    static event: StrStrDict = {
        COMPLETE: "complete"
    }

    static statusMessages: StrStrDict = {
        '0': 'Request failed; blocked or timed out',
        '500': 'Server error encountered.',
        '404': 'Resource not found.',
        '403': 'Access denied.'
    }

    static load(options: AsyncDataLoadOptions): Promise<StrAnyDict> {
        return new Promise((resolve, reject) => {
            options.onComplete = ({ data }: StrAnyDict) => (data.error || data.timedOut) ? reject(data) : resolve(data);
            new AsyncDataRequest(<AsyncDataRequestOptionsInterface>options);
        });
    }

    constructor(options: AsyncDataRequestOptionsInterface) {
        super(options);

        if (this.opts.url) {
            this.createXhr();
        }
    }

    createXhr(): void {
        axios(this.opts)
            .then((response: XhrResponseInterface) => {
                if (response.headers['content-type'].indexOf('application/smil') >= 0) {
                    response.data = this.processSmilResponse(<string>response.data);
                }
                if (response.data === null) {
                    this.emitCompleteWithError('Unable to parse response.', response.config);
                }
                else {
                    // This is on a timeout to avoid triggering the catch clause 
                    // if a downstream exception occurs in response handling code.
                    setTimeout(() => { this.emitComplete(response); }, 1);
                }
            })
            .catch((error) => {
                if (this.shouldRetry()) {
                    this.incrementAttempts();
                    setTimeout(() => { this.createXhr(); }, this.retryInterval);
                }
                else {
                    const msg = error.response || AsyncDataRequest.statusMessages['0'];
                    this.emitCompleteWithError(msg, error.config);
                }
            });
    }

    private emitComplete(res: XhrResponseInterface) {
        this.emit(AsyncDataRequest.event.COMPLETE, {
            response: res.data,
            contentType: res.headers['content-type'],
            error: false
        });
    }

    private emitCompleteWithError(errResponse: XhrResponseInterface | string, cfg: AsyncDataRequestOptionsInterface): void {
        const msg = Util.isString(errResponse) ? errResponse : null,
            res = !msg ? <XhrResponseInterface>errResponse : null;

        this.emit(AsyncDataRequest.event.COMPLETE, {
            status: res && res.status,
            error: true,
            url: cfg && cfg.url || 'not available',
            message: msg || this.getMsg(res && res.status || 0, res && res.data || null)
        });
    }

    private getMsg(stat: number, errorData: any): string {
        let m = AsyncDataRequest.statusMessages['' + stat];

        if (!m) {
            m = Util.isString(errorData) ? errorData : "Unspecified error";
        }

        return "XhrDataRequest error: " + m;
    }

    private processSmilResponse(data: string): any {
        const parser = new DOMParser(),
            parsedData = parser.parseFromString(data, 'application/xml');

        return parsedData instanceof Document ? parsedData : null;
    }
}
