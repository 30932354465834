import {ServiceBase} from "./ServiceBase";
import {
    MediaCapabilitiesResultInterface,
    MediaCapabilitiesServiceInterface
} from "../iface";
import {MediaCapabilities} from "../util/MediaCapabilities";

export class MediaCapabilitiesService extends ServiceBase implements MediaCapabilitiesServiceInterface {

    private mediaCaps: MediaCapabilities;
    private static instance: MediaCapabilitiesService;

    constructor(name: string, mediaCapabilities: MediaCapabilities) {
        super(name);

        this.mediaCaps = mediaCapabilities;
    }

    static getInstance(name: string, mcInstance: MediaCapabilities): MediaCapabilitiesService {
        if (!this.instance) {
            this.instance = new MediaCapabilitiesService(name, mcInstance);
        }
        
        return this.instance;
    }

    get capabilities(): MediaCapabilitiesResultInterface {
        return this.mediaCaps.capabilities;
    }
}
