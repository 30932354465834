import { StrAnyDict } from '../../iface/StrAnyDict';
import { Transformer } from '../vo/Transformer';
import { Validator } from '../vo/Validator';


export const presentation_state_config: StrAnyDict = {

    skipAutoplayCheck: {
        type: 'boolean',
        defaultValue: false,
    },

    streamTime: {
        type: 'number',
        defaultValue: NaN,
        setterTransformer: (value: any) => {
            return Validator.isPositiveNumber(value) ? value : NaN;
        }
    },

    streamDuration: {
        type: 'number',
        defaultValue: NaN,
        setterTransformer: (value: any) => {
            return Validator.isPositiveNumber(value) ? value : NaN;
        }
    },

    autoplaySupport: {
        type: 'object',
        defaultValue: null
    },

    isAutoplay: {
        type: 'boolean',
        defaultValue: false,
    },

    isCurrentVideoAd: {
        type: 'boolean',
        defaultValue: false,
    },

    isFullscreen: {
        type: 'boolean',
        defaultValue: false,
    },

    isMuted: {
        type: 'boolean',
        defaultValue: false,
    },

    isMuteAtPlayStart: {
        type: 'boolean',
        defaultValue: false,
    },

    started: {
        type: 'boolean',
        defaultValue: false,
    },

    userHasUnmuted: {
        type: 'boolean',
        defaultValue: false,
    },

    volume: {
        type: 'number',
        defaultValue: 0.35,
        setterTransformer: (value: any) => {
            return Transformer.clampValue(value, 0, 1);
        }
    },

    usesSsai: {
        type: 'boolean',
        defaultValue: false,
    },

    streamId: {
        type: 'string',
        defaultValue: null,
    },

    suspended: {
        type: 'boolean',
        defaultValue: false,
    }
};
