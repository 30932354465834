import { StreamManager } from './StreamManager';
import { VODStreamRequest } from './VODStreamRequest';
import { StreamEvent } from './StreamEvent';
import { dai } from '../../dai';

const se = <unknown>StreamEvent;
const vr = <unknown>VODStreamRequest;
const sm = <unknown>StreamManager;

export const DaiProxyApi: dai.Sdk = {
    StreamManager: <dai.StreamManagerConstructor>sm,
    StreamEvent: <dai.StreamEventConstructor>se,
    VODStreamRequest: <dai.VODStreamRequestConstructor>vr
}