import { Emitter } from '../../core/Emitter';
import { LogLevel } from '../../enum/LogLevel';
import { VideoSurfaceInterface } from '../../iface';
import { EventInterface } from '../../iface/EventInterface';
import { LoggerInterface } from '../../iface/LoggerInterface';
import { PlaybackAdapterConfigInterface } from '../../iface/PlaybackAdapterConfigInterface';
import { StrAnyDict } from '../../iface/StrAnyDict';
import { TextTrackMode } from '../enum/TextTrackMode';
import { TextTrackSurfaceEvents } from '../enum/TextTrackSurfaceEvents';
import { VideoSurfaceEvents } from '../enum/VideoSurfaceEvents';
import { TextTrackSurface } from './TextTrackSurface';


export class Html5VideoSurface extends Emitter implements VideoSurfaceInterface {

    private logger: LoggerInterface;
    private pVideo: HTMLVideoElement;
    private timeAtLastBufferCheck: number = NaN;
    private textTrackSurface: TextTrackSurface = null;

    private pBuffering: boolean = false;
    private pVideoEventHandler = (e: Event) => this.onVideoElementEvent(e);
    private pTextTrackEventHandler = (e: EventInterface) => this.onTextTrackSurfaceEvent(e);

    constructor(video: HTMLVideoElement, config: PlaybackAdapterConfigInterface, logger: LoggerInterface, useTextTrackSurface: boolean = true) {
        super();

        this.pVideo = video;
        this.logger = logger;
        if (useTextTrackSurface) {
            this.textTrackSurface = new TextTrackSurface(video, config, logger);
        }
        this.logger.log(LogLevel.INFO, 'Html5VideoSurface created');
    }

    destroy() {
        this.removeEvents();
        if (this.textTrackSurface) {
            this.textTrackSurface.destroy();
        }
        this.textTrackSurface = null;
        this.pVideo = null;
        super.destroy();
    }

    play(): void {
        if (this.pVideo) {
            const p = this.pVideo.play();
            if (p) {
                p.then(() => { }).catch((e) => {
                    this.logger.warn('Exception caught in play() Promise: ' + e.message, LogLevel.WARN);
                });
            }
        }
    }

    pause(): void {
        this.pVideo && this.pVideo.pause();
    }

    seek(value: number): void {
        this.pVideo && (this.pVideo.currentTime = value);
    }

    addEvents(): void {

        for (let item in VideoSurfaceEvents) {
            this.pVideo.addEventListener((<StrAnyDict>VideoSurfaceEvents)[item], this.pVideoEventHandler);
        }

        if (!this.textTrackSurface) {
            return;
        }

        for (let item in TextTrackSurfaceEvents) {
            this.textTrackSurface.on((<StrAnyDict>TextTrackSurfaceEvents)[item], this.pTextTrackEventHandler);
        }
    }

    clearCue(): void {
        this.textTrackSurface?.clearCue();
    }

    get video(): HTMLVideoElement {
        return this.pVideo;
    }

    set src(value: string) {
        this.pVideo && (this.pVideo.src = value);
    }

    set volume(value: number) {
        this.pVideo && (this.pVideo.volume = value);
    }
    get volume(): number {
        return this.pVideo ? this.pVideo.volume : null;
    }

    set muted(value: boolean) {
        this.pVideo && (this.pVideo.muted = value);
    }
    get muted(): boolean {
        return this.pVideo ? this.pVideo.muted : null;
    }

    get paused(): boolean {
        return this.pVideo ? this.pVideo.paused : null;
    }

    get time(): number {
        return this.pVideo ? this.pVideo.currentTime : null;
    }

    get duration(): number {
        return this.pVideo ? this.pVideo.duration : null;
    }

    get state(): string {
        return '' //TODO add playback state trap on event and return string here.
    }

    set textTrackMode(mode: TextTrackMode) {
        if (!this.textTrackSurface) {
            return;
        }

        this.textTrackSurface.textTrackMode = mode;
    }

    set textTrack(track: TextTrack) {
        if (!this.textTrackSurface) {
            return;
        }

        this.textTrackSurface.textTrack = track;
    }

    get textTrack(): TextTrack {
        return this.textTrackSurface.textTrack;
    }

    set textTrackSrc(url: string) {
        if (!this.textTrackSurface) {
            return;
        }

        this.textTrackSurface.timeTextSrc = url;
    }

    get textTracks(): TextTrack[] {
        return this.textTrackSurface.textTracks;
    }

    get bufferLength(): number {
        let n = 0;

        if (this.pVideo && this.pVideo.buffered.length > 0) {
            n = this.pVideo.buffered.end(this.pVideo.buffered.length - 1) - this.pVideo.currentTime;
        }

        return n;
    }

    get buffering(): boolean {
        if (!this.paused && this.pVideo) {
            const threshold = (this.timeAtLastBufferCheck + .001);
            this.timeAtLastBufferCheck = this.pVideo.currentTime;
            this.pBuffering = this.pVideo.readyState === 1 && this.pVideo.currentTime < threshold;
        }

        return this.pBuffering;
    }

    get framerate(): number {
        //@ts-ignore  // does not cast
        const frames = this.pVideo.webkitDecodedFrameCount || this.pVideo.mozPresentedFrames || Number.NaN;
        return isNaN(frames) ? frames : frames / this.pVideo.currentTime;
    }

    get metrics(): StrAnyDict {
        let result = {
            droppedVideoFrames: Number.NaN,
            totalVideoFrames: Number.NaN
        };

        if (!this.pVideo) {
            return result;
        }

        const hasWebKit = ('webkitDroppedFrameCount' in this.pVideo) && ('webkitDecodedFrameCount' in this.pVideo);
        const hasQuality = ('getVideoPlaybackQuality' in this.pVideo);

        if (hasQuality) {
            result = this.pVideo.getVideoPlaybackQuality();
        }
        else if (hasWebKit) {
            result = {
                //@ts-ignore  // does not cast
                droppedVideoFrames: this.pVideo.webkitDroppedFrameCount,
                //@ts-ignore  // does not cast
                totalVideoFrames: this.pVideo.webkitDroppedFrameCount + this.pVideo.webkitDecodedFrameCount
            };
        }

        return result;
    }

    // From HTML5 video element 
    private onVideoElementEvent(e: Event): void {
        this.emit(e.type, e);
    }

    //From emitter-based TextTrackSurface
    private onTextTrackSurfaceEvent(e: EventInterface): void {
        this.emit(e.type, e.data);
    }

    private removeEvents(): void {
        for (let item in VideoSurfaceEvents) {
            this.pVideo.removeEventListener((<StrAnyDict>VideoSurfaceEvents)[item], this.pVideoEventHandler);
        }

        if (!this.textTrackSurface) {
            return;
        }

        for (let item in TextTrackSurfaceEvents) {
            this.textTrackSurface.on((<StrAnyDict>TextTrackSurfaceEvents)[item], this.pTextTrackEventHandler);
        }
    }
}
