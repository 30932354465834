import { NotificationInterface } from '../../iface';
import { LogAwareSimpleCommand } from '../LogAwareSimpleCommand';


export class PrepServiceCommand extends LogAwareSimpleCommand {

    execute(notification: NotificationInterface) {
        //const o: PlayerOptionsInterface = notification.body.playerOptions;
        //this.log(LogLevel.DEBUG, 'Services prepared');
    }
}
