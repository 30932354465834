import { AppResources } from '../app/AppResources';
import { Util } from '../core/Util';
import { ErrorCode } from '../enum/ErrorCode';
import { LogLevel } from '../enum/LogLevel';
import { MediatorName } from '../enum/MediatorName';
import { NotificationName } from '../enum/NotificationName';
import { NotificationType } from '../enum/NotificationType';
import { ProxyName } from '../enum/ProxyName';
import { NotificationInterface, ValidationInterface } from '../iface';
import { PlaylistInterface } from '../iface/PlaylistInterface';
import { ResourceConfigurationInterface } from '../iface/ResourceConfigurationInterface';
import { ResourceProxy } from '../model/ResourceProxy';
import { AppMediator } from '../view/AppMediator';
import { ResourceValidator } from './command-helpers/ResourceValidator';
import { LogAwareSimpleCommand } from './LogAwareSimpleCommand';


export class PrepResourceCommand extends LogAwareSimpleCommand {

    execute(notification: NotificationInterface) {
        switch (notification.name) {
            case NotificationName.PREP_RESOURCE_COLLECTION:
                this.prepResourceCollection(notification);
                break;

            case NotificationName.VALIDATE_RESOURCE:
                if (Util.isEmpty(notification.body.resource)) {
                    this.facade.sendNotification(NotificationName.RESOURCE_ERROR,
                        {
                            code: ErrorCode.INVALID_RESOURCE_FORMAT,
                            message: `${AppResources.messages.INVALID_RESOURCE}: Resource missing.`,
                            fatal: true,
                        },
                        NotificationType.INTERNAL
                    );
                    return;
                }
                this.validateResource(notification);
                break;

            case NotificationName.REGISTER_RESOURCE:
                this.registerResource(notification);
                break;
        }
    }

    private prepResourceCollection(notification: NotificationInterface): void {
        const resources: Partial<ResourceConfigurationInterface>[] = notification.body.resources;
        const appMed = <AppMediator>this.facade.retrieveMediator(MediatorName.APPLICATION);
        const playlist = <PlaylistInterface>(this.getProxy(ProxyName.Playlist));

        if (notification.body.clear === true && appMed && playlist.length) {
            appMed.killCurrentResource().then(() => {
                playlist.clear();
                playlist.addResources(resources);
                notification.body.start === true && playlist.start();
            });
        }
        else {
            playlist.addResources(resources);
            notification.body.start === true && playlist.start();
        }
    }

    private validateResource(notification: NotificationInterface): void {
        const resource: ResourceConfigurationInterface = notification.body.resource,
            resourceValidation: ValidationInterface = ResourceValidator.validate(resource);

        if (!resourceValidation.error) {
            this.facade.sendNotification(
                this.cmsDataRetrievalRequired(resource) ? NotificationName.RETRIEVE_RESOURCE : NotificationName.TRANSFORM_RESOURCE,
                { resource: resource },
                NotificationType.INTERNAL
            );
        }
        else {
            this.log(LogLevel.ERROR, AppResources.messages.INVALID_RESOURCE);
            this.facade.sendNotification(NotificationName.RESOURCE_ERROR, {
                message: `${AppResources.messages.INVALID_RESOURCE}: ${resourceValidation.msg}`,
                code: ErrorCode.INVALID_RESOURCE_FORMAT,
                fatal: true,
            }, NotificationType.INTERNAL);
        }
    }

    private registerResource(notification: NotificationInterface): void {
        const resource: ResourceConfigurationInterface = notification.body.resource,
            resourceProxy = new ResourceProxy(ProxyName.ResourceProxy, resource);

        this.facade.registerProxy(resourceProxy);
        this.facade.sendNotification(NotificationName.PLAY_RESOURCE, null, NotificationType.INTERNAL);
    }

    private cmsDataRetrievalRequired(r: ResourceConfigurationInterface): boolean {
        return !Util.isEmpty(r.location.cms) &&
            (!Util.isEmpty(r.location.tokenMap) || !Util.isEmpty(r.location.cmsUri));
    }

}
