import { AppResources } from '../../app/AppResources';
import { Util } from '../../core/Util';
import { ErrorCode } from '../../enum/ErrorCode';
import { LogLevel } from '../../enum/LogLevel';
import { MetadataCuepoint, VideoSurfaceInterface } from '../../iface';
import { AudioTrackInterface } from '../../iface/AudioTrackInterface';
import { LiveStreamInfoInterface } from '../../iface/LiveStreamInfoInterface';
import { LoggerInterface } from '../../iface/LoggerInterface';
import { PlaybackAdapterConfigInterface } from '../../iface/PlaybackAdapterConfigInterface';
import { QualityInterface } from '../../iface/QualityInterface';
import { ResourceLocationDrmInterface } from '../../iface/ResourceLocationDrmInterface';
import { ResourcePlaybackInterface } from '../../iface/ResourcePlaybackInterface';
import { StrAnyDict } from '../../iface/StrAnyDict';
import { DrmType } from '../../util/enum/DrmType';
import { DashEmsg } from '../enum/DashEmsg';
import { Playback } from '../enum/Playback';
import { PlaybackAdapterEvents } from '../enum/PlaybackAdapterEvents';
import { PlaybackAdapterType } from '../enum/PlaybackAdapterType';
import { TextTrackMode } from '../enum/TextTrackMode';
import { TextTrackSurfaceEvents } from '../enum/TextTrackSurfaceEvents';
import { VideoSurfaceEvents } from '../enum/VideoSurfaceEvents';
import { BitrateInfo, Dash, DashFactory, FragmentLoadingCompletedEvent, FragmentRequest, MediaInfo, MediaPlayer, MediaPlayerErrorEvent, PeriodSwitchEvent, QualityChangeRenderedEvent, QualityChangeRequestedEvent, StreamInitializedEvent, TrackChangeRenderedEvent } from '../interface/DashjsInterface';
import { DashjsOverrides } from '../interface/DashjsOverrides';
import { BasePlaybackAdapter } from './BasePlaybackAdapter';


export class DashjsAdapter extends BasePlaybackAdapter {

    private dash: Dash = (<any>window).dashjs;
    private dashFactory: DashFactory = (<any>window).dashjs; //Need better TS definition for this.  Decoration of create method on MediaPlayer. 
    private player!: MediaPlayer;

    protected pType = PlaybackAdapterType.DASHJS;
    private startTime = NaN;
    private seekTime = NaN;
    private audioTracks!: MediaInfo[];
    private ccMode: TextTrackMode;
    private periodSwitchTimeout: number;

    private dashjsEventMap: StrAnyDict = [
        {
            type: this.dash.MediaPlayer.events.STREAM_INITIALIZED,
            callback: (e: StreamInitializedEvent) => this.onStreamInitialized(e)
        },
        {
            type: this.dash.MediaPlayer.events.QUALITY_CHANGE_REQUESTED,
            callback: (e: QualityChangeRequestedEvent) => this.onQualityChangeRequested(e)
        },
        {
            type: this.dash.MediaPlayer.events.QUALITY_CHANGE_RENDERED,
            callback: (e: QualityChangeRenderedEvent) => this.onQualityChangeRendered(e)
        },
        {
            type: this.dash.MediaPlayer.events.FRAGMENT_LOADING_COMPLETED,
            callback: (e: FragmentLoadingCompletedEvent) => this.onFragmentLoadComplete(e)
        },
        {
            type: this.dash.MediaPlayer.events.TRACK_CHANGE_RENDERED,
            callback: (e: TrackChangeRenderedEvent) => this.onTrackChangeRendered(e)
        },
        {
            type: this.dash.MediaPlayer.events.ERROR,
            callback: (e: MediaPlayerErrorEvent) => this.onError(e)
        },
        {
            type: this.dash.MediaPlayer.events.PLAYBACK_SEEKED,
            callback: (e: PeriodSwitchEvent) => this.onSeekComplete(e)
        },
        {
            type: this.dash.MediaPlayer.events.PERIOD_SWITCH_STARTED,
            callback: (e: PeriodSwitchEvent) => this.onPeriodSwitchStarted(e)
        },
        {
            type: this.dash.MediaPlayer.events.PERIOD_SWITCH_COMPLETED,
            callback: (e: PeriodSwitchEvent) => this.onPeriodSwitchCompleted(e)
        },
        {
            type: 'public_keySystemAccessComplete',
            callback: (e: any) => this.onKeySystemAccessComplete(e)
        },
        {
            type: DashEmsg.GOOGLE_DAI,
            callback: (e: any) => this.onEmsgDai(e)
        }
    ];

    constructor(videoSurface: VideoSurfaceInterface, config: PlaybackAdapterConfigInterface, logger: LoggerInterface) {
        super(videoSurface, config, logger);

        this.logger.log(LogLevel.INFO, 'DashjsAdapter created');
    }

    ////////////////////
    //Public Methods
    ////////////////////
    initialize(): void {
        super.initialize();

        const location = this.config.resource.location;

        this.player = this.dashFactory.MediaPlayer().create();

        this.logger.log(LogLevel.INFO, `Dashjs version: ${this.player.getVersion()}`);

        if (location.drm && location.drm.enabled) {
            this.player.setProtectionData(this.getProtectionData(location.drm));
        }

        this.player.initialize(this.videoSurface.video, null, false);
        this.configurePlayer(this.playback);
        this.addEvents(this.player, this.dashjsEventMap);
    }

    destroy(): Promise<void> {
        clearTimeout(this.periodSwitchTimeout);
        this.removeEvents(this.player, this.dashjsEventMap);
        this.player.reset();
        this.player = null;
        this.dash = null;
        this.dashFactory = null;
        return super.destroy();
    }

    private configurePlayer(opts: ResourcePlaybackInterface): void {

        this.player.setLimitBitrateByPortal(opts.abr.capQualityToScreenSize);
        this.player.setLiveDelayFragmentCount(opts.liveEdgeSyncFragmentCount);
        this.player.getDebug().setLogToBrowserConsole(this.enableLogger);        
        this.player.enableForcedTextStreaming(true);
        //conditional options
        !isNaN(opts.abr.startBitrate) && this.player.setInitialBitrateFor(Playback.VIDEO, opts.abr.startBitrate / 1000);
        !isNaN(opts.abr.minBitrate) && (this.minBitrate = opts.abr.minBitrate);
        !isNaN(opts.abr.maxBitrate) && (this.maxBitrate = opts.abr.maxBitrate);
        this.startTime = opts.startTime;
        /*
            VTG-1626 - Bug in Dash.js, with Multiperiod and startTime in later periods, then seeking back to earlier period. Due to this, we do not set start time
            for Dash.js to handle, but rather administer a non gated seek at stream init event if startTime is not NaN, then only set to NaN in seek complete.
            !isNaN(opts.startTime) && (this.mediaUrl += `#s=${opts.startTime}`);
        */

        // performance settings
        const settings = this.config.performanceSettings;
        if (settings.forwardBufferLength != null) {
            this.player.setStableBufferTime(settings.forwardBufferLength);
        }
        if (settings.backBufferLength != null) {
            this.player.setBufferToKeep(settings.backBufferLength) //setBufferAheadToKeep look into this as well. 
        }
        if (settings.topQualityForwardBufferLength != null) {
            this.player.setBufferTimeAtTopQuality(settings.topQualityForwardBufferLength);
            this.player.setBufferTimeAtTopQualityLongForm(settings.topQualityForwardBufferLength);
        }

        //dev override section 
        const o: DashjsOverrides = this.config.resource.overrides?.dashjs;
        let fastSwitch = true,
            jumpGaps = true;

        if (!Util.isEmpty(o)) {
            if (Util.isBoolean(o.fastSwitchEnabled) && o.fastSwitchEnabled !== fastSwitch) {
                this.logger.log(LogLevel.INFO, `Overriding fastSwitchEnabled default value of ${fastSwitch} with ${o.fastSwitchEnabled}`)
                fastSwitch = o.fastSwitchEnabled;
            }
            if (Util.isBoolean(o.jumpGaps) && o.jumpGaps !== jumpGaps) {
                this.logger.log(LogLevel.INFO, `Overriding jumpGaps default value of ${jumpGaps} with ${o.jumpGaps}`)
                jumpGaps = o.jumpGaps;
            }
        }
        this.player.setFastSwitchEnabled(fastSwitch);
        this.player.setJumpGaps(jumpGaps);
    }

    protected loadMediaUrl(): Promise<void> {
        //when loading, until period change complete we will not have a duration so block time update until this point.
        this.blockTimeUpdateEvent = true;
        this.player.attachSource(this.mediaUrl);
        return super.loadMediaUrl();
    }

    seek(position: number): void {
        this.seekTime = position;
        this.blockTimeUpdateEvent = true;
        this.player.seek(position);
    }

    play(): void {
        this.player.play();
    }

    suspend(): void {
        this.player.scheduleWhilePaused = false;
    }

    resume(): void {
        this.player.scheduleWhilePaused = true;
    }

    ////////////////////
    //Accessors
    ////////////////////

    set audioTrack(track: AudioTrackInterface) {
        const audioTrack = Util.find(this.audioTracks, t => track.id == t.id);
        if (!audioTrack) {
            return;
        }
        this.player.setCurrentTrack(audioTrack);
    }

    set textTrack(track: TextTrack) {
        this.videoSurface.textTrack = track;
        this.player.setTextTrack(this.getTextTrackIndex(track));
    }

    get time(): number {
        return this.player.time();
    }

    get duration(): number {
        return this.player.duration();
    }

    set currentIndex(index: number) {
        this.player.setQualityFor(Playback.VIDEO, index);
    }

    get currentIndex(): number {
        return this.player.getQualityFor(Playback.VIDEO);
    }

    set autoQualitySwitching(value: boolean) {
        this.player.setAutoSwitchQualityFor(Playback.VIDEO, value);
    }

    get autoQualitySwitching(): boolean {
        return this.player.getAutoSwitchQualityFor(Playback.VIDEO);
    }

    set minBitrate(value: number) {
        //@ts-ignore //https://github.com/Dash-Industry-Forum/dash.js/issues/2918
        this.player.setMinAllowedBitrateFor(Playback.VIDEO, (value / 1000) - 1);
    }
    get minBitrate(): number {
        //@ts-ignore //https://github.com/Dash-Industry-Forum/dash.js/issues/2918
        return this.player.getMinAllowedBitrateFor(Playback.VIDEO) * 1000;
    }

    set maxBitrate(value: number) {
        this.player.setMaxAllowedBitrateFor(Playback.VIDEO, value / 1000);
    }
    get maxBitrate(): number {
        return this.player.getMaxAllowedBitrateFor(Playback.VIDEO) * 1000;
    }

    get manifestQualities(): QualityInterface[] {
        return this.player.getBitrateInfoListFor(Playback.VIDEO).map((item: BitrateInfo, index: number): QualityInterface => ({
            index,
            bitrate: item.bitrate,
            width: item.width,
            height: item.height,
            codec: null
        }));
    }

    get liveStreamInfo(): LiveStreamInfoInterface {

        const details: LiveStreamInfoInterface = this.liveStreamInfoVO;

        if (this.pIsLiveStream && this.lowLevelDvrDetails && this.time >= 0) {
            const d = <FragmentRequest>this.lowLevelDvrDetails;

            details.relativeTime = this.time;
            details.relativeDuration = this.duration;
            details.absoluteTime = Math.round(this.player.timeAsUTC() * 1000);
            details.absoluteDuration = Math.round(this.player.durationAsUTC() * 1000);
            details.dvrWindowSize = this.player.getDVRWindowSize();
            details.liveEdgeOffset = Math.floor(this.player.getLiveDelay() + d.duration);
            details.safeSeekingTime = Math.max(details.relativeDuration - details.dvrWindowSize, 0);
            details.safeSeekingDuration = Math.floor(details.relativeDuration - details.liveEdgeOffset);
            if (details.safeSeekingDuration < details.relativeTime) {
                details.safeSeekingDuration = Math.floor(details.relativeTime);
            }
            details.isPlayingLive = Math.ceil(details.relativeTime) >= details.safeSeekingDuration;
        }

        return details;
    }

    get fragmentType(): string {
        const track = this.player.getCurrentTrackFor('video');
        return track ? track.mimeType : '';
    }

    ////////////////////
    // Event Handlers
    ////////////////////

    private onStreamInitialized(e: StreamInitializedEvent): void {
        this.networkErrorRetryCount = 0;
        this.pIsLiveStream = this.player.isDynamic();
        this.emit(PlaybackAdapterEvents.MANIFEST_PARSED, { profile: this.manifestQualities });

        this.audioTracks = this.player.getTracksFor('audio');
        this.updateAudioTracks();

        if (this.startTime > 0) {
            this.blockTimeUpdateEvent = true;
            (<any>this.videoSurface).once(VideoSurfaceEvents.LOADED_METADATA, this.seek.bind(this, this.startTime));
            this.startTime = NaN;
        }
    }

    private onSeekComplete(e: PeriodSwitchEvent): void {
        const time = this.videoSurface.time;
        const seek = this.seekTime;

        if (seek > 0) {
            // When transitioning between periods, sometimes an initial seek
            // to the start of the period is performed internally by dashjs.
            // If this happens, re-seek to the previous selected time. 
            const d = (this.lowLevelDvrDetails && this.lowLevelDvrDetails.duration) || 6;

            if (Math.abs(seek - time) > d) {
                this.seek(seek);
                return;
            }
            else {
                this.seekTime = NaN;
            }
        }

        if (time > 0) {
            this.blockTimeUpdateEvent = false;
            this.emit(VideoSurfaceEvents.TIME_UPDATE);
        }
    }

    private onPeriodSwitchStarted(e: PeriodSwitchEvent): void {
        if (this.videoSurface.time > 0) {
            this.blockTimeUpdateEvent = true;
        }

        // The mode of the text tracks is changed internally when switching periods.
        // Capture the current value so it can be restored when the period completes.
        const { mode, enabledMode } = this.config.textTrackSettings;
        if (mode == enabledMode) {
            this.ccMode = mode;
        }
    }

    private onEmsgDai(e: any): void {

        if (!e && !e.event) {
            return;
        }

        const vo: MetadataCuepoint = {
            id: DashEmsg.GOOGLE_DAI,
            info: e.event.messageData,
            data: e.event
        }

        this.logger.log(LogLevel.DEBUG, `DAI - Event ID: ${e.event.id} Start time: ${e.event.presentationTime} Impression ID: ${e.event.messageData}`);

        this.emit(TextTrackSurfaceEvents.METADATA_CUEPOINT, vo);
    }

    private onPeriodSwitchCompleted(e: PeriodSwitchEvent): void {
        // Fix for issues where cues aren't added to text tracks after switching periods,
        // The timeout is for an issue where SmartTV implementations freeze up going into 
        // mid-rolls if the mode is applied too soon after a period switch.

        // TODO: Ideally this would be run on seekcomplete, but that event doesn't always 
        //       fire when periods change. Wait a full second to ensure the period has
        //       finished transitioning, and any subsequent seeks have completed.
        if (this.ccMode) {
            const index = this.getTextTrackIndex(this.videoSurface.textTrack);
            this.player.setTextTrack(-1);
            this.periodSwitchTimeout = setTimeout(() => {
                // Restore the text track mode.
                this.textTrackMode = this.ccMode;
                this.ccMode = null;
                this.player.setTextTrack(index);
            }, 1000);
        }

        // ignore period switch events that occur before seek is complete
        if (this.seekTime > 0 || (e.toStreamInfo.index > 1 && this.videoSurface.time == 0)) {
            return;
        }

        this.blockTimeUpdateEvent = false;
    }

    private onKeySystemAccessComplete(e: any): void {
        if (e.data) {
            this.emit(PlaybackAdapterEvents.DRM_KEYSYSTEM_CREATED, { keysystem: e.data.mksa.keySystem });
        }
    }

    private updateAudioTracks(): void {

        const current = this.getCurrentAudioTrackInfo(this.player.getCurrentTrackFor('audio').id);

        this.normalizedAudioTracks = this.normalizeAudioTracks(this.audioTracks, {
            type: 'roles',
            label: 'lang'
        });

        this.normalizedAudioTracks.forEach(audioTrack => {
            if (Array.isArray(audioTrack.type)) {
                // @ts-ignore
                audioTrack.type = audioTrack.type.join(',');
            }
        });

        if (current) {
            this.emit(PlaybackAdapterEvents.AUDIO_TRACK_UPDATED, {
                tracks: this.normalizedAudioTracks,
                track: this.normalizedAudioTracks[current.index],
            });
        }
    }

    private onTrackChangeRendered(e: TrackChangeRenderedEvent): void {
        if (e.mediaType === Playback.AUDIO) {
            const current = this.getCurrentAudioTrackInfo(this.player.getCurrentTrackFor('audio').id);
            this.emit(PlaybackAdapterEvents.AUDIO_TRACK_CHANGE, { track: this.normalizedAudioTracks[current.index] });
        }
    }

    private getCurrentAudioTrackInfo(id: string): StrAnyDict {

        let v = { index: 0, track: this.audioTracks[0] },
            i = this.audioTracks.length;

        while (i--) {
            if (this.audioTracks[i].id === id) {
                v = { index: i, track: this.audioTracks[i] };
                return v;
            }
        }

        return v;
    }

    private onQualityChangeRequested(e: QualityChangeRequestedEvent): void {
        if (e.mediaType === Playback.VIDEO) {
            //TODO check to see if we still need to switch started type event.  none used in old player. 
            this.emit(PlaybackAdapterEvents.ABR_QUALITY_SWITCHING, { index: e.newQuality });
        }
    }

    private onQualityChangeRendered(e: QualityChangeRenderedEvent): void {
        if (e.mediaType === Playback.VIDEO) {
            this.checkAbrConstraints(this.player.getTopBitrateInfoFor(Playback.VIDEO).qualityIndex);
            this.emit(PlaybackAdapterEvents.ABR_QUALITY_LOADED, { index: e.newQuality });
        }
    }

    private onFragmentLoadComplete(e: FragmentLoadingCompletedEvent): void {
        this.networkErrorRetryCount = 0;

        if (e.request.mediaType === Playback.VIDEO) {
            this.lowLevelDvrDetails = e.request;

            const info = this.player.getTopBitrateInfoFor(Playback.VIDEO);
            if (info) {
                this.checkAbrConstraints(info.qualityIndex);
            }

            if (this.multiCdnHeaderPresent) {
                const metric = this.player.getMetricsFor(Playback.VIDEO).HttpList;
                if (metric && metric.length > 0) {

                    const header = this.getCdnResponseHeader(metric[metric.length - 1]._responseHeaders);
                    this.multiCdnHeaderPresent = !!header;
                    if (this.multiCdnHeaderPresent) {
                        this.emit(PlaybackAdapterEvents.MULTI_CDN, { cdn: header });
                    }
                }
            }
            //@ts-ignore
            this.emit(PlaybackAdapterEvents.FRAGMENT_LOADED, { bandwidth: this.player.getAverageThroughput(Playback.VIDEO) });
        }
    }

    private onError(e: MediaPlayerErrorEvent): void {

        if (e.error.code) {
            switch (e.error.code) {
                case this.dash.MediaPlayer.errors.DOWNLOAD_ERROR_ID_CONTENT_CODE:
                case this.dash.MediaPlayer.errors.DOWNLOAD_ERROR_ID_INITIALIZATION_CODE:
                case this.dash.MediaPlayer.errors.DOWNLOAD_ERROR_ID_MANIFEST_CODE:
                    this.throwError(ErrorCode.DASHJS_NETWORK_ERROR,
                        `${AppResources.messages.FATAL_PLAYBACK_NETWORK_ERROR} : ${e.error.message}`, e.error);
                    break;

                case this.dash.MediaPlayer.errors.MANIFEST_LOADER_PARSING_FAILURE_ERROR_CODE:
                    this.throwError(ErrorCode.DASHJS_PARSE_ERROR,
                        `${AppResources.messages.FATAL_PLAYBACK_MEDIA_ERROR} : ${e.error.message}`, e.error);
                    break;

                case this.dash.MediaPlayer.errors.MEDIASOURCE_TYPE_UNSUPPORTED_CODE:
                    this.throwError(ErrorCode.DASHJS_SRC_NOT_SUPPORTED,
                        `${AppResources.messages.FATAL_PLAYBACK_MEDIA_ERROR} : ${e.error.message}`, e.error);
                    break;

                //TODO Test
                case this.dash.MediaPlayer.errors.MEDIA_KEYERR_CODE:
                case this.dash.MediaPlayer.errors.MEDIA_KEYERR_UNKNOWN_CODE:
                case this.dash.MediaPlayer.errors.MEDIA_KEYERR_CLIENT_CODE:
                case this.dash.MediaPlayer.errors.MEDIA_KEYERR_SERVICE_CODE:
                case this.dash.MediaPlayer.errors.MEDIA_KEYERR_OUTPUT_CODE:
                case this.dash.MediaPlayer.errors.MEDIA_KEYERR_HARDWARECHANGE_CODE:
                case this.dash.MediaPlayer.errors.MEDIA_KEYERR_DOMAIN_CODE:
                case this.dash.MediaPlayer.errors.CAPABILITY_MEDIAKEYS_ERROR_CODE:
                    this.throwError(ErrorCode.DASHJS_DRM_ERROR,
                        `${AppResources.messages.FATAL_PLAYBACK_MEDIA_ERROR} : ${e.error.message}`, e.error);
                    break;

                default:
                    this.throwError(ErrorCode.UNSPECIFIED_DASHJS_ERROR, e.error.message, e.error, false);
            }
        }
    }

    ////////////////////
    // Private Methods
    ////////////////////

    private getCdnResponseHeader(headers: any): string | null {
        if (headers) {

            var map: StrAnyDict = {};

            headers = headers.trim().split(/[\r\n]+/);

            headers.forEach(function (item: string) {
                var parts = item.split(': ');
                var header = parts.shift();
                var value = parts.join(': ');
                map[header] = value;
            });

            return map[Playback.MULTI_CDN] || null;
        }

        return null;
    }

    private getProtectionData(drmParams: ResourceLocationDrmInterface) {

        const data: StrAnyDict = {};

        if (!Util.isEmpty(drmParams.widevine)) {
            data[DrmType.WIDEVINE] = {
                serverURL: drmParams.widevine.url,
                httpRequestHeaders: drmParams.widevine.header,
                audioRobustness: 'SW_SECURE_CRYPTO',
                videoRobustness: 'SW_SECURE_CRYPTO'
            }
        }

        if (!Util.isEmpty(drmParams.playready)) {
            data[DrmType.PLAYREADY] = {
                serverURL: drmParams.playready.url,
                httpRequestHeaders: drmParams.playready.header,
                audioRobustness: 'SW_SECURE_CRYPTO',
                videoRobustness: 'SW_SECURE_CRYPTO'
            }
        }

        return data;
    }

    private getTextTrackIndex(track: TextTrack): number {
        return Util.findIndex(this.videoSurface.textTracks, t => track == t);
    }
}
