/* Copyright(c) 2006-2018 Futurescale, Inc. */
/* TypeScript port by Video Technology Group, CBSi Inc. */

import {Notifier} from "./Notifier";
import {ProxyInterface} from "../iface";

/**
 * @hideconstructor
 */
export class Proxy extends Notifier implements ProxyInterface {

    private readonly proxyName: string = null;
    private pData: any = null;

    constructor(name: string, data?: any) {
        super();

        this.proxyName = name;
        this.pData = data || {};
    }

    /**
    * @ignore
    */
    get name(): string {
        return this.proxyName;
    }

    /**
    * @ignore
    */
    get data(): any {
        return this.pData;
    }

    /**
    * @ignore
    */
    onRegister(): void {
        /* implementation optional */
    }

    /**
    * @ignore
    */
    onRemove(): void {
        this.pData = null;

        super.onRemove();
    }
}
