import { AsyncDataRequest } from '../dataservice/AsyncDataRequest';
import { Request } from "../iface/Request";
import { ThumbnailCueInterface } from '../iface/ThumbnailCueInterface';
import { ThumbnailTrackSurface } from '../playback/surface/ThumbnailTrackSurface';
import { QueryString } from './QueryString';
import { Util } from './Util';

// NOTE: This class is simply a wrapper for util classes/functions that needed to be separated due to circular reference issues.
export class Utilities extends Util {
    /**
     * Render a thumbnail cue into a given container.
     */
    static renderThumbnail(cue: ThumbnailCueInterface, container: HTMLElement): void {
        ThumbnailTrackSurface.renderThumbnail(cue, container);
    }

    /**
     * Helper function for making simple HTTP requests.
     */
    static request(options: Request): Promise<any> {
        if (options.query) {
            options.url = QueryString.append(options.url, options.query);
        }
        return AsyncDataRequest.load(options).then(xhr => xhr.response);
    }
}