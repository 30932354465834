import { apiAccessor, apiCollection, apiMethod } from '../app/ApiDecorators';
import { ResourceConfiguration } from '../app/ResourceConfiguration';
import { Util } from '../core/Util';
import { NotificationName } from '../enum/NotificationName';
import { NotificationType } from '../enum/NotificationType';
import { PlaylistInterface } from '../iface/PlaylistInterface';
import { ResourceConfigurationInterface } from '../iface/ResourceConfigurationInterface';
import { StrAnyDict } from '../iface/StrAnyDict';
import { Proxy } from '../mvc/Proxy';


export class PlaylistProxy extends Proxy implements PlaylistInterface {

    private pList: ResourceConfigurationInterface[];
    private pCurrentIndex: number = -1;
    private cachedApi: PlaylistInterface = null;

    /**
    * @hidden
    */
    constructor(name: string, data?: any) {
        super(name, data);

        this.pList = [];
    }

    /**
     * @ignore
     */
    onRemove(): void {
        this.clear();
        this.pList = null;
        this.cachedApi = null;
        super.onRemove();
    }

    /**
     * @ignore
     */
    getApi(): PlaylistInterface {
        if (!this.cachedApi) {
            this.cachedApi = <PlaylistInterface>apiCollection({}, this);
        }

        return this.cachedApi;
    }

    @apiAccessor(true) //readonly
    get list(): ResourceConfigurationInterface[] {
        const out: ResourceConfigurationInterface[] = [];

        let i = this.pList.length;
        while (i--) {
            out.unshift(Util.merge({}, this.pList[i]));
        }

        return out;
    }

    @apiAccessor()
    get length(): number {
        return this.pList.length;
    }

    @apiAccessor()
    get currentIndex(): number {
        return this.pCurrentIndex;
    }
    set currentIndex(index: number) {
        if (index < 0 || index >= this.pList.length) {
            this.notify(NotificationName.PLAYLIST_OUT_OF_RANGE);

            return;
        }
        const cur = Math.max(this.pCurrentIndex, 0);
        this.advance(index - cur);
    }

    @apiAccessor()
    get currentResource(): ResourceConfigurationInterface {
        const r = this.find(this.pCurrentIndex);

        return r ? Util.merge({}, r) : null;
    }

    @apiAccessor()
    get nextResource(): ResourceConfigurationInterface | null {
        const r = this.find(this.pCurrentIndex + 1);

        return r ? Util.merge({}, r) : null;
    }

    @apiAccessor()
    get prevResource(): ResourceConfigurationInterface | null {
        const r = this.find(this.pCurrentIndex - 1);

        return r ? Util.merge({}, r) : null;
    }

    @apiMethod()
    next(): void {
        this.advance(1);
    }

    @apiMethod()
    prev(): void {
        this.advance(-1);
    }

    @apiMethod()
    start(idx: number = 0): void {
        this.pCurrentIndex = !isNaN(idx) ? idx - 1 : -1;
        this.advance();
    }

    @apiMethod()
    addResources(resources: Partial<ResourceConfigurationInterface> | Partial<ResourceConfigurationInterface>[], index: number = null): void {
        const a: Partial<ResourceConfigurationInterface>[] = !Array.isArray(resources) ? [resources] : resources;

        a.forEach((v, i) => {
            const r = new ResourceConfiguration(v);
            index !== null ? this.pList.splice(index + i, 0, r) : this.pList.push(r);
        });

        this.notifyChanged();
    }

    @apiMethod()
    removeResourceAtIndex(idx: number): ResourceConfigurationInterface {
        const l = this.length;

        if (idx === this.currentIndex && l === 1) {
            this.clear();

            return null;
        }

        if (idx >= 0 && idx < l) {
            const itm = this.pList.splice(idx, 1);

            if (idx === this.currentIndex) {
                this.notify(NotificationName.PLAYLIST_CURRENT_RESOURCE_CLEARED, { interruptedResource: itm });
            }
            this.notifyChanged();

            return (itm && itm[0]) || null;
        }

        return null;
    }

    /**
    * Will clear the entire playlist. 
    */
    @apiMethod()
    clear(): void {
        let itm = this.find(this.pCurrentIndex);
        this.pList = [];
        this.pCurrentIndex = -1;
        this.notify(NotificationName.PLAYLIST_CLEARED, { interruptedResource: itm });
    }

    private advance(inc: number = 1): void {
        const idx = this.pCurrentIndex + inc,
            item = this.find(idx);

        let note: NotificationName = null,
            data = null;

        if (item) {
            this.pCurrentIndex = idx;
            data = { resource: item, currentIndex: idx };
            note = NotificationName.PLAYLIST_ADVANCED;
        }
        else {
            if (idx < 0) {
                note = NotificationName.PLAYLIST_OUT_OF_RANGE;
            }
            else {
                note = NotificationName.PLAYLIST_COMPLETE;
            }
        }

        this.notify(note, data);
    }

    private find(idx: number): ResourceConfigurationInterface | null {
        return (idx >= 0 && idx < this.pList.length && this.pList[idx]) || null;
    }

    private notify(name: string, data: StrAnyDict | null = null): void {
        this.facade && this.facade.sendNotification(name, data || null, NotificationType.INTERNAL);
    }

    private notifyChanged(): void {
        this.notify(NotificationName.PLAYLIST_CHANGE, null);
    }
}
