import { Util } from '../core/Util';
import { dai } from '../dai';
import { AdPlaybackContextInterface } from '../iface';
import { AdTagParametersInterface } from '../iface/AdTagParametersInterface';
import { ResourceAdDaiInterface } from '../iface/ResourceAdDaiInterface';
import { ResourceConfigurationInterface } from '../iface/ResourceConfigurationInterface';
import { StrAnyDict } from '../iface/StrAnyDict';
import { AdParamAssembler } from './AdParamAssembler';


export class DaiStreamRequestAssembler extends AdParamAssembler {

    private static FILTER_OUT_PREMIUM_AUDIO = 'codec:ac-3,codec:ec-3';

    constructor() {
        super();
    }

    assembleStreamRequest(r: ResourceConfigurationInterface, info: AdPlaybackContextInterface, skipEncode: boolean = false): dai.StreamRequest {
        const stdParamsObj: AdTagParametersInterface = this.assembleStdParams(r, info),
            daiParams: ResourceAdDaiInterface = r.ad.dai;

        if (daiParams.filterOutPremiumAudioCodecs) {
            stdParamsObj['dai-excl'] = DaiStreamRequestAssembler.FILTER_OUT_PREMIUM_AUDIO;
        }

        const atp: StrAnyDict = Util.assign({}, stdParamsObj);
        atp.cust_params = this.queryStrFromObj(r.ad.adTagParameters.cust_params, skipEncode ? [] : []);

        delete atp.output;

        const obj = <dai.StreamRequest>{
            apiKey: daiParams.apiKey,
            assetKey: daiParams.assetKey || null,
            streamActivityMonitorId: daiParams.streamActivityMonitorId || null,
            adTagParameters: atp,
            videoId: daiParams.daiVideoId || null,
            contentSourceId: daiParams.contentSourceId || null
        };

        Util.isString(daiParams.format) && (obj.format = daiParams.format);

        return obj;
    }
}
