import { BuildInfoServiceInterface } from "../iface";
import { BuildInfoInterface } from "../iface/BuildInfoInterface";
import { ServiceBase } from './ServiceBase';


export class BuildInfoService extends ServiceBase implements BuildInfoServiceInterface {

    private readonly pBuildInfo: BuildInfoInterface;
    private static instance: BuildInfoService;

    constructor(name: string, buildInfo: BuildInfoInterface) {
        super(name);
        this.pBuildInfo = buildInfo;
    }

    static getInstance(name: string, info: BuildInfoInterface): BuildInfoService {
        if (!this.instance) {
            this.instance = new BuildInfoService(name, info);
        }

        return this.instance;
    }

    get env(): string {
        return this.pBuildInfo.env;
    }

    get playerName(): string {
        return this.pBuildInfo.playerName;
    }

    get playerVersion(): string {
        return this.pBuildInfo.playerVersion;
    }

    get buildTime(): string {
        return this.pBuildInfo.buildTime;
    }

    toObject() {
        return {
            env: this.pBuildInfo.env,
            playerName: this.pBuildInfo.playerName,
            playerVersion: this.pBuildInfo.playerVersion,
            buildTime: this.pBuildInfo.buildTime
        };
    }
}

