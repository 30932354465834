import { DaiProxyApi } from '../ad/dai-api/DaiProxyApi';
import { AppResources } from '../app/AppResources';
import { Util } from '../core/Util';
import { AdContext } from '../enum/AdContext';
import { Autoplay } from '../enum/Autoplay';
import { ErrorCode } from '../enum/ErrorCode';
import { MediatorName } from '../enum/MediatorName';
import { ModelName } from '../enum/ModelName';
import { NotificationName } from '../enum/NotificationName';
import { PlayerDom } from '../enum/PlayerDom';
import { ProxyName } from '../enum/ProxyName';
import { ServiceName } from '../enum/ServiceName';
import { AutoplayInfoInterface, ImaAdServiceInterface, NotificationInterface, PlayerDomProxyInterface, PresentationMediatorInterface, ServiceInterface, SystemServiceInterface } from '../iface';
import { PlayerOptionsInterface } from '../iface/PlayerOptionsInterface';
import { PresentationStateInterface } from '../iface/PresentationStateInterface';
import { VideoInterface } from '../iface/VideoInterface';
import { ContentPlaybackStateProxy } from '../model/ContentPlaybackStateProxy';
import { PlayerDomProxy } from '../model/PlayerDomProxy';
import { ResourceProxy } from '../model/ResourceProxy';
import { AutoplayCapabilitiesService } from '../service/AutoplayCapabilitiesService';
import { DaiAdService } from '../service/DaiAdService';
import { ImaAdService } from '../service/ImaAdService';
import { ContentPresentationMediator } from '../view/ContentPresentationMediator';
import { DaiPresentationMediator } from '../view/DaiPresentationMediator';
import { ImaPresentationMediator } from '../view/ImaPresentationMediator';
import { LogAwareSimpleCommand } from './LogAwareSimpleCommand';
import { ResourceAdInterface } from '../iface/ResourceAdInterface';


export class PrepPlaybackCommand extends LogAwareSimpleCommand {

    execute(notification: NotificationInterface) {
        const sysSvc = <SystemServiceInterface>this.facade.retrieveService(ServiceName.System);

        if (!sysSvc.global || !sysSvc.global.document) {
            this.sendNotification(NotificationName.VIDEO_START_ERROR, { error: AppResources.messages.ENVIRONMENT_NOT_SUPPORTED, fatal: true });

            return;
        }

        const presoState = <PresentationStateInterface>this.getModel(ModelName.PresentationState),
            playerOpts = <PlayerOptionsInterface>this.getModel(ModelName.PlayerOptions),
            useAutoplay = this.isAutoplayRequested(playerOpts.autoplay);

        this.facade.registerProxy(new ContentPlaybackStateProxy(ProxyName.ContentPlaybackStateProxy));

        if (sysSvc.isWebMaf || sysSvc.isTizenSmartTv || sysSvc.isXboxOne) {
            this.processAutoplaySupport({
                supportsMutedAutoplay: true,
                supportsUnmutedAutoplay: true
            }, presoState, playerOpts);

            this.createPresentation(sysSvc);
        }
        else if (!presoState.skipAutoplayCheck && useAutoplay) {
            this.checkAutoplay().then((result: AutoplayInfoInterface) => {
                this.processAutoplaySupport(result, presoState, playerOpts);
                this.createPresentation(sysSvc);
            });
        }
        else {
            this.createPresentation(sysSvc);
        }
    }

    private createPresentation(sysSvc: SystemServiceInterface) {
        const presoMed = this.getPresoMediator(sysSvc);

        if (!presoMed) {
            return;
        }

        this.facade.registerMediator(presoMed);

        this.sendNotification(NotificationName.START_PRESENTATION);
    }

    private getPresoMediator(sysSvc: SystemServiceInterface): PresentationMediatorInterface {
        const domProxy = <PlayerDomProxyInterface>this.facade.retrieveProxy(ProxyName.PlayerDomProxy);
        const video = this.getVideo(sysSvc, domProxy);
        const resourceProxy = <ResourceProxy>this.facade.retrieveProxy(ProxyName.ResourceProxy);
        const adSets = <ResourceAdInterface>resourceProxy.ad;
        const adContext: string | null = (adSets && adSets.context) || null;
        const presoMdl = <PresentationStateInterface>this.getModel(ModelName.PresentationState);
        const n = MediatorName.PRESENTATION_MEDIATOR;

        let ret: PresentationMediatorInterface = null,
            ok: boolean;

        if (video === null) {
            this.sendNotification(NotificationName.RESOURCE_ERROR, {
                code: ErrorCode.UNEXPECTED_CONDITION,
                message: AppResources.messages.VIDEO_PLAYBACK_UNAVAILABLE,
                fatal: true,
            });

            return ret;
        }

        if (adContext && adContext !== AdContext.NONE || presoMdl.isAutoplay) {
            // VTG-2214: Only required for iOS; attribute ignored elsewhere
            domProxy.setVideoAttribute('playsinline', '');
        }

        switch (adContext) {
            case AdContext.IMA:
                ok = this.createImaAdService(resourceProxy);
                if (!ok) {
                    this.sendNotification(NotificationName.RESOURCE_ERROR, {
                        code: ErrorCode.IMA_SDK_MISSING,
                        message: AppResources.messages.IMA_SDK_MISSING,
                        fatal: true,
                    });
                }
                else {
                    ret = new ImaPresentationMediator(n, video);
                }
                break;

            case AdContext.DAI_SDK:
            case AdContext.DAI_API:
                const useApi = adContext === AdContext.DAI_API;

                ok = this.createDaiAdService(domProxy, resourceProxy, useApi, sysSvc);
                ok && (ret = new DaiPresentationMediator(n, video));
                !ok && this.sendNotification(NotificationName.RESOURCE_ERROR, {
                    code: ErrorCode.DAI_SDK_MISSING,
                    message: AppResources.messages.DAI_SDK_UNAVAILABLE,
                    fatal: true,
                });
                break;

            case AdContext.DAI_SSB:
                if (adSets.dai.ssbStreamUrl) {
                    // for now- until we decide whether mediaUrl will act as fallback stream 
                    resourceProxy.location.mediaUrl = adSets.dai.ssbStreamUrl;
                }
                ret = new ContentPresentationMediator(n, video);
                break;

            default:
                ret = new ContentPresentationMediator(n, video);
                break;
        }

        return ret;
    }

    ////////////////
    // Ad services
    private createDaiAdService(
        domProxy: PlayerDomProxyInterface,
        res: ResourceProxy,
        useApi: boolean,
        sysSvc: SystemServiceInterface
    ): boolean {

        if (this.facade.retrieveService(ServiceName.DaiAd)) {
            return true;
        }

        const svc = new DaiAdService(ServiceName.DaiAd, (useApi ? DaiProxyApi : null));
        this.facade.registerService(svc);

        if (!svc.sdk) {
            svc.destroy();
            this.facade.removeService(ServiceName.DaiAd);
            return false;
        }

        const trkSets = res.ad.viewabilityTracking;
        domProxy && svc.setContainer(domProxy.getElement(PlayerDom.AD_CONTAINER));

        const videoIface = useApi ? sysSvc.createSimpleVideoInterface() : domProxy.getVideo();
        svc.setVideoInterface(videoIface);

        svc.setTrackingData({
            ptype: trkSets.ptype,
            vguid: trkSets.vguid,
            partner: trkSets.partner
        });

        return true;
    }

    getVideoInterface(sysSvc: SystemServiceInterface, domProxy: PlayerDomProxyInterface): any {
        return sysSvc.createSimpleVideoInterface();
    }

    getVideo(sysSvc: SystemServiceInterface, domProxy: PlayerDomProxyInterface): any {
        if (sysSvc.isWebMaf) {
            return sysSvc.webMafPlayer;
        }
        else if (domProxy) {
            return domProxy.getVideo();
        }

        return null;
    }

    createImaAdService(res: ResourceProxy): boolean {
        if (this.facade.retrieveService(ServiceName.ImaAd)) {
            return true;
        }

        const svc: ImaAdServiceInterface = new ImaAdService(ServiceName.ImaAd);
        this.facade.registerService(<ServiceInterface>svc);

        const sdk = svc.sdk;

        if (sdk !== null) {
            const domProxy = <PlayerDomProxy>this.facade.retrieveProxy(ProxyName.PlayerDomProxy),
                adC = <HTMLElement>domProxy.getElement(PlayerDom.AD_CONTAINER);

            svc.setContainer(adC);
            svc.adsLoaderOptions = {
                adContainer: adC,
                adClickEl: <HTMLElement>domProxy.getElement(PlayerDom.AD_CLICK_EL),
                video: <VideoInterface>domProxy.getVideo(),
                sdkSettings: svc.sdkSettings,
                adService: svc,
            };

            const trkSets = res.ad.viewabilityTracking;
            svc.setTrackingData({
                ptype: trkSets.ptype,
                vguid: trkSets.vguid,
                partner: trkSets.partner
            });
            svc.createAdsLoader();

            return true;
        }
        else {
            this.facade.removeService(ServiceName.ImaAd);

            return false;
        }
    }
    ////////////
    // autoplay
    private processAutoplaySupport(
        result: AutoplayInfoInterface,
        presoMdl: PresentationStateInterface,
        playerOpts: PlayerOptionsInterface
    ): void {

        const apOpt = playerOpts.autoplay;
        const mutedOk = result.supportsMutedAutoplay;
        const unmutedOk = result.supportsUnmutedAutoplay;

        presoMdl.autoplaySupport = {
            supportsMutedAutoplay: mutedOk,
            supportsUnmutedAutoplay: unmutedOk
        };

        presoMdl.isMuteAtPlayStart = (
            apOpt === Autoplay.ATTEMPT_MUTED && mutedOk ||
            (apOpt === Autoplay.ATTEMPT_UNMUTED_THEN_MUTED && !unmutedOk && mutedOk)
        );

        presoMdl.isAutoplay = this.shouldAutoplay(apOpt, result);
    }

    private isAutoplayRequested(opt: Autoplay): boolean {
        if (Util.isEmpty(opt) || opt === Autoplay.NONE) {
            return false;
        }

        return true;
    }

    private shouldAutoplay(opt: Autoplay, info: AutoplayInfoInterface): boolean {
        if (Util.isEmpty(opt) || opt === Autoplay.NONE) {
            return false;
        }

        const mutedOk = info.supportsMutedAutoplay;
        const unmutedOk = info.supportsUnmutedAutoplay;

        const muteOnly = opt === Autoplay.ATTEMPT_MUTED && mutedOk;
        const soundOnly = opt === Autoplay.ATTEMPT_UNMUTED && unmutedOk;
        const fallback = !soundOnly && (opt === Autoplay.ATTEMPT_UNMUTED_THEN_MUTED && mutedOk);

        return muteOnly || soundOnly || fallback;
    }

    private checkAutoplay(): Promise<AutoplayInfoInterface> {
        const ads = <AutoplayCapabilitiesService>this.getService(ServiceName.AutoplayCapabilities);

        return ads.detectCapabilities();
    }
}
