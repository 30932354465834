import { AppResources } from '../app/AppResources';
import { PlayerDom } from '../enum/PlayerDom';
import { PlayerDomProxyInterface } from '../iface';
import { DimensionsInterface } from '../iface/DimensionsInterface';
import { PlayerDomInterface } from '../iface/PlayerDomInterface';
import { StrAnyDict } from '../iface/StrAnyDict';
import { VideoInterface } from '../iface/VideoInterface';
import { Proxy } from '../mvc/Proxy';


export class PlayerDomProxy extends Proxy implements PlayerDomProxyInterface {

    private controlVisCssClass: string = 'cvui-ctrlbar-vis';

    constructor(name: string, data?: any) {
        super(name, data);
    }

    setModel(coll: StrAnyDict): void {
        for (const q in coll) {
            this.model[q] = coll[q];
        }
    }

    removeDom(): void {
        const main = this.getMain(),
            children = main.childNodes;
        let i = children.length;

        while (i--) {
            main.removeChild(children[i]);
        }
    }

    setControlVisibility(controlsVisible: boolean): void {
        const el = this.getMain();
        if (!el) { return; }

        const m = controlsVisible ? 'add' : 'remove';

        el.classList[m](this.controlVisCssClass);
    }

    getElement(n: PlayerDom): any {
        const el = this.model[n];
        return el || null;
    }

    getSafeZIndex(): number {
        const el = this.getElement(PlayerDom.AD_CONTAINER);
        if (!el) return 10;

        return Number(el.style.zIndex) + 10;
    }

    getVideo(): VideoInterface {
        return <VideoInterface>this.getElement(PlayerDom.VIDEO_EL);
    }

    getTextTrackContainer(): HTMLElement {
        return (<HTMLElement>this.getElement(PlayerDom.CC_CONTAINER)) || null;
    }

    setVideoVolume(v: number) {
        const el = this.getVideo();
        if (!el || this.getVideoVolume() == v) {
            return;
        }

        el.volume = v;
    }

    getVideoVolume(): number {
        const el = this.getVideo();
        return el ? el.volume : null;
    }

    getMain(): HTMLElement {
        return this.getElement(PlayerDom.MAIN_CONTAINER);
    }

    showAdClickElement(flag?: boolean): void {
        const el = this.getElement(PlayerDom.AD_CLICK_EL);

        if (el) {
            (el.style.display = flag !== false ? 'block' : 'none');
        }
    }

    getDimensions(): DimensionsInterface {
        const r = this.getPresentationRect();

        return r ? { width: r.width, height: r.height } : null;
    }

    showContainer(name: PlayerDom, flag: boolean): void {
        const el = this.getElement(name);

        if (el) {
            el.style.display = flag ? 'inline-block' : 'none';
        }
    }

    showAdContainer(flag: boolean) {
        this.showContainer(PlayerDom.AD_CONTAINER, flag);
    }

    getPresentationRect(): ClientRect | null {
        const m = this.getMain(),
            r = m && m.getBoundingClientRect();

        return r || null;
    }

    muteVideo(flag: boolean): void {
        const v = this.getVideo(),
            att = 'muted';

        if (v) {
            v.muted = flag;
            flag && v.setAttribute(att, att);
            !flag && v.removeAttribute(att);
        }
    }

    primeVideo(): Promise<any> {
        return Promise.resolve()
            .then(() => {
                const v = this.getVideo();
                if (v) {
                    v.src = AppResources.blankVideoUrl;
                    v.load();

                    return v.play();
                }
            });
    }

    setVideoAttribute(name: string, value: string): void {
        const v = this.getVideo();
        v && v.setAttribute(name, value);
    }

    removeVideoAttribute(name: string): void {
        const v = this.getVideo();
        v && v.removeAttribute(name);
    }

    private get model(): PlayerDomInterface {
        return <PlayerDomInterface>this.data;
    }
}
