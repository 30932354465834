import { AppResources } from '../../app/AppResources';
import { Util } from '../../core/Util';
import { ErrorCode } from '../../enum/ErrorCode';
import { LogLevel } from '../../enum/LogLevel';
import { Range, VideoSurfaceInterface } from '../../iface';
import { AudioTrackInterface } from '../../iface/AudioTrackInterface';
import { EventInterface } from '../../iface/EventInterface';
import { LoggerInterface } from '../../iface/LoggerInterface';
import { PlaybackAdapterConfigInterface } from '../../iface/PlaybackAdapterConfigInterface';
import { QualityInterface } from '../../iface/QualityInterface';
import { ResourcePlaybackAbrInterface } from '../../iface/ResourcePlaybackAbrInterface';
import { StrAnyDict } from '../../iface/StrAnyDict';
import { Utils } from '../../util/Utils';
import { HlsjsRobustness } from '../enum/HlsjsRobustness';
import { Playback } from '../enum/Playback';
import { PlaybackAdapterEvents } from '../enum/PlaybackAdapterEvents';
import { PlaybackAdapterType } from '../enum/PlaybackAdapterType';
import { TextTrackMode } from '../enum/TextTrackMode';
import { TextTrackSurfaceEvents } from '../enum/TextTrackSurfaceEvents';
import { audioTracksUpdatedData, audioTrackSwitchedData, errorData, fragLoadedData, Hls, Level, levelLoadedData, levelSwitchedData, levelSwitchingData, levelUpdatedData, manifestLoadedData, manifestParsedData } from '../interface/HlsjsInterface';
import { BaseHtml5Adapter } from './BaseHtml5Adapter';


export class HlsjsAdapter extends BaseHtml5Adapter {

    protected pType = PlaybackAdapterType.HLSJS;

    private Hls: Hls = (<any>window).Hls;//static version of Hls and all static properties. 
    private player!: Hls; //hls instantiated instance. 
    private pFramerate: number = Number.NaN;
    private pFragmentType: string = '';

    private hlsjsEventMap: StrAnyDict = [
        {
            type: this.Hls.Events.MANIFEST_PARSED,
            callback: (type: string, data: manifestParsedData) => this.onManifestParsed(type, data)
        },
        {
            type: this.Hls.Events.MANIFEST_LOADED,
            callback: (type: string, data: manifestLoadedData) => this.onManifestLoaded(type, data)
        },
        {
            type: this.Hls.Events.LEVEL_LOADED,
            callback: (type: string, data: any) => this.onLevelLoaded(type, data)
        },
        {
            type: this.Hls.Events.LEVEL_UPDATED,
            callback: (type: string, data: levelUpdatedData) => this.onLevelUpdated(type, data)
        },
        {
            type: this.Hls.Events.LEVEL_SWITCHING,
            callback: (type: string, data: levelSwitchingData) => this.onLevelSwitching(type, data)
        },
        {
            type: this.Hls.Events.LEVEL_SWITCHED,
            callback: (type: string, data: levelSwitchedData) => this.onLevelSwitched(type, data)
        },
        {
            type: this.Hls.Events.FRAG_LOADED,
            callback: (type: string, data: any) => this.onFragmentLoaded(type, data)
        },
        {
            type: this.Hls.Events.FRAG_PARSING_DATA,
            callback: (type: string, data: any) => this.onFragmentParsingData(type, data)
        },
        {
            type: this.Hls.Events.AUDIO_TRACKS_UPDATED,
            callback: (type: string, data: audioTracksUpdatedData) => this.onAudioTrackUpdated(type, data)
        },
        {
            type: this.Hls.Events.AUDIO_TRACK_SWITCHED,
            callback: (type: string, data: audioTrackSwitchedData) => this.onAudioTrackSwitched(type, data)
        },
        {
            type: this.Hls.Events.ERROR,
            callback: (type: string, data: errorData) => this.onError(type, data)
        }
    ];

    constructor(videoSurface: VideoSurfaceInterface, config: PlaybackAdapterConfigInterface, logger: LoggerInterface) {
        super(videoSurface, config, logger);
        this.logger.log(LogLevel.INFO, 'HlsjsAdapter created');
    }

    ////////////////////
    //Public Methods
    ////////////////////

    initialize(): void {

        super.initialize();

        let hlsConfig = this.Hls.DefaultConfig;
        hlsConfig.debug = this.enableLogger;
        hlsConfig.capLevelToPlayerSize = this.playback.abr.capQualityToScreenSize;
        //hlsConfig.capLevelOnFPSDrop = todo?
        hlsConfig.autoStartLoad = false;
        hlsConfig.liveSyncDurationCount = this.playback.liveEdgeSyncFragmentCount;

        hlsConfig.manifestLoadingMaxRetry = HlsjsRobustness.MANIFEST_RETRY_ATTEMPTS;
        hlsConfig.manifestLoadingRetryDelay = HlsjsRobustness.MANIFEST_RETRY_DELAY;
        hlsConfig.levelLoadingRetryDelay = HlsjsRobustness.LEVEL_RETRY_DELAY;
        hlsConfig.levelLoadingTimeOut = HlsjsRobustness.LEVEL_RETRY_TIMEOUT;
        hlsConfig.fragLoadingRetryDelay = HlsjsRobustness.FRAGMENT_RETRY_DELAY;
        hlsConfig.fragLoadingTimeOut = HlsjsRobustness.FRAGMENT_RETRY_TIMEOUT;
        hlsConfig.enableCEA708Captions = true;

        hlsConfig.xhrSetup = this.onRequest.bind(this);

        // performance settings
        const settings = this.config.performanceSettings;
        if (settings.forwardBufferLength != null) {
            hlsConfig.maxBufferLength = settings.forwardBufferLength;
        }
        if (settings.backBufferLength != null) {
            hlsConfig.liveBackBufferLength = settings.backBufferLength;
        }
        if (settings.topQualityForwardBufferLength != null) {
            hlsConfig.maxMaxBufferLength = settings.topQualityForwardBufferLength;
        }

        hlsConfig = this.mergeStreamingConfigs(hlsConfig, this.config.resource.overrides);

        this.logger.log(LogLevel.INFO, `Hlsjs version: ${this.Hls.version}`);
        this.player = new this.Hls(hlsConfig);

        // This tells hlsjs to use "showing" or "hidden" when selecting a text track.
        this.player.subtitleDisplay = this.config.textTrackSettings.native;
        this.player.attachMedia(this.videoSurface.video);
        this.addEvents(this.player, this.hlsjsEventMap);
    }

    protected loadMediaUrl(): Promise<void> {
        this.player.loadSource(this.mediaUrl);
        return super.loadMediaUrl();
    }

    destroy(): Promise<void> {
        this.removeEvents(this.player, this.hlsjsEventMap);
        this.player.destroy();
        this.player = null;
        this.Hls = null;
        return super.destroy();
    }

    play(): void {
        if (this.player.config.maxMaxBufferLength != HlsjsRobustness.BUFFER_LEVEL_PLAYBACK) {
            this.player.config.maxMaxBufferLength = HlsjsRobustness.BUFFER_LEVEL_PLAYBACK;
        }
        super.play();
    }

    suspend(): void {
        this.player.stopLoad();
    }

    resume(): void {
        this.player.startLoad();
    }
    ////////////////////
    //Accessors
    ////////////////////
    set audioTrack(track: AudioTrackInterface) {
        this.player.audioTrack = track.index;
    }

    set currentIndex(index: number) {
        this.player.loadLevel = index;
    }
    get currentIndex(): number {
        return this.player.loadLevel;
    }

    set autoQualitySwitching(value: boolean) {
        this.player.loadLevel = value ? -1 : this.player.nextLoadLevel;
    }
    get autoQualitySwitching(): boolean {
        return this.player.loadLevel === -1;
    }

    set minBitrate(value: number) {
        this.player.config.minAutoBitrate = value;
    }
    get minBitrate(): number {
        return this.player.config.minAutoBitrate;
    }

    set maxBitrate(value: number) {
        if (!this.player.config.capLevelToPlayerSize) {
            this.player.autoLevelCapping = isNaN(value) ? -1 : Utils.getIndexForBitrate(this.player.levels, value, false);
        }
        else {
            this.logger.log(LogLevel.WARN, AppResources.messages.CAP_LEVEL_MAXBITRATE);
        }
    }
    get maxBitrate(): number {
        return this.player.levels[this.player.autoLevelCapping].bitrate;
    }

    get manifestQualities(): QualityInterface[] {
        return this.player.levels.map((item: Level, index: number): QualityInterface => ({
            index,
            bitrate: item.bitrate,
            width: item.width,
            height: item.height,
            codec: (item.attrs) ? item.attrs.CODECS : undefined
        }));
    }

    get seekable(): Range {
        const duration = this.videoSurface.video.duration;
        const dvr = (this.lowLevelDvrDetails) ? this.lowLevelDvrDetails.totalduration : 0;
        return { start: duration - dvr, end: duration };
    }

    get segmentDuration(): number {
        return (this.lowLevelDvrDetails) ? this.lowLevelDvrDetails.averagetargetduration : super['segmentDuration'];
    }

    get framerate(): number {
        return this.pFramerate;
    }

    get fragmentType(): string {
        return this.pFragmentType;
    }

    ////////////////////
    //Event Handlers
    ////////////////////  
    protected onVideoSurfaceEvent(e: EventInterface): void {
        switch (e.type) {
            case TextTrackSurfaceEvents.TEXT_TRACK_CHANGE:
            case TextTrackSurfaceEvents.TEXT_TRACK_DISPLAY_MODE_CHANGE:
                const track = this.videoSurface.textTrack;
                const mode = track.mode;
                const enabled = mode != TextTrackMode.DISABLED;
                if (enabled) {
                    const index = Util.findIndex(this.player.subtitleTracks, (t) => t.lang == track.language && t.name == track.label);
                    if (this.player.subtitleTrack != index) {
                        this.player.subtitleTrack = index;
                    }
                }
                break;
        }

        super.onVideoSurfaceEvent(e);
    }

    private onManifestParsed(type: string, data: manifestParsedData): void {
        this.emit(PlaybackAdapterEvents.MANIFEST_PARSED, { profile: this.manifestQualities });
    }

    private onManifestLoaded(type: string, data: manifestLoadedData): void {
        //TODO Do we want separation for loading the manifest vs loading the fragments? or bundle into one concept. Discuss.

        const startTime = this.playback.startTime;
        this.player.startLoad(!isNaN(startTime) ? startTime : -1);

        if (this.player.subtitleTracks.length > 0) {
            this.player.subtitleTrack = -1;
        }

        this.setBitrateRestrictionAtStartup();
        this.emit(PlaybackAdapterEvents.MANIFEST_LOADED);
    }

    private onLevelLoaded(type: string, data: levelLoadedData): void { //set type to any, hls definition of level is missing but is present at RT.
        this.lowLevelDvrDetails = data.details;
        this.pIsLiveStream = this.lowLevelDvrDetails.live;
        this.emit(PlaybackAdapterEvents.ABR_QUALITY_LOADED, { index: data.level });
    }

    private onLevelUpdated(type: string, data: levelUpdatedData): void {
        //@ts-ignore
        this.checkAbrConstraints(this.player.maxAutoLevel);
    }

    private onLevelSwitching(type: string, data: levelSwitchingData): void {
        //TODO check to see if we still need to switch started type event.  none using in old player.
        this.emit(PlaybackAdapterEvents.ABR_QUALITY_SWITCHING, { index: data.level });
    }

    private onLevelSwitched(type: string, data: levelSwitchedData): void {
        this.emit(PlaybackAdapterEvents.ABR_QUALITY_LOADED, { index: data.level });
    }

    private onFragmentLoaded(type: string, data: fragLoadedData): void { //stats missing from ts def for Hls.fragloadData
        this.networkErrorRetryCount = 0;
        this.mediaErrorRetryCount = 0;
        if (!this.pFragmentType) {
            this.pFragmentType = Util.getMimeType(data.frag.url);
        }

        //@ts-ignore
        this.checkAbrConstraints(this.player.maxAutoLevel);

        const bw = (data.stats.loaded * 8000) / Number(window.performance.now() - data.stats.trequest);
        this.emit(PlaybackAdapterEvents.FRAGMENT_LOADED, { bandwidth: bw });
    }

    private onFragmentParsingData(type: any, data: any): void { //missing params from ts def for Hls.fragParsingData
        this.pFramerate = data.nb / (data.endPTS - data.startPTS);
        this.emit(PlaybackAdapterEvents.FRAGMENT_PARSED, { rate: this.pFramerate });
    }

    private onAudioTrackUpdated(type: string, data: audioTracksUpdatedData) {

        this.normalizedAudioTracks = this.normalizeAudioTracks(this.player.audioTracks, {
            codec: 'audioCodec',
            label: 'name'
        });

        this.emit(PlaybackAdapterEvents.AUDIO_TRACK_UPDATED, {
            tracks: this.normalizedAudioTracks,
            track: this.normalizedAudioTracks[this.player.audioTrack],
        });
    }

    private onAudioTrackSwitched(type: string, data: audioTrackSwitchedData) {
        this.emit(PlaybackAdapterEvents.AUDIO_TRACK_CHANGE, { track: this.normalizedAudioTracks[parseInt(data.id)] });
    }

    private onRequest(xhr: XMLHttpRequest, url: string): void {
        xhr.withCredentials = (url.indexOf('akamaihd') > -1 && url.indexOf('csmil') > -1);

        if (this.multiCdnHeaderPresent) {
            xhr.addEventListener('readystatechange', (e: any) => {
                if (url.indexOf('.ts') !== -1 && xhr.readyState === XMLHttpRequest.HEADERS_RECEIVED) {

                    if (this.multiCdnHeaderPresent) {
                        var headers = xhr.getAllResponseHeaders();
                        this.multiCdnHeaderPresent = headers.indexOf(Playback.MULTI_CDN) !== -1;

                        if (this.multiCdnHeaderPresent) {
                            this.emit(PlaybackAdapterEvents.MULTI_CDN, { cdn: xhr.getResponseHeader(Playback.MULTI_CDN.toUpperCase()) })
                        }
                    }
                }
            });
        }
    }

    private onError(type: string, data: errorData): void {

        let code = ErrorCode.UNSPECIFIED_HLSJS_ERROR;

        switch (data.type) {
            case this.Hls.ErrorTypes.NETWORK_ERROR:
                data.fatal && this.handleNetworkErrors(data);
                break;

            case this.Hls.ErrorTypes.MEDIA_ERROR:
                data.fatal && this.handleMediaErrors(data);
                break;

            case this.Hls.ErrorTypes.MUX_ERROR:
                code = ErrorCode.HLSJS_MUX_ERROR;
            //no break is intended.             
            default:
                //TODO add message in AppResource for general error prefix to details
                const msg = this.getErrorMessage(`${data.details}`, data.fatal);
                this.log(LogLevel.ERROR, msg, data);
                this.throwError(code, msg, data, data.fatal);
        }
    }

    ////////////////////
    // Private Methods
    ////////////////////

    private handleNetworkErrors(data: errorData): void {
        const max = HlsjsRobustness.FATAL_ERROR_RECOVERY_ATTEMPTS,
            ErrorDetails = this.Hls.ErrorDetails;

        switch (data.details) {

            case ErrorDetails.LEVEL_LOAD_ERROR:
            case ErrorDetails.FRAG_LOAD_ERROR:
                if (this.networkErrorRetryCount < max) {

                    this.player.startLoad();
                    this.networkErrorRetryCount++;

                    //retry logging                    
                    this.log(LogLevel.ERROR, this.getErrorMessage(
                        AppResources.messages.RETRY_PLAYBACK_NETWORK_ERROR, false,
                        `${this.networkErrorRetryCount} / ${max}`
                    ));
                    break;
                }
                this.throwError(ErrorCode.HLSJS_NETWORK_ERROR,
                    AppResources.messages.FATAL_PLAYBACK_NETWORK_ERROR, data);
                break;

            case ErrorDetails.MANIFEST_PARSING_ERROR:
                this.throwError(ErrorCode.HLSJS_PARSE_ERROR,
                    `${AppResources.messages.FATAL_PLAYBACK_NETWORK_ERROR} : ${data.details}`, data);
                break;

            default:
                this.throwError(ErrorCode.HLSJS_NETWORK_ERROR, `${AppResources.messages.FATAL_PLAYBACK_NETWORK_ERROR} : ${data.details}`, data, data.fatal);
        }
    }

    private handleMediaErrors(data: errorData): void {

        const max = HlsjsRobustness.FATAL_ERROR_RECOVERY_ATTEMPTS,
            ErrorDetails = this.Hls.ErrorDetails;

        switch (data.details) {
            case ErrorDetails.MANIFEST_INCOMPATIBLE_CODECS_ERROR:
                this.throwError(ErrorCode.HLSJS_SRC_NOT_SUPPORTED,
                    `${AppResources.messages.FATAL_PLAYBACK_MEDIA_ERROR} : ${data.details}`, data);
                break;

            default:

                if (this.mediaErrorRetryCount < max) {
                    if (this.mediaErrorRetryCount === 1) {
                        this.player.swapAudioCodec();
                    }
                    this.player.recoverMediaError();
                    this.mediaErrorRetryCount++;

                    //retry logging                    
                    this.log(LogLevel.ERROR, this.getErrorMessage(
                        AppResources.messages.RETRY_PLAYBACK_MEDIA_ERROR, false,
                        `${this.mediaErrorRetryCount} / ${max}`
                    ));

                    break;
                }

                this.throwError(ErrorCode.HLSJS_MEDIA_ERROR,
                    `${AppResources.messages.FATAL_PLAYBACK_MEDIA_ERROR} : ${data.details}`, data);
        }
    }

    private setBitrateRestrictionAtStartup(): void {
        const abr: ResourcePlaybackAbrInterface = this.playback.abr;

        if (!isNaN(abr.minBitrate)) {
            this.minBitrate = abr.minBitrate - 1; // HLS.js does not look at >= so we need to set the min just one below the actual bitrate. To ease developer confusion, handle inline.
        }

        if (!isNaN(abr.maxBitrate)) {
            this.maxBitrate = abr.maxBitrate;
        }

        if (!isNaN(abr.startBitrate)) {
            const levels: Level[] = this.player.levels;
            const index = Utils.getIndexForBitrate(levels, abr.startBitrate, false);
            if (Util.inRange(index, 0, levels.length - 1)) {
                this.player.config.startLevel = index;
            }
            else {
                //log warning here no error event needed.  
            }
        }
    }
}
