import { dai } from '../dai';
import { AdContext } from '../enum/AdContext';
import { ServiceName } from '../enum/ServiceName';
import { DaiAdServiceInterface, SimpleVideoInterface, SystemServiceInterface, ImaAdInfoInterface } from '../iface';
import { GamAdService } from './GamAdService';



export class DaiAdService extends GamAdService implements DaiAdServiceInterface {

    private streamMgr: dai.StreamManager;
    private pSdk: dai.Sdk | null = null;
    private usesApi: boolean = false;
    private videoIface: SimpleVideoInterface = null;

    constructor(name: string, sdk?: dai.Sdk) {
        super(name, sdk ? AdContext.DAI_API : AdContext.DAI_SDK);
        if (sdk) {
            this.pSdk = sdk;
            this.usesApi = true;
        }
    }

    destroy(): void {
        this.streamMgr && this.streamMgr.reset();
        this.streamMgr = null;
        this.pSdk = null;
        this.videoIface = null;

        super.destroy();
    }

    initViewabilityTracking(): void {
        this.createViewabilityTracker();
    }

    get videoInterface(): SimpleVideoInterface {
        return this.videoIface;
    }

    get usesDaiApi(): boolean {
        return this.usesApi;
    }

    get sdk(): dai.Sdk | null {

        if (!this.pSdk) {
            const sysSvc = <SystemServiceInterface>this.getService(ServiceName.System),
                global = sysSvc.global;

            if (
                global &&
                global.google &&
                global.google.ima &&
                global.google.ima.dai
            ) {
                this.pSdk = global.google.ima.dai.api;
            }
        }

        return this.pSdk;
    }

    setVideoInterface(obj: SimpleVideoInterface): void {
        this.videoIface = obj;
    }

    setVolume(v: number): void {
        this.usesApi && (this.videoIface.volume = v);
    }

    setMuted(flag: boolean): void {
        this.usesApi && (this.videoIface.muted = flag);
    }

    setDuration(d: number): void {
        this.usesApi && (this.videoIface.duration = d);
    }

    setCurrentTime(t: number): void {
        this.usesApi && (this.videoIface.currentTime = t);
    }

    reset() {
        this.streamMgr.reset();
        if (this.usesApi) {
            this.videoIface.duration = NaN;
            this.videoIface.currentTime = NaN
        }
    }

    assembleAdInfo(ad: dai.Ad, adData?: Record<string, any>): ImaAdInfoInterface {
        if (this.adContext === AdContext.DAI_SDK) {
            return super.assembleAdInfo(ad, adData);
        }

        const podInfo = ad.getAdPodInfo();
        const adPos = (podInfo && podInfo.getAdPosition()) || -1;
        const { adId, creativeId } = ad.getDaiWrapperData();

        return {
            adServerName: this.adContext,
            adDuration: ad.getDuration(),
            adTitle: ad.getTitle(),
            adId: adId,
            adPosition: adPos,
            adAssetUrl: adData.mediaUrl || null,
            isVpaid: false,
            wrapperAdId: adId,
            creativeId: creativeId,
            wrapperCreativeId: creativeId,
            vastMediaHeight: null,
            vastMediaWidth:  null,
            vastMediaBitrate: null
        };
    }

    getStreamManager(): dai.StreamManager {
        if (!this.streamMgr && this.sdk) {
            if (this.usesApi) {
                this.streamMgr = new this.sdk.StreamManager(this.videoIface, this.adContainer, this.getLogger());
            }
            else {
                this.streamMgr = new this.sdk.StreamManager(this.videoIface, this.adContainer);
            }
        }

        return this.streamMgr;
    }
}
