import { MediaCapabilitiesMimeType } from "../util/enum/MediaCapabilitiesMimeType";

export const MimeType: Record<string, string> = {
    VTT: 'text/vtt',
    XML: 'application/ttml+xml',
    M3U8: MediaCapabilitiesMimeType.HLS,
    MPD: MediaCapabilitiesMimeType.DASH,
    TS: MediaCapabilitiesMimeType.TS_VIDEO,
    MP4: MediaCapabilitiesMimeType.MP4_VIDEO,
    MPV: MediaCapabilitiesMimeType.MP4_VIDEO,
    MPA: MediaCapabilitiesMimeType.MP4_AUDIO,
    WEBM: MediaCapabilitiesMimeType.WEBM_VIDEO,
};
