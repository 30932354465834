import { ImaAdCallAssembler } from '../ad/ImaAdCallAssembler';
import { ImaAdPlayer } from '../ad/ImaAdPlayer';
import { Util } from '../core/Util';
import { dai } from '../dai';
import { ModelName } from '../enum/ModelName';
import { NotificationName } from '../enum/NotificationName';
import { PlaybackState } from '../enum/PlaybackState';
import { ProxyName } from '../enum/ProxyName';
import { ServiceName } from '../enum/ServiceName';
import { AdBreakCollectionInterface, AdBreakScheduleItemInterface, AdDataProxyInterface, AdPlaybackContextInterface, AdProgressInterface, ImaAdPlayerEventInterface, ImaAdPlayerInterface, ImaAdPlayerOptions, ImaAdServiceInterface, PresentationMediatorInterface, SystemServiceInterface } from '../iface';
import { AdBreakInfoInterface } from '../iface/AdBreakInfoInterface';
import { AdCuePointInterface } from '../iface/AdCuePointInterface';
import { AdItemInterface } from '../iface/AdItemInterface';
import { BuildInfoInterface } from '../iface/BuildInfoInterface';
import { ContentPlaybackStateInterface } from '../iface/ContentPlaybackStateInterface';
import { ErrorInfoInterface } from '../iface/ErrorInfoInterface';
import { EventInterface } from '../iface/EventInterface';
import { QualityInterface } from '../iface/QualityInterface';
import { ResourceConfigurationInterface } from '../iface/ResourceConfigurationInterface';
import { AdDataProxy } from '../model/AdDataProxy';
import { ResourceProxy } from '../model/ResourceProxy';
import { CommonPresentationMediator } from './CommonPresentationMediator';


/**
 * ImaPresentationMediator is used for Google IMA ads (separate video; typically progressive)
 */
export class ImaPresentationMediator extends CommonPresentationMediator implements PresentationMediatorInterface {

    private adDataProxy: AdDataProxyInterface;
    private adPlayer: ImaAdPlayerInterface;

    private contentSegmentStarted: boolean = false;
    private pendingSeekTime: number = null;
    private contentStartReleased: boolean = false;
    private fatalContentErrorReceived: boolean = false;
    private breaksToSkip: string = null;
    private breakMetaDataReleased: boolean = false;
    private pendingBreak: AdBreakScheduleItemInterface = null;
    private pendingCuepoints: dai.CuePoint[];

    constructor(name: string, viewControl?: any) {
        super(name, viewControl);
        this.preloadContent = false;
    }

    onRemove(): void {
        this.killAdPlayer();
        this.adDataProxy = null;

        super.onRemove();
    }

    closeAds(): void {
        this.listenToAdPlayer(false);
        this.domProxy?.showAdContainer(false);
        this.adPlayer?.destroy();
    }

    start(): void {
        const contentStartTime = this.resourceProxy.playback.startTime,
            showPreRoll = this.resourceProxy.ad.showPrerollOnNonZeroStart,
            nonZeroStart = !isNaN(contentStartTime) && contentStartTime > 0,
            mUrl = this.resourceProxy.location.mediaUrl;

        this.hasContent = Util.isString(mUrl) && !Util.isEmpty(mUrl);

        if (nonZeroStart) {
            const cp = this.resourceProxy.ad.adTagParameters.cust_params;

            this.breaksToSkip = cp && cp.skippod || null;
            if (!showPreRoll && this.breaksToSkip === null) {
                this.breaksToSkip = '1';
            }
        }

        if (nonZeroStart && showPreRoll) {
            this.resourceProxy.playback.startTime = NaN;
            this.pendingSeekTime = contentStartTime;
        }

        this.mute(this.presoModel.isMuted);

        if (this.isClickToPlay) {
            this.setForClickToPlay();
        }
        else {
            if (this.hasContent) {
                this.notify(NotificationName.VIDEO_LOAD_START);
                this.prepareForPlayback();
            }
            else {
                this.play();
            }
        }
    }

    // override
    play(): void {
        if (!this.presoModel.started) {
            this.adService.initializeAdsLoader();
            this.domProxy && this.domProxy.showAdContainer(true);
            this.createAdPlayer();
        }
        else {
            this.isAdPlaying() ? this.adPlayer.resumeAd() : this.playVideo();
        }
    }

    // override
    pause(): void {
        this.isAdPlaying() && this.adPlayer ? this.adPlayer.pauseAd() : this.pauseVideo();
    }

    // override
    mute(flag: boolean): void {
        this.muteVideo(flag);
        if (this.adPlayer) {
            flag && this.adPlayer.mute();
            !flag && (this.adPlayer.unmute(this.domProxy.getVideoVolume()));
        }
        this.uiMediator && this.uiMediator.setMuteState(flag);
    }

    // override
    seek(position: number): void {
        let seekT = position;

        if (this.adPlayer) {
            const b = this.adPlayer.getBreakForSeekTime(position);
            if (b) {
                seekT = b.startTime;
                this.pendingSeekTime = position;
                this.pendingBreak = b;
                this.notify(NotificationName.SEEK_REDIRECT_START, {
                    requestedSeekTime: position,
                    actualSeekTime: seekT
                });
            }
        }
        this.seekVideo(seekT);
    }

    // override
    getAdBreakTimes(): AdCuePointInterface[] {
        const out: AdCuePointInterface[] = [],
            breaks: AdBreakCollectionInterface = this.adPlayer && this.adPlayer.adBreaks;

        if (!breaks) {
            return out;
        }

        if (breaks.pre) {
            out.push({
                start: breaks.pre.startTime,
                duration: breaks.pre.endTime - breaks.pre.startTime,
                played: breaks.pre.hasPlayed
            });
        }
        if (breaks.mid) {
            let i = breaks.mid.length, b;
            while (i--) {
                b = breaks.mid[i];
                out.unshift({
                    start: b.startTime,
                    duration: b.endTime - b.startTime,
                    played: b.hasPlayed
                });
            }
        }
        if (breaks.post) {
            out.push({
                start: breaks.post.startTime,
                duration: breaks.post.endTime - breaks.post.startTime,
                played: breaks.post.hasPlayed
            });
        }

        return out;
    }

    ////////////////////////////////////////////
    // Respond to content video state changes
    ////////////////////////////////////////////
    protected respondToVideoPlaying(): void {
        this.startPreso();

        this.uiMediator && this.setTransportType();

        super.respondToVideoPlaying();

        if (this.isAdPlaying()) {
            this.notify(NotificationName.AD_PLAYING);
        }
        else if (this.contentSegmentStarted) {
            this.contentPlaybackStateProxy.model.state = PlaybackState.PLAYING;
            this.setPlayingState();
            this.notify(NotificationName.CONTENT_PLAYING);
        }
    }

    protected respondToVideoPaused(): void {
        super.respondToVideoPaused();

        if (this.isAdPlaying()) {
            this.notify(NotificationName.AD_PAUSED);
        }
        else {
            this.contentPlaybackStateProxy.model.state = PlaybackState.PAUSED;
            this.setPausedState();
            this.notify(NotificationName.CONTENT_PAUSED);
        }
    }

    protected respondToVideoTimeUpdate(streamTime: number): void {
        if (this.contentComplete) {
            return;
        }

        const cps: ContentPlaybackStateInterface = this.contentPlaybackStateProxy.model;
        const isAd = this.isAdPlaying();

        this.setAdPlayerBitrate();

        if (isAd) {
            return;
        }

        this.presoModel.streamTime = streamTime;
        cps.time = streamTime;

        // if a seek redirect results in no activity from the SDK, then need to take action...
        if (this.pendingBreak && (streamTime - this.pendingBreak.startTime) > 1) {
            if (this.adPlayer) {
                this.adPlayer.cancelPendingBreak(this.pendingBreak.breakId);
                this.adPlayer.markAsPlayed([this.pendingBreak.breakId]);
            }
            this.pendingBreak = null;
            this.notify(NotificationName.SEEK_REDIRECT_COMPLETE);
            this.pendingSeekTime && this.seek(this.pendingSeekTime);

            return;
        }

        if (streamTime > 0.75 && !this.contentSegmentStarted) {
            this.startContentSegment();
        }
        
        if (this.contentSegmentStarted) {
            super.respondToVideoTimeUpdate(streamTime);
        } 
    }

    protected respondToVideoEnd(): void {
        this.contentComplete = true;
        this.notify(NotificationName.CONTENT_SEGMENT_END);
        this.notify(NotificationName.CONTENT_COMPLETE);
        const adp = !!this.adPlayer;
        if (!adp || (adp && !this.adPlayer.hasPostRoll())) {
            super.respondToVideoEnd();
        }
    }

    protected respondToDurationChange(duration: number): void {
        this.contentPlaybackStateProxy.model.duration = duration;
        this.presoModel.streamDuration = duration;

        if (!isNaN(duration) && duration > 0) {

            !this.contentDurationReleased && this.releaseContentDuration(duration);

            if (this.pendingCuepoints) {
                const temp = this.pendingCuepoints;
                this.pendingCuepoints = null;

                this.notify(NotificationName.AD_CUEPOINTS_AVAILABLE, {
                    cuepoints: temp
                });
            }
        }
    }

    protected respondToBufferingStatusCheck(): void {
        if (!this.isAdPlaying()) {
            super.respondToBufferingStatusCheck();
        }
    }

    protected respondToQualityChange(quality: QualityInterface = null): void {
        this.setAdPlayerBitrate();
        super.respondToQualityChange(quality);
    }

    protected respondToSizeChange(): void {
        this.adPlayer && this.adPlayer.updateSize(this.presoModel.isFullscreen);
    }

    protected respondToError(data: ErrorInfoInterface) {
        this.fatalContentErrorReceived = data.fatal;
        super.respondToError(data);
    }

    ///////////
    // PRIVATE
    private startPreso(): void {
        if (this.presoModel.started) { return; }

        this.presoModel.started = true;

        if (this.uiMediator) {
            this.uiMediator.displayPoster(false);
            this.uiMediator.hideClickCatcher(true);
            this.presoModel.isMuteAtPlayStart && !this.presoModel.userHasUnmuted && this.uiMediator.showUnmutePrompt();
        }

        this.notify(NotificationName.ENABLE_UI);
    }

    private startContentSegment(): void {
        this.contentSegmentStarted = true;
        this.contentPlaybackStateProxy.model.started = true;
        this.domProxy && this.domProxy.showAdContainer(false);
        this.setPlayingState();

        if (this.uiMediator && !this.contentPlaybackStateProxy.model.liveStreamInfo) {
            this.uiMediator.setSeekable(true);
        }

        if (!this.contentStartReleased) {
            this.contentStartReleased = true;
            this.notify(NotificationName.CONTENT_START);
        }

        if (this.fullscreenRestrictedDuringAdPlay && this.uiMediator) {
            this.uiMediator.setFullScreenAccessRestricted(false);
            this.uiMediator.enableFullscreen();
        }

        this.notify(NotificationName.CONTENT_SEGMENT_START);
    }

    setAdPlayerBitrate(): void {
        if (this.adPlayer) {
            const pbsMdl = <ContentPlaybackStateInterface>this.contentPlaybackStateProxy.model,
                br = pbsMdl.bitrate

            br && !isNaN(br) && (this.adPlayer.contentBitrate = br);
        }
    }

    ////////////////////////////
    // Handle ImaAdPlayer Events
    private hAdPlayerEvent(e: EventInterface): void {
        const iae: ImaAdPlayerEventInterface = ImaAdPlayer.event,
            t = e.type;

        // if (e.type.toLowerCase().indexOf('progress') < 0)  {
        //     console.log(`%cImaPm: ${e.type}`, 'color: #900; font-size: 13px; font-weight: bold');
        // }

        switch (t) {
            case iae.AD_CLICK_ELEMENT_VISIBILITY_REQUESTED:
                this.domProxy.showAdClickElement(true);
                break;

            case iae.AD_CLICK:
                this.notify(NotificationName.AD_CLICK, e.data);
                break;

            case iae.AD_SKIPPED:
                this.notify(NotificationName.AD_SKIPPED, e.data);
                break;

            case iae.RAW_AD_SCHEDULE_AVAILABLE:
                // no impl needed - keep 'in case'
                break;

            case iae.AD_CUEPOINTS_AVAILABLE:
                this.pendingCuepoints = e.data.cuepoints;
                break;

            case iae.CONTENT_PAUSE_REQUESTED:
                this.breakMetaDataReleased = false;
                this.respondToPauseRequestFromAdPlayer();
                break;

            case iae.CONTENT_RESUME_REQUESTED:
                this.resumeContentPlay();
                break;

            case iae.AD_BREAK_START:
                this.pendingBreak = null;
                this.respondToContentEnd();
                this.respondToAdBreakStart();
                break;

            case iae.AD_BREAK_METADATA:
                this.pendingBreak = null;
                this.respondToAdBreakMetadata(<AdBreakInfoInterface>e.data);
                break;

            case iae.AD_LOADED:
                this.pendingBreak = null;
                this.respondToAdLoaded(<AdItemInterface>e.data);
                break;

            case iae.AD_START:
                this.pendingBreak = null;
                this.respondToAdStart(<AdItemInterface>e.data);
                break;

            case iae.AD_PROGRESS:
                this.respondToAdProgress(<AdProgressInterface>e.data);
                break;

            case iae.AD_PAUSED:
                this.notify(NotificationName.AD_PAUSED);
                this.notify(NotificationName.RESOURCE_PAUSED);
                break;

            case iae.AD_RESUMED:
                this.notify(NotificationName.AD_PLAYING);
                this.notify(NotificationName.RESOURCE_PLAYING);
                break;

            case iae.AD_FIRST_QUARTILE:
                this.notify(NotificationName.AD_FIRST_QUARTILE);
                break;

            case iae.AD_MIDPOINT:
                this.notify(NotificationName.AD_MIDPOINT);
                break;

            case iae.AD_THIRD_QUARTILE:
                this.notify(NotificationName.AD_THIRD_QUARTILE);
                break;

            case iae.AD_COMPLETE:
                this.respondToAdComplete();
                this.domProxy.showAdClickElement(false);
                this.notify(NotificationName.AD_COMPLETE);
                break;

            case iae.AD_BREAK_COMPLETE:
                this.breakMetaDataReleased = false;
                this.respondToAdBreakComplete();
                break;

            case iae.ALL_ADS_COMPLETED:
                this.breakMetaDataReleased = false;
                this.killAdPlayer();
                break;

            case iae.AD_BREAK_DISCARDED:
                this.breakMetaDataReleased = false;
                this.domProxy.showAdClickElement(false);
                this.notify(NotificationName.AD_BREAK_DISCARDED);
                break;

            case iae.AD_BUFFERING:
                // don't have a good way to know that
                // ad is *NOT* buffering, so no impl
                break;

            case iae.AD_STALLED:
                this.adDataProxy.resetAll();
                this.notify(NotificationName.AD_STALLED, e.data);
                break;

            case iae.AD_ERROR:
                this.domProxy.showAdClickElement(false);
                this.notify(NotificationName.AD_ERROR, e.data);
                break;

            case iae.AD_INIT_ERROR:
                this.killAdPlayer();
                this.notify(NotificationName.AD_ERROR, e.data);
                this.resumeContentPlay();
                break;
        }
    }

    ////////////////////////////////
    // Respond to ImaAdPlayer events
    ////////////////////////////////
    private respondToPauseRequestFromAdPlayer(): void {
        if (this.presoModel.started) {
            this.pauseVideo();
        }
        else {
            this.startPreso();
        }
    }

    private resumeContentPlay(): void {
        this.domProxy.showAdClickElement(false);

        if (this.fatalContentErrorReceived) {
            this.notify(NotificationName.DISABLE_UI);
            return;
        }

        if (!this.hasContent || this.contentComplete) {
            super.respondToVideoEnd();
            return;
        }

        if (!this.contentSegmentStarted) {
            if (!this.contentStartReleased) {
                const contentStartTime = this.resourceProxy.playback.startTime,
                    nonZeroStart = !isNaN(contentStartTime) && contentStartTime > 0;

                if (nonZeroStart) {
                    const breaks = this.getAdBreakTimes(),
                        adjustedStart = this.adjustStartTimeForAdBreakProximity(contentStartTime, breaks);

                    this.resourceProxy.playback.startTime = adjustedStart;
                }
            }

            this.load().then(() => {
                this.startContentSegment();
            });
        }

        if (this.pendingSeekTime) {
            this.notify(NotificationName.SEEK_REDIRECT_COMPLETE);
            this.seekVideo(this.pendingSeekTime);
            this.pendingSeekTime = null;
        }

        this.startPreso();
        this.play();
    }

    private respondToContentEnd(): void {
        if (this.contentSegmentStarted) {
            this.contentSegmentStarted = false;
            this.notify(NotificationName.CONTENT_SEGMENT_END);
        }
    }

    private respondToAdBreakStart(): void {
        this.domProxy && this.domProxy.showAdContainer(true);
        this.presoModel.isCurrentVideoAd = true;

        if (this.uiMediator) {
            this.uiMediator.hideClickCatcher(true);
            this.fullscreenRestrictedDuringAdPlay && this.uiMediator.disableFullscreen();
        }

        this.notify(NotificationName.AD_BREAK_START);
    }

    private respondToAdBreakMetadata(data: AdBreakInfoInterface): void {
        if (!this.breakMetaDataReleased) {
            this.adDataProxy.breakInfo = data;
            this.breakMetaDataReleased = true;
            this.notify(NotificationName.AD_BREAK_METADATA, { adBreakInfo: data });
        }
    }

    private respondToAdStart(data: AdItemInterface): void {
        this.adDataProxy.adInfo = data;

        this.notify(NotificationName.AD_START, { adInfo: data });
        this.notify(NotificationName.RESOURCE_PLAYING);

        this.checkPreload();
    }

    private checkPreload(): void {
        const preload = this.resourceProxy.ad.ima.preloadContentAtEndOfPreRoll === true;
        const lastAd = this.adDataProxy.adInfo.adPosition === this.adDataProxy.breakInfo.adTotal;

        if (preload && lastAd) {
            const handler = (event: EventInterface) => {
                const ad = <AdProgressInterface>event.data;
                const threshold = Util.clampValue(Math.ceil(ad.duration / 4), 2, 10);
                const time = Math.round(ad.currentTime);
                if (time >= threshold) {
                    this.adPlayer.off(ImaAdPlayer.event.AD_PROGRESS, handler);
                    this.load();
                }
            }
            this.adPlayer.on(ImaAdPlayer.event.AD_PROGRESS, handler);
        }
    }

    private respondToAdComplete(): void {
        this.adDataProxy.resetAd();
    }

    private respondToAdLoaded(data: AdItemInterface): void {
        this.adDataProxy.adInfo = data;
    }

    private respondToAdProgress(data: AdProgressInterface) {
        this.notify(NotificationName.AD_TIME_UPDATE, {
            currentTime: data.currentTime,
            duration: data.duration
        });
    }

    private respondToAdBreakComplete(): void {
        this.domProxy && this.domProxy.showAdContainer(false);
        this.presoModel.isCurrentVideoAd = false;
        this.notify(NotificationName.AD_BREAK_COMPLETE);
    }

    ////////////////
    // init and util 

    private get adService(): ImaAdServiceInterface {
        return <ImaAdServiceInterface>this.facade.retrieveService(ServiceName.ImaAd);
    }

    private assembleAdContextInfo(muted: boolean): AdPlaybackContextInterface {
        const sys = <SystemServiceInterface>this.facade.retrieveService(ServiceName.System);

        return {
            buildEnv: (<BuildInfoInterface>this.getModel(ModelName.BuildInfo)).env,
            platform: sys.platform,
            mutedPlayback: muted
        }
    }

    private listenToAdPlayer(flag: boolean): void {
        if (!this.adPlayer) { return; }
        const iae: ImaAdPlayerEventInterface = ImaAdPlayer.event,
            m = flag ? 'on' : 'off';

        for (const q in iae) {
            this.adPlayer[m](iae[q], flag ? (e: EventInterface) => this.hAdPlayerEvent(e) : null);
        }
    }


    private createAdPlayer(): void {
        const resource: ResourceConfigurationInterface = (<ResourceProxy>this.facade.retrieveProxy(ProxyName.ResourceProxy)).resource,
            muteAtStart: boolean = this.presoModel.isMuteAtPlayStart,
            options: ImaAdPlayerOptions = {
                presentationContainer: this.domProxy.getMain(),
                playheadInterface: this.domProxy.getVideo(),
                initialPlayState: {
                    autoplay: this.presoModel.isAutoplay,
                    muted: muteAtStart
                },
                playAdsAfterTime: this.breaksToSkip && this.breaksToSkip.match(/^1/) !== null ? 0 : null,
                adService: this.adService,
                logger: this.logger,
                ad: resource.ad,
                adCallUrl: this.getAdCallUrl(resource, muteAtStart),
                enableStalledAdCheck: true
            };

        this.adPlayer = new ImaAdPlayer(options);

        if (this.breaksToSkip) {
            const posArr = this.breaksToSkip.split(',').map((x: string): number => parseInt(x)),
                ids: string[] = [];

            let i = posArr.length;
            while (i--) {
                const v = posArr[i],
                    vPrime = v === 1 ? 'pre_0' : 'mid_' + String(v - 1);
                ids.push(vPrime);
            }

            this.adPlayer.markAsPlayed(ids);
        }

        this.listenToAdPlayer(true);
    }

    private getAdCallUrl(resource: ResourceConfigurationInterface, muteAtStart: boolean): string {
        const acu = resource.ad.ima.adCallUrl,
            hasUrl = !Util.isEmpty(acu),
            callAssembler: ImaAdCallAssembler = new ImaAdCallAssembler();

        if (hasUrl) {
            return muteAtStart ? callAssembler.substituteStringForMutedPlayback(acu) : acu;
        }

        const call = callAssembler.assembleAdCall(resource, this.assembleAdContextInfo(muteAtStart));

        return call;
    }

    private killAdPlayer(): void {
        if (this.adPlayer) {
            this.listenToAdPlayer(false);
            this.adPlayer.destroy();
            this.adPlayer = null;
        }
    }

    onRegister() {
        super.onRegister();
        this.domProxy && this.domProxy.showAdContainer(false);
        this.adDataProxy = new AdDataProxy(ProxyName.AdDataProxy, null);
        this.facade.registerProxy(this.adDataProxy);
    }
}
