import ObjectAssign from 'es6-object-assign';
import 'es6-promise/auto';
import { Shell } from './app/Shell';
import { BaseClass } from './core/BaseClass';
import { CoreEvent } from './core/CoreEvent';
import { Emitter } from './core/Emitter';
import { Logger } from './core/Logger';
import { QueryString } from './core/QueryString';
import { Utilities } from './core/Utilities';
import { AdContext } from './enum/AdContext';
import { Autoplay } from './enum/Autoplay';
import { Browser } from './enum/Browser';
import { Device } from './enum/Device';
import { ErrorCode } from './enum/ErrorCode';
import { KeyboardScope } from './enum/KeyboardScope';
import { LocalizationId } from './enum/LocalizationId';
import { LocalizedLanguage } from './enum/LocalizedLanguage';
import { LogLevel } from './enum/LogLevel';
import { NativePlugin } from './enum/NativePlugin';
import { Os } from './enum/Os';
import { PerformanceMode } from './enum/PerformanceMode';
import { Platform } from './enum/Platform';
import { PlaybackState } from './enum/PlaybackState';
import { PlayerEvent } from './enum/PlayerEvent';
import { PluginPriority } from './enum/PluginPriority';
import { QualityCategory } from './enum/QualityCategory';
import { StreamType } from './enum/StreamType';
import { TextTrackKind } from './enum/TextTrackKind';
import { BuildInfoInterface } from './iface/BuildInfoInterface';
import { PlayerOptionsInterface } from './iface/PlayerOptionsInterface';
import { ResourceConfigurationInterface } from './iface/ResourceConfigurationInterface';
import { StrAnyDict } from './iface/StrAnyDict';
import { SystemInfoApiInterface } from './iface/SystemInfoApiInterface';
import { VideoPlayerInterface } from './iface/VideoPlayerInterface';
import { VideoPlayerReadyCallback } from './iface/VideoPlayerReadyCallback';
import { PlaybackAdapterType } from './playback/enum/PlaybackAdapterType';

export * from './cvp.types';

ObjectAssign.polyfill();

const bi = (function () {
    const frag = /^(__)([A-Z_]+)(__)$/;
    const i = {
        env: '__ENV__',
        playerVersion: '__PLAYER_VER__',
        playerName: '__PLAYER_NAME__',
        buildTime: '__BUILD_TIME__',
    };
    i.playerName = !frag.test(i.playerName) ? i.playerName : 'CBSi Video Player (CVP)';
    i.playerVersion = !frag.test(i.playerVersion) ? i.playerVersion : '-dev';
    i.buildTime = !frag.test(i.buildTime) ? i.buildTime : (new Date()).toLocaleDateString();

    Logger.log(`${i.playerName}; Ver. ${i.playerVersion}  ${i.buildTime}`);

    return i;
}());

export default {

    createVideoPlayer(options: PlayerOptionsInterface, callback?: VideoPlayerReadyCallback): Promise<VideoPlayerInterface> {
        return new Promise((resolve, reject) => {
            Shell.createVideoPlayer(options, (player: VideoPlayerInterface, error: StrAnyDict) => {
                if (Utilities.isFunction(callback)) {
                    callback(player, error);
                }

                if (error != null) {
                    reject(error);
                }
                else {
                    resolve(player);
                }
            }, this.buildInfo);
        });
    },

    removeVideoPlayer(playerId: string): Promise<void> {
        return Shell.removeVideoPlayer(playerId);
    },

    createResourceConfig(config?: Partial<ResourceConfigurationInterface>): ResourceConfigurationInterface {
        return Shell.createResourceConfig(config);
    },

    get buildInfo(): BuildInfoInterface {
        return bi;
    },

    get systemInfo(): SystemInfoApiInterface | null {
        return Shell.getSysInfoForUser();
    },

    core: {
        BaseClass: BaseClass,
        CoreEvent: CoreEvent,
        Emitter: Emitter,
    },

    util: {
        QueryString: QueryString,
        Util: Utilities
    },

    AdContext: AdContext,
    Autoplay: Autoplay,
    Browser: Browser,
    Device: Device,
    ErrorCode: ErrorCode,
    KeyboardScope: KeyboardScope,
    LocalizationId: LocalizationId,
    LocalizedLanguage: LocalizedLanguage,
    LogLevel: LogLevel,
    NativePlugin: NativePlugin,
    Os: Os,
    PerformanceMode: PerformanceMode,
    Platform: Platform,
    PlaybackAdapterType: PlaybackAdapterType,
    PlaybackState: PlaybackState,
    PlayerEvent: PlayerEvent,
    PluginPriority: PluginPriority,
    QualityCategory: QualityCategory,
    StreamType: StreamType,
    TextTrackKind: TextTrackKind
};
