import { Logger } from '../core/Logger';
import { PlayerEvent } from '../enum/PlayerEvent';

/**
 * This is a collection of deprecated functionality
 */
export class Deprecated {
    static decorateEventType(obj: any) {
        Object.defineProperty(obj, 'EventType', {
            get() {
                Logger.warn('EventType instance property is deprecated. Please use the cvp.PlayerEvent enum instead. - Access to this property will be completely removed March 16th, 2021')
                return PlayerEvent;
            },
            enumerable: true,
            configurable: false
        });
        return obj;
    }

    static checkEventName(name: string) {
        if (/[A-Z]+/.test(name)) {
            Logger.warn('Event name is being forced to lowercase. Please use the cvp.PlayerEvent enum instead of a string literal.');
            name = name.toLowerCase();
        }
        return name;
    }
}
