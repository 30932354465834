import { Util } from '../../core/Util';
import { Range } from '../../iface';
import { LiveStreamInfoInterface } from '../../iface/LiveStreamInfoInterface';
import { BasePlaybackAdapter } from './BasePlaybackAdapter';


export abstract class BaseHtml5Adapter extends BasePlaybackAdapter {

    private lastKnownTimes: any = {
        absolute: NaN,
        relative: NaN,
        end: NaN
    };

    seek(position: number): void {
        if (this.pIsLiveStream) {
            const lsi = this.liveStreamInfoVO;
            position = Util.mapToRange(position, 0, lsi.relativeDuration, lsi.safeSeekingTime, lsi.safeSeekingDuration);
        }
        super.seek(position);
    }

    ////////////////////
    //Accessors
    ////////////////////
    get time(): number {
        return (this.pIsLiveStream) ? this.liveStreamInfoVO.relativeTime : this.videoSurface.time;
    }

    get duration(): number {
        return (this.pIsLiveStream) ? this.liveStreamInfoVO.relativeDuration : this.videoSurface.duration;
    }

    get seekable(): Range {
        const result = { start: 0, end: 0 };
        const video = this.videoSurface.video;
        const range = video.seekable;
        const index = range.length - 1;
        if (index >= 0) {
            result.start = range.start(index);
            result.end = range.end(index);
        }

        return result;
    }

    get segmentDuration() {
        return 6;
    }

    get liveStreamInfo(): LiveStreamInfoInterface {
        const details: LiveStreamInfoInterface = this.liveStreamInfoVO;
        const video = this.videoSurface.video;
        const { start, end } = this.seekable;
        const time = video.currentTime;
        const duration = end - start;
        const segmentDuration = this.segmentDuration;
        const count = this.playback.liveEdgeSyncFragmentCount;

        details.relativeTime = Math.max(time - start, 0);
        details.relativeDuration = duration;
        details.absoluteDuration = Date.now();
        details.dvrWindowSize = Math.floor(duration);
        details.liveEdgeOffset = (segmentDuration * count) + segmentDuration;
        details.safeSeekingTime = Math.ceil(start);
        details.safeSeekingDuration = Math.floor(end);
        details.isPlayingLive = Math.ceil(time + details.liveEdgeOffset) >= details.safeSeekingDuration;

        if (end != this.lastKnownTimes.end) {
            this.lastKnownTimes.end = end;
            this.lastKnownTimes.absolute = Math.round(details.absoluteDuration - (details.relativeTime * 1000));
            this.lastKnownTimes.relative = time;
        }

        const delta = Math.round((time - this.lastKnownTimes.relative) * 1000);
        details.absoluteTime = this.lastKnownTimes.absolute + delta;

        //Temp log line until we insure Tracking is happy with this solution.  Follow VTG-1977 for more info. 
        //console.log('Live streams UTC time', details.absoluteTime, new Date(Math.floor(details.absoluteTime * 1000)));

        return details;
    }
}             
