import { Emitter } from '../../core/Emitter';
import { Util } from '../../core/Util';
import { LogLevel } from '../../enum/LogLevel';
import { TextTrackKind } from '../../enum/TextTrackKind';
import { MetadataCuepoint, SystemServiceInterface } from '../../iface';
import { LoggerInterface } from '../../iface/LoggerInterface';
import { PlaybackAdapterConfigInterface } from '../../iface/PlaybackAdapterConfigInterface';
import { TextTrackEvents } from '../enum/TextTrackEvents';
import { TextTrackMode } from '../enum/TextTrackMode';
import { TextTrackSurfaceEvents } from '../enum/TextTrackSurfaceEvents';
import { TextTrackType } from '../enum/TextTrackType';
import { SmpteToVttCueConverter } from '../util/SmpteToVttCueConverter';


export class TextTrackSurface extends Emitter {

    private logger: LoggerInterface;
    private video: HTMLVideoElement;
    private system: SystemServiceInterface;
    private config: PlaybackAdapterConfigInterface;

    private pTextTracks: TextTrackList = null;
    private currentTextTrack: TextTrack = null;
    private currentTextTrackMode: TextTrackMode = TextTrackMode.DISABLED;
    private allowTextTrackCueDispatch: boolean = true;
    private existingTrack: Array<TextTrack> = [];

    private onTextTrackAddedHandler: EventListenerOrEventListenerObject = (e: TrackEvent) => this.onTextTrackAdded(e);
    private onVideoTextTrackAddedHandler: EventListenerOrEventListenerObject = (e: TrackEvent) => this.onVideoTextTrackAdded(e);
    private onTextTrackChangeHandler: EventListenerOrEventListenerObject = (e: TrackEvent) => this.onTextTrackChange(e);
    private onCueChangeHandler: EventListenerOrEventListenerObject = (e: Event) => this.onCueChange(e);

    constructor(video: HTMLVideoElement, config: PlaybackAdapterConfigInterface, logger: LoggerInterface) {
        super();
        this.system = config.system;
        this.config = config;
        this.video = video;
        this.pTextTracks = video.textTracks;

        this.currentTextTrackMode = config.textTrackSettings.mode;
        this.allowTextTrackCueDispatch = !config.textTrackSettings.native;

        // These functions have the potential to be called multiple times in rapid succession. 
        // Debounce to allow multiple changes to be applied in a single pass.
        this.processTracks = Util.debounce(this.processTracks.bind(this), 100);
        this.addTracks = Util.debounce(this.addTracks.bind(this), 25);

        this.addEvents();

        this.logger = logger;
        this.logger.log(LogLevel.INFO, 'TextTrackSurface created');
    }

    destroy() {
        this.removeEvents();
        Util.forEach(this.pTextTracks, (t) => this.cleanupTrack(t));
        Util.forEach(this.video.querySelectorAll('track'), (element) => this.video.removeChild(element));
        super.destroy();
    }

    clearCue() {
        Util.clearCue(this.currentTextTrack, this.video.currentTime);
    }

    private cleanupTrack(track: TextTrack) {
        function cleanupCue(cue: TextTrackCue) {
            try {
                if (cue) {
                    track.removeCue(cue);
                }
            }
            catch (error) {
                // ignore errors and continue cleanup
            }
        }

        // Cues must be cleaned up in reverse order. Otherwise half of the cues will be left behind.
        Util.forEachReverse(track.cues, cleanupCue);
        Util.forEachReverse(track.activeCues, cleanupCue);

        //@ts-ignore
        track.expired = true;

        if (this.isTextTrack(track.kind)) {
            //hls.js disablement
            //@ts-ignore
            track.textTrack1 = true;
            //@ts-ignore
            track.textTrack2 = true;

            //dashjs disablement                    
            //@ts-ignore
            track.isTTML = true; //forces dash.js to use the new track it creates for vtt or ttml sideload on next load.  
            //@ts-ignore
            track.isEmbedded = false;
        }

        //general disablement
        //@ts-ignore
        track.mode = TextTrackMode.DISABLED;
    }

    /**
     * Sidecar use only
     */
    set timeTextSrc(url: string) {
        const isVtt = url.indexOf('.vtt') >= 0;
        if (isVtt) {
            this.createVttTextTrack(this.video, url);
        }
        else {
            this.processSmpteTimedText(url);
        }
    }

    set textTrackMode(mode: TextTrackMode) {
        if (this.currentTextTrack) {
            this.setTrackMode(mode);
        }
        else {
            this.logger.log(LogLevel.WARN, `No text track detected`);
        }
    }

    set textTrack(newTrack: TextTrack) {
        if (!this.isValidTrack(newTrack) || newTrack == this.currentTextTrack) {
            return;
        }

        // disable old track
        if (this.currentTextTrack && this.currentTextTrack.mode !== TextTrackMode.DISABLED) {
            this.currentTextTrack.mode = TextTrackMode.DISABLED;
        }

        this.currentTextTrack = newTrack;

        // re-apply the track mode to the new text track
        this.setTrackMode(this.currentTextTrackMode).then(() => {
            this.logger.log(LogLevel.INFO, `${newTrack.language} is being set as the current text track`);
            this.emit(TextTrackSurfaceEvents.TEXT_TRACK_CHANGE, newTrack);
        });
    }

    get textTrack(): TextTrack {
        return this.currentTextTrack;
    }

    get textTracks(): TextTrack[] {
        return Util.toArray(this.pTextTracks).filter(t => this.isValidTrack(t));
    }

    get activeTextTracks(): TextTrack[] {
        return Util.toArray(this.pTextTracks).filter(t => !this.isExpired(t));
    }

    private setTrackMode(mode: TextTrackMode): Promise<void> {
        return new Promise((resolve, reject) => {
            const modeChanged = (mode != this.currentTextTrackMode);
            const applyMode = (): void => {
                // mode is typed as number with string enum reserve mapped so even though you can set string, TS wants int.
                //@ts-ignore
                this.currentTextTrack.mode = mode;

                if (modeChanged) {
                    // Dispatch the event after the promise resolves
                    setTimeout(() => this.emit(TextTrackSurfaceEvents.TEXT_TRACK_DISPLAY_MODE_CHANGE, { mode }), 0);
                }

                // Logging
                let msg: string = TextTrackMode.DISABLED;
                mode === TextTrackMode.HIDDEN && (msg = 'enabled for event driven external custom rendering');
                mode === TextTrackMode.SHOWING && (msg = 'enabled for native rendering by the user agent');
                this.logger.log(LogLevel.INFO, `The ${this.currentTextTrack.kind} track for language code ${this.currentTextTrack.language} is being ${msg} `);

                resolve();
            };

            this.currentTextTrackMode = mode;

            // HACK: FF has issue with setting showing from disabled need to set to hidden then showing with timeout. 
            if (mode === TextTrackMode.SHOWING &&
                this.currentTextTrack.mode === TextTrackMode.DISABLED) {

                // Temporarily set to hidden to get around FF issue
                this.currentTextTrack.mode = TextTrackMode.HIDDEN;
                setTimeout(applyMode, 10);
            }
            else {
                applyMode();
            }
        });
    }

    // Events
    private onVideoTextTrackAdded(e: TrackEvent): void {
        // hlsjs reuses tracks for 608/708, so remove the expired flag.
        const track = e.track;
        //@ts-ignore
        track.expired = false;
        this.addTracks();
    }

    private onTextTrackAdded(e: TrackEvent): void {
        // VTG-2215 - hlsjs creates an empty "subtitles" track. Ignore it.
        const track = e.track;

        if (track.kind == TextTrackKind.SUBTITLES && !track.language && !track.label) {
            return;
        }

        this.addTracks();
    }

    private onTextTrackChange(e: Event): void {
        this.processTracks();
    }

    private onCueChange(e: Event): void {
        const t = <TextTrack>e.target,
            cues = t && t.activeCues;

        if (!cues || !cues.length) {
            return;
        }

        switch (t.kind) {
            case TextTrackType.METADATA:
                this.processId3(cues[0]);
                break;
            case TextTrackType.CAPTIONS:
            case TextTrackType.SUBTITLES:
                if (this.allowTextTrackCueDispatch && t.mode === TextTrackMode.HIDDEN) {
                    this.emit(TextTrackSurfaceEvents.TEXT_CUEPOINT, { activeCues: cues });
                }
                break;
        }
    }

    private addTracks(): void {
        Util.forEach(this.activeTextTracks, (t) => {
            t.addEventListener(TextTrackEvents.CUE_CHANGE, this.onCueChangeHandler);
            t.mode = t.kind === TextTrackType.METADATA ? TextTrackMode.HIDDEN : TextTrackMode.DISABLED;

            if (!this.isTextTrack(t.kind) || this.isDuplicateTrack(t)) {
                return;
            }

            this.logger.log(LogLevel.INFO, `A ${t.kind} text track was added`);
            this.emit(TextTrackSurfaceEvents.TEXT_TRACK_ADDED, t);
        });

        // If this is the first time through, select the best track from the list
        if (!this.currentTextTrack) {
            this.textTrack = this.findDefaultTrack(this.textTracks, this.config.textTrackSettings.language);
            if (!this.textTrack) {
                return;
            }
            this.textTrack.mode = this.config.textTrackSettings.mode;
            this.emit(TextTrackSurfaceEvents.TEXT_TRACK_AVAILABLE);
        }

        // Native hls playback in Safari won't trigger a text track change event, so do it manually
        this.processTracks();
    }

    private processTracks(): void {
        const { enabled, native, enabledMode } = this.config.textTrackSettings;

        // Only search valid tracks
        const tracks = this.textTracks;

        // hlsjs sometimes enables expired tracks. Ensure all expired tracks are disabled.
        Util.forEach(this.pTextTracks, t => {
            if (this.isExpired(t) && t.mode != TextTrackMode.DISABLED) {
                t.mode = TextTrackMode.DISABLED;
            }
        });

        // Handle non-native text rendering separately
        if (!native) {
            if (enabled) {
                // Streaming libraries sometimes set the mode to "showing"
                const track = Util.find(tracks, t => t.mode == TextTrackMode.SHOWING);
                if (track) {
                    track.mode = enabledMode;
                }
            }
            else {
                // Streaming libraries sometimes set the mode to "hidden"
                const track = Util.find(tracks, t => t.mode != TextTrackMode.DISABLED);
                if (track) {
                    track.mode = TextTrackMode.DISABLED;
                }
            }
            return;
        }

        // Check for change to the text track settings via native UIs or DOM APIs
        const track = Util.find(tracks, t => t.mode == TextTrackMode.SHOWING);

        if (enabled) {
            // no change
            if (track == this.currentTextTrack) {
                return;
            }

            // If no enabled track was found, then the mode has changed
            if (!track) {
                this.textTrackMode = TextTrackMode.DISABLED;
            }
            else {
                // Otherwise, the a different track was enabled
                this.textTrack = track;
            }
        }
        else {
            // no change
            if (!track) {
                return;
            }

            // Update the track if it has changed
            if (track != this.currentTextTrack) {
                this.textTrack = track;
            }
            // Update the mode 
            this.textTrackMode = TextTrackMode.SHOWING;
        }
    }

    private processId3(cue: any): void {
        const val = cue.value,
            arr = this.config.resource.playback.id3OwnerIds;

        if (!val) {
            return;
        }

        if (val.key === 'TXXX') {
            this.transmitMetadataCuepoint('google_dai', val);
        }
        else if (val.key === 'PRIV' && val.info === 'com.cbsi.live.sg') { // VTG-1813 Segment level tracking            
            const data = {
                info: val.info,
                data: String.fromCharCode.apply(null, new Uint8Array(cue.value.data))
            }

            this.transmitMetadataCuepoint('com.cbsi.live.sg', data);
        }
        else {
            // Gated on ownerId array
            Util.forEach(arr, (id) => {
                if (val.info.indexOf(id) !== -1) {
                    this.transmitMetadataCuepoint(id, val);
                }
            });
        }
    }

    private transmitMetadataCuepoint(id: string, d: any) {
        const vo: MetadataCuepoint = {
            id: id,
            info: d.info,
            data: d.data
        }
        this.emit(TextTrackSurfaceEvents.METADATA_CUEPOINT, vo);
    }

    private addEvents(): void {
        this.pTextTracks.addEventListener(TextTrackEvents.ADD_TRACK, this.onTextTrackAddedHandler);
        this.pTextTracks.addEventListener(TextTrackEvents.CHANGE, this.onTextTrackChangeHandler);

        // HACK: Workaround for a bug in hlsjs where the `addtrack` event is dispatched from
        //       the video element instead of the text track list when swithing to a resource
        //       with 608/708 captions.
        (<any>this.video).addEventListener(TextTrackEvents.ADD_TRACK, this.onVideoTextTrackAddedHandler);
    }

    private removeEvents(): void {
        this.pTextTracks.removeEventListener(TextTrackEvents.ADD_TRACK, this.onTextTrackAddedHandler);
        this.pTextTracks.removeEventListener(TextTrackEvents.CHANGE, this.onTextTrackChangeHandler);

        // HACK: hlsjs 608 workaround
        (<any>this.video).removeEventListener(TextTrackEvents.ADD_TRACK, this.onVideoTextTrackAddedHandler);

        Util.forEach(this.pTextTracks, (t) => t.removeEventListener(TextTrackEvents.CUE_CHANGE, this.onCueChangeHandler));
    }

    // Util
    private isDuplicateTrack(t: TextTrack): boolean {
        // Check for duplicate tracks generated by dashjs when switching periods on a stream with 608 captions
        const result = this.existingTrack.some(track => t.language == track.language && t.label == track.label && t.kind == track.kind);
        if (!result) {
            this.existingTrack.push(t);
        }
        return result;
    }

    private isTextTrack(type: string) {
        return type === TextTrackType.CAPTIONS || type === TextTrackType.SUBTITLES;
    }

    private isExpired(track: TextTrack): boolean {
        //@ts-ignore
        return track.expired;
    }

    private isValidTrack(track: TextTrack): boolean {
        if (!track) {
            return false;
        }

        if (!this.isTextTrack(track.kind)) {
            return false;
        }

        if (this.isExpired(track)) {
            return false;
        }

        return true;
    }

    // TODO: move strings into emum or props?
    private createVttTextTrack(el: HTMLVideoElement, src: string): void {
        // For Src to load and parse VTT must be this way. 
        const t = document.createElement('track');
        t.kind = TextTrackType.CAPTIONS;
        t.label = 'English';
        t.srclang = 'en';
        t.id = 'sidecar-vtt';
        t.src = src;
        el.appendChild(t);

        // Safari natvie will set the mode to "showing" causing a flicker
        t.track.mode = TextTrackMode.DISABLED;
    }

    private processSmpteTimedText(url: string) {
        const converter = new SmpteToVttCueConverter(url, this.system);
        converter.convert().then(result => {
            this.createSmpteTextTrack(this.video, result);
            this.logger.log(LogLevel.INFO, 'Smpte XML conversion and text track creation successful');
        }).catch(e => {
            this.logger.log(LogLevel.INFO, 'Smpte XML conversion and text track creation error');
        });
    }

    private createSmpteTextTrack(el: HTMLVideoElement, cues: Array<VTTCue>): void {
        try {
            const t = el.addTextTrack(TextTrackType.CAPTIONS, 'English', 'en');
            Util.forEach(cues, (item) => t.addCue(item));
        } catch (error) {
            this.logger.log(LogLevel.INFO, error);
        }
    }

    private findDefaultTrack(tracks: TextTrack[], language: string): TextTrack {
        let regex = new RegExp(language, "i");
        let track = Util.find(tracks, t => regex.test(t.language));
        if (!track) {
            const short = Util.parseLanguageTag(language).language;
            if (short != language) {
                regex = new RegExp(short, "i");
                track = Util.find(tracks, t => regex.test(t.language));
            }
        }
        return track || tracks[0];
    }
}
