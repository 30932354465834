import { PlayerEvent } from '../enum/PlayerEvent';
import { AdErrorEventInterface, ImaAdErrorInfoInterface } from '../iface';
import { StrAnyDict } from '../iface/StrAnyDict';
import { PlayerErrorEvent } from './PlayerErrorEvent';

export class AdErrorEvent extends PlayerErrorEvent implements AdErrorEventInterface {

    private eInfo: ImaAdErrorInfoInterface;

    constructor(code: any, msg: string, data: ImaAdErrorInfoInterface, fatal: boolean) {
        super(PlayerEvent.AD_ERROR, code, msg, data, fatal);
        this.eInfo = <ImaAdErrorInfoInterface>(this.data || {});
    }

    get errorCode(): number | null {
        return this.eInfo.errorCode || null;
    }

    get innerErrorMessage(): string {
        return this.eInfo.innerErrorMessage || null;
    }

    get sdkVersion(): string {
        return this.eInfo.sdkVersion || null;
    }

    get vastErrorCode(): number {
        return this.eInfo.vastErrorCode || null;
    }

    get requestContext(): StrAnyDict {
        return this.eInfo.requestContext || null;
    }

    get adInfo() {
        return this.eInfo.adInfo || null;
    }
}
