import { AsyncDataRequest } from '../../dataservice/AsyncDataRequest';
import { AsyncDataRequestOptions } from '../../dataservice/AsyncDataRequestOptions';
import { XhrResponseType } from '../../enum/XhrResponseType';
import { CmsDataRetrieverCallback, CmsDataRetrieverOptions } from '../../iface';
import { ErrorRecoveryInterface } from '../../iface/ErrorRecoveryInterface';
import { EventInterface } from '../../iface/EventInterface';
import { StrStrDict } from '../../iface/StrStrDict';


// Will make an XHR call upon instantiation.
// See CmsDataRetrieverOptions; either a uriTemplate and id, or a url may be supplied.
// A responseType and callback are required. 
export class CmsDataRetriever {

    constructor(options: CmsDataRetrieverOptions) {
        const errRecovery = options.errorRecovery || null;
        let errorMsg: string | null = null;

        if (options.url) {
            this.retrieveResource(options.url, options.responseType, options.callback, errRecovery);
        }
        else if (options.uriTemplate && options.tokenMap) {
            const uri = this.replaceUriTokens(options.uriTemplate, options.tokenMap);
            this.retrieveResource(uri, options.responseType, options.callback, errRecovery);
        }
        else {
            errorMsg = 'Invalid options passed to CmsDataRetriever.'
        }

        if (errorMsg) {
            options.callback && options.callback(null, {
                status: 0,
                error: true,
                url: 'n/a',
                message: errorMsg
            });
        }
    }

    private replaceUriTokens(uri: string, tokenMap: StrStrDict): string {
        let u: string = uri;

        if (tokenMap) {
            for (const q in tokenMap) {
                u = u.replace(q, tokenMap[q]);
            }
        }
        return u;
    }

    private retrieveResource(
        u: string,
        responseType: XhrResponseType,
        callback: CmsDataRetrieverCallback,
        er: ErrorRecoveryInterface = null
    ): void {

        const req = AsyncDataRequestOptions.create({
            responseType: responseType, // REM: Use XhrResponseType.TEXT for MPX SMIL! (is converted to Document by AsyncDataRequest)
            timeout: 2000,
            url: u,
            errorRecovery: er,
            onComplete: (e: EventInterface) => {
                const doc = e.data.response,
                    err = e.data.error;
                callback && callback(doc, err ? e.data : null);
            }
        });

        new AsyncDataRequest(req);
    }
}
