import { DataConfigInterface } from '../../iface/DataConfigInterface';
import { ModelInterface } from '../../iface/ModelInterface';
import { PlayerDomInterface } from '../../iface/PlayerDomInterface';
import { StrAnyDict } from '../../iface/StrAnyDict';
import { VideoInterface } from '../../iface/VideoInterface';
import { ConfigurablePropertyContainer } from './ConfigurablePropertyContainer';


export class PlayerDom implements PlayerDomInterface, ModelInterface {

    private model: StrAnyDict;

    constructor(config: DataConfigInterface) {
        this.model = new ConfigurablePropertyContainer(config);
    }

    static get modelName(): string { return 'PlayerDom'; }

    get data(): PlayerDomInterface { return <PlayerDomInterface>(this.model.data); }

    reset(): void { this.model.reset(); }


    set main(value: HTMLElement) { this.model.main = value; }
    get main(): HTMLElement { return this.model.main; }

    set video(value: VideoInterface) { this.model.video = value; }
    get video(): VideoInterface { return this.model.video; }

    set videoContainer(value: HTMLElement) { this.model.videoContainer = value; }
    get videoContainer(): HTMLElement { return this.model.videoContainer; }

    set ccContainer(value: HTMLElement) { this.model.ccContainer = value; }
    get ccContainer(): HTMLElement { return this.model.ccContainer; }

    set adContainer(value: HTMLElement) { this.model.adContainer = value; }
    get adContainer(): HTMLElement { return this.model.adContainer; }

    set panelContainer(value: HTMLElement) { this.model.panelContainer = value; }
    get panelContainer(): HTMLElement { return this.model.panelContainer; }

    set adClickEl(value: HTMLElement) { this.model.adClickEl = value; }
    get adClickEl(): HTMLElement { return this.model.adClickEl; }
}
