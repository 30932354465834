import { DataConfigInterface } from '../../iface/DataConfigInterface';
import { PropertyConfigInterface } from '../../iface/PropertyConfigInterface';
import { StrAnyDict } from '../../iface/StrAnyDict';
import { Validator } from './Validator';


export class Generator {

    protected constructor() { }

    static generateModel(
        scope: any,
        config: DataConfigInterface,
        dataContainer: StrAnyDict,
        defaultValueContainer: StrAnyDict
    ): void {

        for (let q in config) {
            this.createProperty(q, config[q], scope, dataContainer, defaultValueContainer);
        }
    }

    private static createProperty(
        name: string,
        config: PropertyConfigInterface,
        scope: any,
        dataCtr: StrAnyDict,
        defaultValueCtr: StrAnyDict
    ): void {

        let validator = config.validator,
            type = config.type,
            defValue = config.defaultValue,
            enumerable = config.enumerable !== false,
            setterTransformer = this.getTransformer(config, 'setter', scope),
            getterTransformer = this.getTransformer(config, 'getter', scope);

        if (config.writable === false) {
            Object.defineProperty(scope, name, {
                enumerable: enumerable,
                value: defValue,
                writable: false
            });
            dataCtr[name] = defValue;
        }
        else {
            Object.defineProperty(scope, name, {
                enumerable: enumerable,
                set: function (val: any) {
                    let canWarn = console && console.warn,
                        txVal = setterTransformer(val);

                    // note: value is first transformed, then validated
                    if (validator && !validator(txVal)) {
                        canWarn && console.warn(`Invalid value '${txVal}' for property ${name}`);

                        return;
                    }
                    if (!Validator.checkType(txVal, type, defValue === null)) {
                        canWarn && console.warn(`Invalid type supplied for property ${name}`);

                        return;
                    }
                    dataCtr[name] = txVal;
                },
                get: function () {
                    return getterTransformer(dataCtr[name]);
                }
            });

            dataCtr[name] = defValue;
        }

        defaultValueCtr[name] = defValue;
    }

    private static getTransformer(pCfg: PropertyConfigInterface, which: string, scope: any) {
        const tx = pCfg[which + 'Transformer'];

        if (tx && typeof tx === 'function') {
            return tx.bind(scope);
        }

        return this.blankTransformer;
    }

    private static blankTransformer(val: any) {
        return val;
    }

}
