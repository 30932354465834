import { dai } from '../../dai';

/*
    export interface AdResponse extends StrAnyDict {
        seq: number;
        start: number;
        duration: number;
        title: string;
        description: string;
        advertiser: string;
        ad_system: string;
        ad_id: string;
        creative_id: string;
        creative_ad_id: string;
        deal_id: string;
        clickthrough_url: string;
        wrappers: AdWrapper[];
        events: AdEventObject[];
        verifications?: AdVerification[]; 
    }

    export interface AdPodResponse extends StrAnyDict {
        type: string;
        start: number;
        duration: number;
        ads: AdResponse[];
    }
*/

export class AdPodInfo {

    private data: dai.AdBreakResponse;
    private pos!: number;
    private podIdx: number;

    constructor(data: dai.AdBreakResponse, adPos: number, idx: number) {
        this.data = data;
        this.podIdx = idx;
        this.pos = adPos;
    }

    getAdPosition(): number {
        return this.pos;
    }

    setAdPosition(n: number): void {
        this.pos = n;
    }

    getMaxDuration(): number {
        return this.data.duration;
    }

    getPodIndex(): number { // 0 [1, 2,..n] -1
        return this.podIdx;
    } 

    setPodIndex(n: number): void {
        this.podIdx = n;
    }

    getTimeOffset(): number {
        return this.data.start;
    }

    getTotalAds(): number {
        return this.data.ads && this.data.ads.length;
    }
}