import { BuildInfoInterface } from '../../iface/BuildInfoInterface';
import { DataConfigInterface } from '../../iface/DataConfigInterface';
import { ModelInterface } from '../../iface/ModelInterface';
import { StrAnyDict } from '../../iface/StrAnyDict';
import { ConfigurablePropertyContainer } from './ConfigurablePropertyContainer';


export class BuildInfo implements BuildInfoInterface, ModelInterface {

    private model: StrAnyDict;

    constructor(config: DataConfigInterface) {
        this.model = new ConfigurablePropertyContainer(config);
    }

    static get modelName(): string { return 'BuildInfo'; }

    get data(): BuildInfoInterface { return <BuildInfoInterface>(this.model.data); }

    reset(): void { this.model.reset(); }


    set buildTime(value: string) { this.model.buildTime = value; }
    get buildTime(): string { return this.model.buildTime; }

    set env(value: string) { this.model.env = value; }
    get env(): string { return this.model.env; }

    set playerName(value: string) { this.model.playerName = value; }
    get playerName(): string { return this.model.playerName; }

    set playerVersion(value: string) { this.model.playerVersion = value; }
    get playerVersion(): string { return this.model.playerVersion; }
}
