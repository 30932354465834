import { Util } from '../../core/Util';
import { AsyncDataRequest } from '../../dataservice/AsyncDataRequest';
import { AsyncDataRequestOptions } from '../../dataservice/AsyncDataRequestOptions';
import { Browser } from '../../enum/Browser';
import { XhrResponseType } from '../../enum/XhrResponseType';
import { SystemServiceInterface } from '../../iface';
import { EventInterface } from '../../iface/EventInterface';
import { StrAnyDict } from '../../iface/StrAnyDict';


export class SmpteToVttCueConverter {

    private url: string;
    private system: SystemServiceInterface;
    private isVTTCueSupported: boolean;

    constructor(url: string, system: SystemServiceInterface) {
        this.url = url;
        this.system = system;
        this.isVTTCueSupported = this.system.global.VTTCue !== undefined;
    }

    convert(): Promise<Array<VTTCue>> {

        return new Promise<Array<VTTCue>>((resolve, reject) => {

            this.loadXml(this.url).then(result => {

                const json = Util.xmlToJson(result),
                    smpteCues: [] = json.tt.body.div.p;

                if (smpteCues && smpteCues.length > 0) {
                    const vttCues = this.convertToVTTCues(smpteCues);
                    return resolve(vttCues);
                }
                return reject(null);

            }).catch((err: any) => {
                //log
                return reject(null);
            })
        });
    }

    private loadXml(url: string): Promise<XMLDocument> {

        return new Promise<XMLDocument>((resolve, reject) => {
            new AsyncDataRequest(AsyncDataRequestOptions.create({
                url: url,
                method: 'GET',
                responseType: XhrResponseType.DOCUMENT,
                onComplete: (e: EventInterface) => {
                    if (e.data.error) {
                        return reject(null);
                    }

                    return resolve(e.data.response);
                },
                errorRecovery: {}
            }));
        });
    }

    //UVPJS Ported code.
    private convertToVTTCues(cues: Array<StrAnyDict>): Array<VTTCue> {

        let list = [];

        for (let i = 0, len = cues.length; i < len; i++) {

            const item = cues[i];

            let text = item.text;
            if (Util.isEmpty(text)) {
                continue;
            }

            //Convert old <span> tags. 
            //OPTIMIZE?
            text = text.replace(/(.*)<span.*tts:fontStyle="italic">(.*)<\/span>(.*)/g, '$1<i>$2</i>$3');
            text = text.replace(/(.*)<span.*tts:fontWeight="bold">(.*)<\/span>(.*)/g, '$1<b>$2</b>$3');
            text = text.replace(/(.*)<span.*tts:textDecoration="under">(.*)<\/span>(.*)/g, '$1<u>$2</u>$3');

            // // Count lines for positioning.
            const lc = Util.getNumLines(text);
            item._lineCountPrev = item._lineCountPrev || 0;
            item._lineCountNext = item._lineCountNext || 0;

            let n = i + 1;
            let nextItem = cues[n];
            // // Determine number of lines for each start time.
            while ((nextItem && nextItem.begin) === item.begin) {
                nextItem._lineCountPrev = lc + item._lineCountNext;
                item._lineCountNext += Util.getNumLines(nextItem.text);
                nextItem = cues[++n];
            }

            const Cue = this.isVTTCueSupported ? this.system.global.VTTCue : this.system.global.TextTrackCue;
            let newCue = new Cue(Util.hmsToSec(item.begin), Util.hmsToSec(item.end), text);

            //Can only add position data to VTTCue, Edge only supports TextTrackCue
            if (this.isVTTCueSupported) {
                newCue = this.metadataToPosition(item, newCue);
            }
            list.push(newCue);
        }

        return list;
    }

    private metadataToPosition(item: StrAnyDict, cue: VTTCue): VTTCue {

        const metadata = item.metadata;
        // TODO: This should be normalized with _setPosition?? FROM OLD PLAYER TODO
        if (metadata) {
            cue.snapToLines = true; // Ensures lines don't overlap.

            // NOTE: `cccol` has a max of 35.
            const xpos = Math.round(metadata.cccol / 35 * 100);
            cue.position = xpos;

            //VTG-1399: Safari v12.1 does not accept 'middle' - must be 'center; but previous version need middle.
            try {
                //@ts-ignore - middle is not an AlignSetting, but old safari does not conform with center!! 
                cue.align = xpos < 45 ? 'start' : xpos > 55 ? 'end' : this.getAlignString();
            } catch (e) {
                cue.align = 'center';
            }

            cue.positionAlign = xpos < 45 ? 'line-left' : xpos > 55 ? 'line-right' : 'center';

            // NOTE: `ccrow` has a max of 15. Subtract 2 lines to bring text
            //       above control bar. Negative numbers indicate bottom
            //       up, where -1 is absolute bottom.
            cue.line = metadata.ccrow - 17 + this.getLineOffset();
        }

        return cue;
    }

    private getAlignString(): string {
        return this.system.browser === Browser.SAFARI ? 'middle' : 'center';
    }

    private getLineOffset(): number {
        return this.system.browser === Browser.SAFARI ? -1 : 0;
    }
}
