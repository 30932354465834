import { AppResources } from '../../app/AppResources';
import { Util } from '../../core/Util';
import { ScriptLoader } from '../../dataservice/ScriptLoader';
import { Browser } from '../../enum/Browser';
import { ErrorCode } from '../../enum/ErrorCode';
import { LogLevel } from '../../enum/LogLevel';
import { Os } from '../../enum/Os';
import { PlaybackAdapterInterface, SystemServiceInterface, VideoSurfaceInterface } from '../../iface';
import { ErrorRecoveryInterface } from '../../iface/ErrorRecoveryInterface';
import { EventInterface } from '../../iface/EventInterface';
import { LoggerInterface } from '../../iface/LoggerInterface';
import { OverridesInterface } from '../../iface/OverridesInterface';
import { PlaybackAdapterConfigInterface } from '../../iface/PlaybackAdapterConfigInterface';
import { ScriptLoaderComplete } from '../../iface/ScriptLoaderComplete';
import { MediaCapabilitiesMimeType } from '../../util/enum/MediaCapabilitiesMimeType';
import { ExtLibEndpoint } from '../enum/ExtLibEndpoint';
import { PlaybackAdapterType } from '../enum/PlaybackAdapterType';
import { StreamingLibraryVersion } from '../enum/StreamingLibraryVersion';
import { Html5VideoSurface } from '../surface/Html5VideoSurface';
import { WebMafVideoSurface } from '../surface/WebMafVideoSurface';
import { DashjsAdapter } from './DashjsAdapter';
import { HlsjsAdapter } from './HlsjsAdapter';
import { Html5Adapter } from './Html5Adapter';
import { PlayStationAdapter } from './PlayStationAdapter';
import { ShakaAdapter } from './ShakaAdapter';
import { TwitchLowLatencyAdapter } from './TwitchLowLatencyAdapter';


export class PlaybackAdapterFactory {

    protected constructor() { }

    static getAdapterOverride(config: PlaybackAdapterConfigInterface) {
        return config.resource.overrides?.adapter;
    }

    static getAdapterType(config: PlaybackAdapterConfigInterface): PlaybackAdapterType {

        // Override with valid string from PlaybackAdapterType Enum
        const override: PlaybackAdapterType = this.getAdapterOverride(config);
        if (!Util.isEmpty(override)) {
            if (override == PlaybackAdapterType.SHAKA) {
                if (PlaybackAdapterFactory.isShakaAdapter(config)) {
                    return PlaybackAdapterType.SHAKA;
                }
            }
            else {
                return override;
            }
        }

        if (PlaybackAdapterFactory.isTwitchLowLatencyAdapter(config)) {
            return PlaybackAdapterType.TWITCH_LOW_LATENCY;
        }

        if (PlaybackAdapterFactory.isPlayStationAdapter(config)) {
            return PlaybackAdapterType.PLAY_STATION;
        }

        if (PlaybackAdapterFactory.isHTML5Adapter(config)) {
            return PlaybackAdapterType.HTML5;
        }

        if (PlaybackAdapterFactory.isDashAdapter(config)) {
            return PlaybackAdapterType.SHAKA;
        }

        if (PlaybackAdapterFactory.isHlsAdapter(config)) {
            return PlaybackAdapterType.HLSJS;
        }

        return PlaybackAdapterType.UNKNOWN;
    }

    static getUrl(type: PlaybackAdapterType, config: PlaybackAdapterConfigInterface): string {
        const overrides: OverridesInterface = config.playerOptions.overrides || {};
        const resolveVersionTemplate = (url: string, version: string, context: any = {}): string => {
            return Util.template(url, { VERSION: version, ...context });
        };

        if (overrides.dependencies?.[type]) {
            return overrides.dependencies[type];
        }

        switch (type) {
            case PlaybackAdapterType.HLSJS:
                return resolveVersionTemplate(ExtLibEndpoint.HLSJS_GZIP_CDN, StreamingLibraryVersion.HLSJS);

            case PlaybackAdapterType.DASHJS:
                return resolveVersionTemplate(ExtLibEndpoint.DASHJS_GZIP_CDN, StreamingLibraryVersion.DASHJS);

            case PlaybackAdapterType.SHAKA:
                const DEBUG = (overrides.enableLowLevelStreamingLogs === true) ? '.debug' : '';
                return resolveVersionTemplate(ExtLibEndpoint.SHAKA_GZIP_CDN, StreamingLibraryVersion.SHAKA, { DEBUG });

            case PlaybackAdapterType.TWITCH_LOW_LATENCY:
                return resolveVersionTemplate(ExtLibEndpoint.TWITCH_GZIP_CDN, StreamingLibraryVersion.TWITCH);

            default:
                return null;
        }
    }

    static loadLib(url: string, config: PlaybackAdapterConfigInterface, lib: () => any): Promise<any> {
        return new Promise((resolve, reject) => {
            try {
                if (lib() != null) {
                    return resolve();
                }
            }
            catch (error) { /* */ }

            new ScriptLoader({
                url: this.checkSslForUrl(config.system, url),
                urls: [],
                timeout: PlaybackAdapterFactory.getScriptLoaderTimeout(config.playerOptions.overrides),
                onComplete: (e: EventInterface) => {
                    const d = <ScriptLoaderComplete>e.data;
                    if (d.error || d.timedOut) {
                        reject(d);
                    }
                    else {
                        resolve();
                    }
                },
                errorRecovery: PlaybackAdapterFactory.getErrorRecoveryFromConfig(config)
            });
        });
    }

    static getAdapter(video: HTMLVideoElement, config: PlaybackAdapterConfigInterface, logger: LoggerInterface): Promise<PlaybackAdapterInterface> {

        const type = PlaybackAdapterFactory.getAdapterType(config);
        const videoSurface = PlaybackAdapterFactory.getVideoSurface(type, video, config, logger); //TEST if marked non static no access if no instace right??
        const loadError = (code: string): any => ({ target: type, code, message: AppResources.messages.ADAPTER_LIB_UNAVAILABLE });
        const url = this.getUrl(type, config);

        switch (type) {
            case PlaybackAdapterType.HLSJS:
                return this.loadLib(url, config, () => (<any>window).Hls)
                    .then(() => {
                        logger.log(LogLevel.INFO, `Loading ${PlaybackAdapterType.HLSJS} external library version: ${StreamingLibraryVersion.HLSJS}`);
                        return new HlsjsAdapter(videoSurface, config, logger);
                    })
                    .catch((error) => {
                        throw loadError(ErrorCode.HLS_SDK_MISSING);
                    });

            case PlaybackAdapterType.DASHJS:
                return this.loadLib(url, config, () => (<any>window).dashjs.MediaPlayer)
                    .then(() => {
                        logger.log(LogLevel.INFO, `Loading ${PlaybackAdapterType.DASHJS} external library version: ${StreamingLibraryVersion.DASHJS}`);
                        return new DashjsAdapter(videoSurface, config, logger);
                    })
                    .catch((error) => {
                        throw loadError(ErrorCode.DASH_SDK_MISSING);
                    });

            case PlaybackAdapterType.SHAKA:
                return this.loadLib(url, config, () => (<any>window).shaka.Player)
                    .then(() => {
                        logger.log(LogLevel.INFO, `Loading ${PlaybackAdapterType.SHAKA} external library version: ${StreamingLibraryVersion.SHAKA}`);
                        return new ShakaAdapter(videoSurface, config, logger);
                    })
                    .catch((error) => {
                        throw loadError(ErrorCode.SHAKA_SDK_MISSING);
                    });

            case PlaybackAdapterType.TWITCH_LOW_LATENCY:
                return this.loadLib(url, config, () => (<any>window).MediaPlayer)
                    .then(() => {
                        logger.log(LogLevel.INFO, `Loading ${PlaybackAdapterType.TWITCH_LOW_LATENCY} external library version: ${StreamingLibraryVersion.TWITCH}`);
                        return new TwitchLowLatencyAdapter(videoSurface, config, logger);
                    })
                    .catch((error) => {
                        throw loadError(ErrorCode.TWITCH_SDK_MISSING);
                    });

            case PlaybackAdapterType.HTML5:
                return Promise.resolve(new Html5Adapter(videoSurface, config, logger));

            case PlaybackAdapterType.PLAY_STATION:
                return Promise.resolve(new PlayStationAdapter(videoSurface, config, logger));

            default:
                return Promise.reject(loadError(ErrorCode.UNEXPECTED_CONDITION));
        }
    }

    private static isPlayStationAdapter(config: PlaybackAdapterConfigInterface): boolean {
        return config.system.isWebMaf;
    }

    private static isHTML5Adapter(config: PlaybackAdapterConfigInterface): boolean {

        const fairplayDetected = !Util.isEmpty(config.resource.location.drm?.fairplay?.appCertUrl),
            isSafari = config.system.info.browser === Browser.SAFARI,
            isIOS = config.system.os === Os.IOS,
            isAndroid = config.system.os == Os.ANDROID,
            osVersion = config.system.osVersionInfo,
            url = config.resource.location.mediaUrl,
            type = Util.getMimeType(url),
            isM3u8 = type == MediaCapabilitiesMimeType.HLS;

        if (!isM3u8 && type == MediaCapabilitiesMimeType.MP4_VIDEO) {
            return true;
        }

        //HLS with FairPlay on Safari
        if (isM3u8 && fairplayDetected && isSafari) {
            return true;
        }

        //IOS
        if (isM3u8 && isIOS) {
            if (fairplayDetected && isSafari) {
                return true;
            }
            else if (!fairplayDetected) {
                return true;
            }
        }

        //Android < 5
        if (isM3u8 && !fairplayDetected && isAndroid && osVersion.majorVersion < 5) {
            return true;
        }

        return false;
    }

    private static isDashAdapter(config: PlaybackAdapterConfigInterface): boolean {
        const url = config.resource.location.mediaUrl;
        return Util.getMimeType(url) == MediaCapabilitiesMimeType.DASH;
    }

    private static isTwitchLowLatencyAdapter(config: PlaybackAdapterConfigInterface): boolean {
        //TODO Twitch Temp code until we decide what to do
        const type = this.getAdapterOverride(config);
        return type === PlaybackAdapterType.TWITCH_LOW_LATENCY;
    }

    private static isHlsAdapter(config: PlaybackAdapterConfigInterface): boolean {
        const url = config.resource.location.mediaUrl,
            isSafari = config.system.info.browser === Browser.SAFARI,
            isIOS = config.system.os === Os.IOS,
            isM3u8 = Util.getMimeType(url) == MediaCapabilitiesMimeType.HLS;

        // Unsupported playback scenario... 
        if (isM3u8 && isIOS && !isSafari) {
            return false;
        }

        return isM3u8;
    }

    private static isShakaAdapter(config: PlaybackAdapterConfigInterface): boolean {
        const url = config.resource.location.mediaUrl;
        const type = this.getAdapterOverride(config);
        return Util.getMimeType(url) == MediaCapabilitiesMimeType.DASH && type === PlaybackAdapterType.SHAKA;
    }

    private static checkSslForUrl(system: SystemServiceInterface, baseUrl: string): string {
        return system.isWebOs ? Util.makeUrl(true, baseUrl) : baseUrl;
    }

    private static getScriptLoaderTimeout(overrides: OverridesInterface): number {
        let timeout = 5000;

        if (overrides.streamingLibLoaderTimeout > 0) {
            timeout = overrides.streamingLibLoaderTimeout;
        }

        return timeout;
    }

    private static getVideoSurface(type: PlaybackAdapterType, video: HTMLVideoElement, config: PlaybackAdapterConfigInterface, logger: LoggerInterface): VideoSurfaceInterface {
        const useTextTrackSurface = (type != PlaybackAdapterType.SHAKA);
        return type === PlaybackAdapterType.PLAY_STATION ?
            new WebMafVideoSurface(config, logger) : new Html5VideoSurface(video, config, logger, useTextTrackSurface);
    }

    private static getErrorRecoveryFromConfig(config: PlaybackAdapterConfigInterface): ErrorRecoveryInterface {

        let errorRecovery: ErrorRecoveryInterface = null;

        const er = config.playerOptions.networkErrorRecovery
        if (!Util.isEmpty(er)) {
            errorRecovery = {
                retryAttempts: er.retryAttempts,
                retryIntervals: er.retryIntervals
            }
        }

        return errorRecovery;
    }
}
