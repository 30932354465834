import { Emitter } from '../../core/Emitter';
import { LogLevel } from '../../enum/LogLevel';
import { VideoSurfaceInterface } from '../../iface';
import { AudioTrackInterface } from '../../iface/AudioTrackInterface';
import { AudioTracksInterface } from '../../iface/AudioTracksInterface';
import { EventHandler } from '../../iface/EventHandler';
import { EventInterface } from '../../iface/EventInterface';
import { LoggerInterface } from '../../iface/LoggerInterface';
import { PlaybackAdapterConfigInterface } from '../../iface/PlaybackAdapterConfigInterface';
import { StrAnyDict } from '../../iface/StrAnyDict';
import { Timer } from '../../util/Timer';
import { Utils } from '../../util/Utils';
import { PlaybackAdapterEvents } from '../enum/PlaybackAdapterEvents';
import { PlayStation } from '../enum/PlayStation';
import { TextTrackMode } from '../enum/TextTrackMode';
import { TextTrackSurfaceEvents } from '../enum/TextTrackSurfaceEvents';
import { VideoSurfaceEvents } from '../enum/VideoSurfaceEvents';


export class WebMafVideoSurface extends Emitter implements VideoSurfaceInterface {

    private timer: Timer;
    private onTicHandler: EventHandler = (e: EventInterface) => this.onTic(e);

    private pTime: number = 0;
    private pDuration: number = 0;
    private pVideo: any;
    private pMetrics: any;
    private pState: string;
    private pBitrate: number = 0;
    private abrProfileCount: number = 0;
    private pMasterBitrateProfile: StrAnyDict[] = [];
    private logger: LoggerInterface;
    private audioTrackInfo: AudioTracksInterface;
    private audioTrackUpdated: boolean = false;
    private pStartTime = NaN;
    private global: any;
    private config: PlaybackAdapterConfigInterface;


    ///state
    private seeking: boolean = false;

    constructor(config: PlaybackAdapterConfigInterface, logger: LoggerInterface) {
        super();

        this.logger = logger;

        this.timer = new Timer(500);
        this.timer.on(Timer.TIC_EVENT, this.onTicHandler);
        this.config = config;
        this.logger.log(LogLevel.WARN, `${this.config}`); //TODO remove once id3 owner tag filtering is implemented. 
        this.global = config.system.global;
        //WebMaf Bindings
        this.pVideo = this.global.WM_videoPlayer;

        this.global.accessfunction = (json: any) => this.accessfunction(json);

        this.pMetrics = this.global.videometrics;
        this.pMetrics.onBitrateChange = (value: number) => this.onBitrateChange(value);

        this.callExternalWebMafApi(
            '{"command":"setAdaptiveStreamingParameters",' +
            ' "bandwidthHistoryMaxEntries":30, ' +
            ' "bandwidthHistoryMinEntries": 1, ' +
            ' "bandwidthUtilisationPercentage": 80, ' +
            ' "segmentsBetweenSwitchUp": 5}'
        );

        //@ts-ignore
        if (this.logger) {
            this.pVideo.TTY = (tty: any) => {
                this.logger.log(LogLevel.INFO, tty);
            }
            this.pVideo.TTYLevel = 0; //TODO - MAP THIS TO OUR LOG LEVEL? 
        }
    }

    destroy() {
        this.callExternalWebMafApi('{"command":"stop"}');
        this.timer.off(Timer.TIC_EVENT, this.onTicHandler);
        super.destroy();
    }

    play(): void {
        this.callExternalWebMafApi('{"command":"play"}');
    }

    pause(): void {
        this.callExternalWebMafApi('{"command":"pause"}');
    }

    seek(position: number): void {
        if (!this.seeking) {
            this.seeking = true;
            const pos = Math.floor(position); //webmaf will fail to seek with float.
            this.callExternalWebMafApi('{"command":"setPlayTime","playTime":' + pos + '}');
            this.logger.log(LogLevel.INFO, `seeking to ${pos}`);
            this.emit(VideoSurfaceEvents.SEEKING, { position: pos });
        }
    }

    addEvents(): void {
        //TODO fill out with constructor code so we can add events at controlled time.  
    }

    clearCue(): void {
        // noop
    }

    set startTime(value: number) {
        this.logger.log(LogLevel.INFO, `startTime set on webmaf ${value}`);
        this.pStartTime = value;
    }

    set volume(value: number) {//Ensemble does not control volume on webmaf, just lets TV. But you could.  
        this.callExternalWebMafApi('{"command":"setVolume", "volume":' + value + '}');
    }
    get volume(): number {
        return 1;
    }

    set muted(value: boolean) {//Ensemble does not control volume on webmaf, just lets TV. But you could.  
        this.callExternalWebMafApi('{"command": "setVolume", "volume": 0.0}');
    }
    get muted(): boolean {
        return false;
    }

    set src(value: string) {
        this.callExternalWebMafApi(value);
    }

    get video(): any {
        return this.pVideo;
    }

    get framerate(): number {
        return Number.NaN;
    }

    get metrics(): StrAnyDict {
        return this.pMetrics;
    }

    get time(): number {
        return this.pTime;
    }

    get duration(): number {
        return this.pDuration;
    }

    get bufferLength(): number {
        return this.pVideo.bufferedRange;
    }

    get buffering(): boolean {
        return this.pState === PlayStation.BUFFERING;
    }

    get paused(): boolean {
        return this.pState === PlayStation.PAUSED;
    }

    get state(): string {
        return this.pState;
    }

    get masterBitrateProfile(): StrAnyDict[] {
        return this.pMasterBitrateProfile;
    }

    get bitrate(): number {
        return this.pBitrate; //check if remove from tic and just update JIT per metric needed?
    }

    set startingBitrate(value: number) {
        this.logger.log(LogLevel.INFO, `startingBitrate set on webmaf ${value}`);
        this.callExternalWebMafApi('{"command":"setVideoStartingBandwidth","bandwidth":' + value + '}');
    }

    set minBitrate(value: number) {
        this.logger.log(LogLevel.INFO, `minBitrate set on webmaf ${value}`);
        this.callExternalWebMafApi('{"command":"setFixVideoRepresentations", "minBitrate":' + value + '}');
    }

    set maxBitrate(value: number) {
        this.logger.log(LogLevel.INFO, `maxBitrate set on webmaf ${value}`);
        this.callExternalWebMafApi('{"command":"setFixVideoRepresentations", "maxBitrate":' + value + '}');
    }

    set audioTrack(track: AudioTrackInterface) {
        const id = this.audioTrackInfo.tracks[track.index].id;
        this.audioTrackUpdated = true;
        this.callExternalWebMafApi('{"command":"setAudioTrack", "audioTrack":' + JSON.stringify(id) + '}');
    }

    set textTrackMode(mode: TextTrackMode) {
        const enable = mode !== TextTrackMode.DISABLED;
        this.callExternalWebMafApi('{"command":"setClosedCaptions","enable": ' + enable + '}');
    }

    set textTrack(track: TextTrack) {
        this.callExternalWebMafApi('{"command":"setSubtitleTrack","subtitleTrack": ' + JSON.stringify(track.language) + '}');
        this.emit(TextTrackSurfaceEvents.TEXT_TRACK_CHANGE, track);
    }

    set textTrackSrc(url: string) {
        this.logger.log(LogLevel.INFO, `Sidecar text is not supported on playstation ${url}`);
    }

    get textTracks(): TextTrack[] {
        return [];
    }

    private callExternalWebMafApi(command: string) {
        this.global.external.user(command);
    }

    private onTic(e: EventInterface) {
        this.callExternalWebMafApi('{"command":"getPlaybackTime"}');
        if (this.audioTrackUpdated) { // may be able to call this right after setting but since codec fails will not set. 
            this.audioTrackUpdated = false;
        }
        this.updateMetrics();
    }

    private onBitrateChange(value: number) {
        const videoBitrate = value / 2 //FIX WEBMAF Bug,  they report back exactly 2x the manifest advertised bitrate!!
        const index = Utils.getIndexForBitrate(this.pMasterBitrateProfile, videoBitrate, false);

        this.logger.log(LogLevel.INFO, `bitrate change ${videoBitrate}`);
        this.emit(PlaybackAdapterEvents.ABR_QUALITY_LOADED, { index: index });
    }

    private updateMetrics(): void {
        this.metrics.poll();
        this.pBitrate = this.metrics.currentBitrate / 2; //FIX WEBMAF Bug,  they report back exactly 2x the manifest advertised bitrate!!
    }

    /**
     * Not supported by webmaf for any format besides smooth streaming. 
     */
    private parseAudioInfo(info: any): AudioTracksInterface {

        const rawTracks: [] = info.audioTracks.split(',');
        const channels: [] = info.audioNumChannels.split(',');
        const returnObj: AudioTracksInterface = { track: null, tracks: [] };

        for (let i = 0, len = rawTracks.length; i < len; i++) {
            const track: AudioTrackInterface = {
                index: i,
                id: rawTracks[i],
                type: channels[i],
                lang: rawTracks[i],
                label: rawTracks[i],
                codec: ''
            };

            returnObj.tracks.push(track);

            if (rawTracks[i] === info.currentAudioTrack) {
                returnObj.track = track;
            }
        }
        return returnObj;
    }

    /**
     * WebMaf Access Point.
     */
    private accessfunction(json: any) {

        //Just for dev releases for now.  
        //TODO - MAP THIS TO OUR Low leve logging override
        // if (json.indexOf('getPlaybackTime') === -1 &&
        //     json.indexOf('playerSubtitle') === -1 &&
        //     json.indexOf('playerMessage') === -1) {
        //     //this.logger.log(LogLevel.INFO, 'Access Function CVP:', json);            
        // }

        const data = JSON.parse(json);

        switch (data.command) {
            case PlayStation.GET_AUDIO_TRACKS:
                this.respondToAudioTrackInfo(data);
                break;

            case PlayStation.GET_VIDEO_REP_COUNT:
                this.respondToVideoRepCount(data);
                break;

            case PlayStation.GET_VIDEO_REP_INFO:
                this.respondToVideoRepInfo(data);
                break;

            case PlayStation.GET_SUBTITLE_TRACKS:
                this.respondToGetSubtitleTracks(data);
                break;

            case PlayStation.GET_PLAYBACK_TIME:
                this.respondToPlaybackTime(data);
                break;

            case PlayStation.NETWORK_STATUS_CHANGE:
                break;

            case PlayStation.CONTENT_AVAILABLE:
                this.respondToContentAvailable(data);
                break;

            case PlayStation.PLAYER_SUBTITLE:
                this.respondToPlayerSubtitle(data);
                break;
            case PlayStation.PLAYER_TIMED_EVENT:
                this.respondToTimedMetadataEvent(data);
                break;
            case PlayStation.PLAYER_STREAMING_ERROR:
                //TODO Error handling                     
                break;
            case PlayStation.PLAYER_ERROR:
                //TODO Error handling
                break;
            case PlayStation.PLAYER_STATUS_CHANGE:
                this.respondToPlayerStatusChange(data);
                break;
        }
    }

    private respondToTimedMetadataEvent(data: any) {
        // this.logger.log(LogLevel.INFO, PlayStation.PLAYER_TIMED_EVENT, data);
        /**
         * TODO Waiting for PC to Implement OwnerID for PS4 need to eval data and seee how to fit into MetadataCuepoint 
         * - refactor to share if possible code in processId3 of TextTrackSurface.ts
         */
        this.emit(TextTrackSurfaceEvents.METADATA_CUEPOINT, { timestamp: data.timestamp, msg: data.data });
    }


    private respondToGetSubtitleTracks(data: any) {
        const arr = data.subtitleTracks.split(',');
        arr.forEach((item: any) => {
            if (item === 'und') {
                this.logger.log(LogLevel.INFO, "timed metadata detected.", data);
                this.callExternalWebMafApi('{"command":"setTimedMetadata","enable":true}');
                this.callExternalWebMafApi('{"command":"setSubtitleTrack","subtitleTrack":"und"}');
            }
            else {
                //Temp solution need to look at multi lang tracks for text and audio will need some refactor.
                const track: any = {
                    language: item,
                    kind: 'subtitle',
                    label: item
                }
                this.emit(TextTrackSurfaceEvents.TEXT_TRACK_ADDED, track);
                this.emit(TextTrackSurfaceEvents.TEXT_TRACK_CHANGE, track);//use this to set the track of info otherwise not set.  Discuss?             
            }
        });

        if (arr.length > 0) {
            this.emit(TextTrackSurfaceEvents.TEXT_TRACK_AVAILABLE);
        }
    }

    private respondToPlayerSubtitle(data: any) {
        const p = data.text.split(';'),
            cueList: StrAnyDict = [];

        //CEA Embedded 608    
        if (p.length >= 8) {
            if (p[6] == "action:caption_block") {
                const len = p.length / 8;
                for (let i = 0; i < len; i++) {
                    const index = i * 8;
                    cueList.push({
                        text: decodeURIComponent(escape(atob(p[index + 7]))),
                        x: p[index + 0].split(':')[1],
                        y: p[index + 1].split(':')[1],
                        r: p[index + 2].split(':')[1],
                        g: p[index + 3].split(':')[1],
                        b: p[index + 4].split(':')[1]
                    });
                }
            }
            else {
                this.logger.log(LogLevel.INFO, "no style info cea 608", data);
            }
        }
        else {
            cueList.push({ text: data.text });
        }

        if (cueList.length > 0) {
            this.emit(TextTrackSurfaceEvents.TEXT_CUEPOINT, { activeCues: cueList });
        }
    }

    private respondToContentAvailable(data: any) {
        if (this.pDuration !== data.totalLength) {
            this.pDuration = data.totalLength;
            this.emit(VideoSurfaceEvents.DURATION_CHANGE, { value: this.pDuration });
        }
    }

    private respondToPlaybackTime(data: any) {
        /**
         * webmaf spits out bogus time value when user is
         * seeking quickly and we do not want to update time on that
         * or any value > duration
         */
        if (this.pTime !== data.elapsedTime &&
            data.elapsedTime <= data.totalTime) {

            this.pTime = data.elapsedTime;
            this.emit(VideoSurfaceEvents.TIME_UPDATE, { value: this.pTime });

            if (this.seeking) {
                this.seeking = false;
                this.emit(VideoSurfaceEvents.SEEKED);
            }
        }
    }

    private respondToVideoRepInfo(data: any) {
        this.pMasterBitrateProfile.push(data);
        if (this.pMasterBitrateProfile.length === this.abrProfileCount) {
            this.emit(PlaybackAdapterEvents.MANIFEST_PARSED, this.pMasterBitrateProfile);
        }
    }

    private respondToVideoRepCount(data: any) {
        this.abrProfileCount = data.count;
        for (let i = 0; i < this.abrProfileCount; i++) {
            this.callExternalWebMafApi('{"command":"getRepresentationInfo", "representation":' + i + '}');
        }
    }

    private respondToAudioTrackInfo(data: any) {
        this.audioTrackInfo = this.parseAudioInfo(data);
        this.emit(PlaybackAdapterEvents.AUDIO_TRACK_UPDATED, this.audioTrackInfo);
    }

    /**
     * Player Status
     */
    private respondToPlayerStatusChange(data: any) {
        switch (data.playerState) {
            case PlayStation.BUFFERING:
                break;
            case PlayStation.READY:
                this.respondToPlayerStatusReady(data);
                break;

            case PlayStation.PAUSED:
                this.emit(VideoSurfaceEvents.PAUSE);
                break;

            case PlayStation.PLAYING:
                this.emit(VideoSurfaceEvents.PLAYING);
                break;

            case PlayStation.DISPLAYING_VIDEO:
                !this.timer.started && this.timer.start();
                break;

            case PlayStation.END_OF_STREAM:
                this.respondToPlayerStatusEOS();
                break;
            default:
                break;
        }
        this.pState = data.playerState;
    }

    private respondToPlayerStatusEOS() {
        this.timer.started && this.timer.stop();
        this.emit(VideoSurfaceEvents.ENDED);
    }

    private respondToPlayerStatusReady(data: any) {
        this.emit(VideoSurfaceEvents.CAN_PLAY_THROUGH);
        this.callExternalWebMafApi('{"command":"getRepresentationsCount"}');
        this.callExternalWebMafApi('{"command":"getSubtitleTracks"}');
        //this.callExternalWebMafApi('{"command":"getAudioTracks"}');// disabled for now, not supported for HLS or Dash. 

        //set deferred playback options here.
        !isNaN(this.pStartTime) && this.seek(this.pStartTime);
    }
}
