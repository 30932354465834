import { NotificationName } from '../enum/NotificationName';
import { NotificationType } from '../enum/NotificationType';
import { NotificationInterface } from '../iface';
import { EventInterface } from '../iface/EventInterface';
import { Timer } from '../util/Timer';
import { LogAwareMediator } from './LogAwareMediator';


export class TimerMediator extends LogAwareMediator {

    private timer: Timer;

    constructor(name: string) {
        super(name);
        this.timer = new Timer();
        this.timer.on(Timer.TIC_EVENT, (e: EventInterface) => this.onTic(e));
    }

    killTimer(): void {
        if (this.timer && this.timer.started) {
            this.timer.stop();
        }
    }

    listNotificationInterests(): string[] {
        return [
            NotificationName.RESOURCE_START
        ];
    }

    handleNotification(notification: NotificationInterface) {

        switch (notification.name) {
            case NotificationName.RESOURCE_START:
                if (this.timer && !this.timer.started) {
                    this.timer.start();
                }
                break;
        }
    }

    onRegister(): void {
        super.onRegister();
    }

    onRemove() {
        this.timer.destroy();
        this.timer = null;
        super.onRemove();
    }

    onTic(e: EventInterface) {
        this.facade.sendNotification(NotificationName.TIMER_TIC, { count: e.data.count }, NotificationType.INTERNAL);
    }
}
