export enum ServiceName {
    ImaAd = 'ImaAd',
    DaiAd = "DaiAd",
    System = 'System',
    MediaCapabilities = 'MediaCapabilities',
    Logging = 'Logging',
    Tracking = 'Tracking',
    BuildInfo = 'BuildInfo',
    TrackingService = 'TrackingService',
    AutoplayCapabilities = 'AutoplayCapabilities'
}

