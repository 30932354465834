import { cvui } from '../../cvui';
import { KeyboardScope } from '../../enum/KeyboardScope';
import { LogLevel } from '../../enum/LogLevel';
import { MediatorName } from '../../enum/MediatorName';
import { PlayerDom } from '../../enum/PlayerDom';
import { ProxyName } from '../../enum/ProxyName';
import { ServiceName } from '../../enum/ServiceName';
import { FacadeInterface, NotificationInterface, SystemServiceInterface, UiEnumCollectionInterface } from '../../iface';
import { PlayerOptionsInterface } from '../../iface/PlayerOptionsInterface';
import { StrAnyDict } from '../../iface/StrAnyDict';
import { PlayerDomProxy } from '../../model/PlayerDomProxy';
import { AccessibilityKeyCommandMediator } from '../../view/AccessibilityKeyCommandMediator';
import { FullscreenMediator } from '../../view/FullscreenMediator';
import { UiMediator } from '../../view/UiMediator';
import { UserKeyCommandMediator } from '../../view/UserKeyCommandMediator';
import { LogAwareSimpleCommand } from '../LogAwareSimpleCommand';
import ViewControllerInterface = cvui.ViewControllerInterface;


export class PrepViewCommand extends LogAwareSimpleCommand {

    execute(notification: NotificationInterface) {
        const f: FacadeInterface = this.facade,
            sysSvc = <SystemServiceInterface>f.retrieveService(ServiceName.System),
            playerOpts: PlayerOptionsInterface = notification.body.playerOptions;

        if (sysSvc.global) {
            // ui
            if (!playerOpts.useNativeControls && sysSvc.global.cvui) {
                this.createUi(sysSvc.global.cvui, sysSvc, playerOpts);
            }
            else {
                !playerOpts.useNativeControls && this.log(LogLevel.INFO, 'PrepView: Skipping UI creation.');
            }

            // fullscreen
            if (!sysSvc.isTv) {
                f.registerMediator(new FullscreenMediator(MediatorName.FULLSCREEN));
            }

            const kScope = this.getScopeForKeys(playerOpts.overrides && playerOpts.overrides.keyboardScope);

            if (kScope !== KeyboardScope.NONE) {
                // accessibility key command mediator
                const akm = new AccessibilityKeyCommandMediator(MediatorName.ACC_KEY_CMD_MEDIATOR);
                f.registerMediator(akm);

                // user key commands
                const ukm = new UserKeyCommandMediator(MediatorName.KEY_COMMAND);
                f.registerMediator(ukm);

                // set key handling scope
                const c = this.getPlayerContainer();
                akm.scope = c;
                ukm.scope = c;
            }
        }
        else {
            this.log(LogLevel.INFO, 'System.global is null');
        }

        this.log(LogLevel.DEBUG, 'View prepared');
    }

    getScopeForKeys(scope: string): KeyboardScope {
        switch (scope) {
            case KeyboardScope.PLAYER:
            case KeyboardScope.DOCUMENT:
            case KeyboardScope.NONE:
                return scope;
        }

        return KeyboardScope.PLAYER;
    }

    getPlayerContainer(): HTMLElement {
        const domProxy = <PlayerDomProxy>this.facade.retrieveProxy(ProxyName.PlayerDomProxy);

        return domProxy.getMain();
    }

    createUi(ui: cvui.UiObjectInterface, sysSvc: SystemServiceInterface, playerOpts: PlayerOptionsInterface) {
        const viewController: ViewControllerInterface = this.getViewController(ui, sysSvc, playerOpts),
            enums: UiEnumCollectionInterface = this.getEnums(ui);

        const m = new UiMediator(MediatorName.UI, viewController);

        this.facade.registerMediator(m);
        m.initialize(enums);
    }

    getEnums(ui: cvui.UiObjectInterface): UiEnumCollectionInterface {
        return {
            ControlName: ui.ControlName,
            ControlEvent: ui.ControlEvent,
            PlayState: ui.PlayState,
            DecoratorName: ui.DecoratorName,
            VideoType: ui.VideoType,
        }
    }

    getViewController(ui: cvui.UiObjectInterface, sysSvc: SystemServiceInterface, opts: PlayerOptionsInterface): ViewControllerInterface {
        const domProxy = <PlayerDomProxy>this.facade.retrieveProxy(ProxyName.PlayerDomProxy);
        let uic: StrAnyDict = opts.uiConfig;

        // vtg-1732 - exclude CC
        if (sysSvc.isIos || sysSvc.isAndroid) {
            if (!uic) {
                uic = {};
            }
            if (!uic.exclude || !Array.isArray(uic.exclude)) {
                uic.exclude = [];
            }
            uic.exclude.indexOf(ui.ControlName.CC_TOGGLE) == -1 && uic.exclude.push(ui.ControlName.CC_TOGGLE);
        }

        return ui.createUi({
            controlContainer: domProxy.getMain(),
            panelContainer: domProxy.getElement(PlayerDom.PANEL_CONTAINER),
            zOrigin: domProxy.getSafeZIndex(),
            systemInfo: sysSvc.info,
            configuration: uic || {},
            omitVolume: !sysSvc.isDesktop
        });
    }
}
