import { Logger } from '../core/Logger';
import { Util } from '../core/Util';
import { ServiceName } from '../enum/ServiceName';
import { MediaCapabilitiesServiceInterface, ServiceCollection, SystemServiceInterface } from '../iface';
import { BuildInfoInterface } from '../iface/BuildInfoInterface';
import { EventInterface } from '../iface/EventInterface';
import { PlayerOptionsInterface } from '../iface/PlayerOptionsInterface';
import { ResourceConfigurationInterface } from '../iface/ResourceConfigurationInterface';
import { SystemInfoApiInterface } from '../iface/SystemInfoApiInterface';
import { VideoPlayerInterface } from '../iface/VideoPlayerInterface';
import { VideoPlayerReadyCallback } from '../iface/VideoPlayerReadyCallback';
import { BuildInfoService } from '../service/BuildInfoService';
import { MediaCapabilitiesService } from '../service/MediaCapabilitiesService';
import { SystemService } from '../service/SystemService';
import { MediaCapabilities } from '../util/MediaCapabilities';
import { System } from '../util/System';
import { ResourceConfiguration } from './ResourceConfiguration';
import { VideoPlayer } from './VideoPlayer';


interface PlayerRequestObject {
    options: PlayerOptionsInterface,
    callback: VideoPlayerReadyCallback
}

export class Shell {
    private static serviceCollection: ServiceCollection = null;
    private static playerCollection: Record<string, VideoPlayerInterface> = {};
    private static initialized: boolean = false;
    private static initializing: boolean = null;
    private static pendingPlayerRequests: PlayerRequestObject[] = [];
    private static pendingPlayerDestroys: Record<string, Promise<void>> = {};

    protected constructor() { }

    static createVideoPlayer(options: PlayerOptionsInterface, callback: VideoPlayerReadyCallback, buildInfo: BuildInfoInterface): void {
        if (this.initialized) {
            this.createVideoPlayerApp({ options, callback });
            return;
        }
        else {
            this.pendingPlayerRequests.push({ options, callback });
        }

        if (this.initializing === null) {
            this.initializing = true;
            this.initialize(buildInfo);
        }
    }

    static createVideoPlayerApp(pro: PlayerRequestObject) {
        const c = pro.options.container,
            sys = <SystemServiceInterface>this.serviceCollection[ServiceName.System];

        let ok = false;

        if (sys.isWebMaf) {
            ok = true;
        }
        else if (
            c !== null && c !== undefined &&
            (
                c.constructor === HTMLDivElement ||
                (typeof c == 'object' && typeof c.getBoundingClientRect === 'function') ||
                (typeof c == 'string' && document.querySelector(c) != null)
            )
        ) {
            ok = true;
        }

        if (!ok) {
            const msg = 'Invalid player configuration: Missing presentation container.';
            Logger.error(msg);
            pro.callback(null, { message: msg });
        }

        const vp = new VideoPlayer({
            globalServices: this.serviceCollection,
            playerOptions: pro.options
        });

        this.playerCollection[vp.appId] = vp;

        vp
            .initialize()
            .then(api => pro.callback(api, null))
            .catch(error => pro.callback(null, error));
    }

    static removeVideoPlayer(playerId: string): Promise<void> {
        const p = this.playerCollection[playerId];
        if (!p) {
            return Promise.resolve();
        }

        const pending = this.pendingPlayerDestroys[playerId];
        if (pending) {
            return pending;
        }

        return this.pendingPlayerDestroys[playerId] = p
            .destroy()
            .then(() => {
                delete this.playerCollection[playerId];
                delete this.pendingPlayerDestroys[playerId];
                Logger.log(`[CVP] Player with id ${playerId} removed.`);
            });
    }

    static createResourceConfig(config?: Partial<ResourceConfigurationInterface>): ResourceConfigurationInterface {
        return new ResourceConfiguration(config);
    }

    static getSysInfoForUser(): SystemInfoApiInterface {
        if (this.serviceCollection === null) {
            return null;
        }

        const info = Util.assign({}, System.info),
            mc = (<MediaCapabilitiesServiceInterface>this.serviceCollection[ServiceName.MediaCapabilities]).capabilities;

        info.hasMediaSource = mc.hasMediaSource;
        info.supportsNativeHls = mc.supportsNativeHls;

        return <SystemInfoApiInterface>info;
    }

    private static processPending(): void {
        const n = this.pendingPlayerRequests.length;

        for (let i = 0; i < n; i++) {
            this.createVideoPlayerApp(this.pendingPlayerRequests[i]);
            this.pendingPlayerRequests[i] = null;
        }
        this.pendingPlayerRequests = [];
    }

    private static createServices(mediaCaps: MediaCapabilities, buildInfo: BuildInfoInterface): void {
        const s: ServiceCollection = {};

        s[ServiceName.System] = SystemService.getInstance(ServiceName.System);
        s[ServiceName.MediaCapabilities] = MediaCapabilitiesService.getInstance(ServiceName.MediaCapabilities, mediaCaps);
        s[ServiceName.BuildInfo] = BuildInfoService.getInstance(ServiceName.BuildInfo, buildInfo);

        this.serviceCollection = s;
    }

    private static initialize(buildInfo: BuildInfoInterface) {
        new MediaCapabilities({
            onComplete: (e: EventInterface) => {
                // @ts-ignore
                this.createServices(e.target as MediaCapabilities, buildInfo);
                this.initialized = true;
                this.initializing = false;
                this.processPending();
            }
        });
    }
}
