import { Util } from '../core/Util';
import { Browser } from '../enum/Browser';
import { Device } from '../enum/Device';
import { Os } from '../enum/Os';
import { Platform } from '../enum/Platform';
import { StrAnyDict } from '../iface/StrAnyDict';
import { SystemInfoInterface } from '../iface/SystemInfoApiInterface';


export class System {
    private static sysInfo: SystemInfoInterface = null;
    private static pGlobal: any;

    protected constructor() { }

    static get info(): SystemInfoInterface {
        return this.sysInfo;
    }

    static get global(): any {
        return this.pGlobal;
    }

    static initialize() {
        if (typeof (<any>window) !== "object") { // not a browser
            System.pGlobal = null;
            return;
        }
        System.pGlobal = <any>window;

        let ua = navigator.userAgent,
            platformInfo = this.getPlatform(ua),
            browserInfo = this.getBrowser(ua, platformInfo);

        this.sysInfo = <SystemInfoInterface>(Util.assign({}, browserInfo, platformInfo));

        let osVer = this.sysInfo.osVersion,
            osMajVer = osVer && Util.isString(osVer) ? parseInt(osVer.split('.')[0]) : NaN;

        this.sysInfo.osMajorVersion = osMajVer;
    }

    private static getBrowser(ua: string, platformInfo: StrAnyDict): StrAnyDict {
        /* eslint-disable */
        let re: StrAnyDict = {
            [Browser.CHROME]: /Chrome\/(\S+)/,
            [Browser.CHROME_IOS]: /CriOS\/(\S+)/,
            [Browser.FIREFOX_IOS]: /FxiOS\/(\S+)/,
            [Browser.EDGE]: / Edge\/([^\s]+)/,
            [Browser.FIREFOX]: /Firefox\/(\S+)/,
            [Browser.OPERA]: /Opera|OPR\/(\S+)/,
            [Browser.SAFARI]: /Version\/(\S+).*Safari/,
            [Browser.MSIE]: /MSIE ([^\s]+)[\)\;]\ Windows/,
            [Browser.ANDROID]: /Android/,
            [Browser.SILK]: /Silk\/(\S+)/,
            [Browser.PLAYSTATION]: /Playstation (\S+)/,
            [Browser.UNKNOWN]: null,
        },
            /* eslint-enable */
            isChromeIos = re[Browser.CHROME_IOS].test(ua),
            isChrome = (
                (re[Browser.CHROME].test(ua) || isChromeIos) &&
                !(re[Browser.OPERA].test(ua)) &&
                !(/Edge/.test(ua))
            ),
            assembleInfo = (bName: Browser | string, re: RegExp | null, ua: string): StrAnyDict => {
                let browser: any = re && re.exec(ua),
                    out = {
                        browser: bName,
                        browserVersion: '0',
                        browserMajorVersion: 0
                    };

                if (browser && browser.length && browser.length > 1) {
                    out.browserVersion = browser[1] || '';

                    if (out.browserVersion.indexOf('.')) {
                        let ar = out.browserVersion.split('.');

                        if (Array.isArray(ar) && ar.length) {
                            let mv = parseInt(ar[0]);
                            !isNaN(mv) && (out.browserMajorVersion = mv);
                        }
                    }
                    else {
                        let mv = parseInt(out.browserVersion);
                        !isNaN(mv) && (out.browserMajorVersion = mv);
                    }
                }

                return out;
            };

        // checked for Chrome first above - it has a majority
        if (isChrome) {
            let name = isChromeIos ? Browser.CHROME_IOS : Browser.CHROME;

            return assembleInfo(name, re[name], ua);
        }


        let b = this.getMsBrowser(re[Browser.EDGE], re[Browser.MSIE], ua);
        if (b.browser !== Browser.UNKNOWN) {
            return assembleInfo(b.browser, b.re, ua);
        }

        // non-MS browsers
        for (let q in re) {
            switch (q) {
                case Browser.FIREFOX: // fall thru
                case Browser.FIREFOX_IOS: // fall thru
                case Browser.SILK: // fall thru
                case Browser.ANDROID: // fall thru
                case Browser.PLAYSTATION:
                    if (re[q].test(ua)) {
                        if (q === Browser.SILK) {
                            platformInfo.device = Device.KINDLE;
                        }
                        return assembleInfo(q, re[q], ua);
                    }

                    break;

                case Browser.SAFARI:
                    /*eslint-disable-next-line*/
                    let b = this.getSafariBrowser(re[q], ua, platformInfo);
                    if (b) {
                        return assembleInfo(q, re[q], ua);
                    }
                    break;

                case Browser.OPERA:
                    /*eslint-disable-next-line*/
                    let test = re[q].exec(ua);

                    if (test) {
                        let browser = (
                            test.indexOf('Opera Mini/') >= 0 ||
                            test.indexOf('Opera Mobi') >= 0
                        ) ? Browser.OPERA_MOBILE : Browser.OPERA;

                        return assembleInfo(browser, re[q], ua);
                    }
                    break;

                case Browser.UNKNOWN:
                    break;

            }
        }

        return assembleInfo(Browser.UNKNOWN, re[Browser.UNKNOWN], ua);
    }


    private static getMsBrowser(reEdge: RegExp, reIe: RegExp, ua: string): StrAnyDict {
        // Trident (IE rendering engine) checked last to distinguish IE and Edge
        /* eslint-disable-next-line*/
        let tri = /Trident.[^\s]+;.*rv:([^\s]+)[\)\;]/;

        if (reIe.test(ua)) {
            return { browser: Browser.MSIE, re: reIe };
        }
        else if (reEdge.test(ua)) {
            return { browser: Browser.EDGE, re: reEdge };
        }
        else if (tri.test(ua)) {
            return { browser: Browser.MSIE, re: tri };
        }

        return { browser: Browser.UNKNOWN, re: null };
    }

    private static getSafariBrowser(re: RegExp, ua: string, platformInfo: StrAnyDict): string | null {
        let test = re.test(ua),
            browserName = platformInfo.os === Os.ANDROID ? Browser.ANDROID : Browser.SAFARI;

        if (test) {
            return browserName;
        }

        return null;
    }

    private static getPlatform(userAgent: string): StrAnyDict {
        const ual = userAgent.toLowerCase(),
            out = {
                os: Os.UNKNOWN,
                platform: Platform.UNKNOWN,
                osVersion: '',
                device: Device.UNKNOWN
            };

        // Firefox on iOS
        if (ual.indexOf('fxios') >= 0) {
            out.os = Os.IOS;
            out.platform = Platform.MAC;
            if (ual.indexOf('iphone') >= 0) {
                out.device = Device.IPHONE;
            }
            else { // iPad
                out.device = Device.IPAD;
            }

            return out;
        }

        let re = /\s(\([^)]+\))/,
            test = re.exec(userAgent);

        if (test && test.length && test.length > 1) {
            let pStr = test[1].replace(/_/gi, '.'), // 'platform string'
                tests = [
                    (str: string, obj: StrAnyDict) => this.isLinux(str, obj),
                    (str: string, obj: StrAnyDict) => this.isMac(str, obj),
                    (str: string, obj: StrAnyDict) => this.isWin(str, obj),
                ],
                i = tests.length, ti;

            while (i--) {
                ti = tests[i];
                if (ti(i === 0 ? userAgent : pStr, out)) {
                    break;
                }
            }
        }

        return out;
    }

    private static isLinux(str: string, obj: StrAnyDict): boolean {
        const regex = new RegExp(Platform.LINUX, 'i');
        const isArm = /Linux armv/.test(navigator.platform);

        if (regex.test(str) || str.indexOf(Os.ANDROID) >= 0) {

            obj.platform = Platform.LINUX;
            obj.device = Device.UNKNOWN;

            if (str.indexOf(Os.CHROMIUM) >= 0) {
                /* eslint-disable-next-line*/
                let re = /Chromium\/([\d+\.]*)\s/,
                    test = re.exec(str);

                obj.os = Os.CHROMIUM;
                obj.osVersion = (test && test[1]) || '';
            }
            else if (str.indexOf(Os.ANDROID) >= 0) {
                let re = / Android ([^\s]+);/,
                    test = re.exec(str);

                obj.os = Os.ANDROID;
                obj.osVersion = (test && test[1]) || '';
                obj.device = Device.ANDROID_PHONE;
            }
            else if (str.indexOf(Os.WEBOS) >= 0) {
                obj.os = Os.WEBOS;
                obj.osVersion = 'n/a';
                obj.device = Device.LG_SMART_TV;
            }
            else if (str.indexOf(Os.SMARTCAST) >= 0) {
                obj.os = Os.SMARTCAST;
                obj.osVersion = 'n/a';
                obj.device = Device.VIZIO_SMART_TV;
            }
            else if (str.indexOf(Os.TIZEN) >= 0) {
                let re = /Tizen ([^\s]+)(;|\))/,
                    test = re.exec(str);
                obj.os = Os.TIZEN;
                obj.osVersion = (test && test[1]) || '';
                obj.device = Device.SAMSUNG_SMART_TV;
            }
            //@ts-ignore
            else if (typeof $badger != 'undefined' || (/ WPE$/.test(str) && isArm)) {
                obj.os = Platform.LINUX;
                obj.osVersion = 'n/a';
                obj.device = Device.COMCAST_X1;
            }
            else if (isArm) {
                obj.os = Platform.LINUX;
                obj.osVersion = 'n/a';
                obj.device = Device.EMBEDDED;
            }
            return true;
        }

        return false;
    }

    private static isMac(str: string, obj: StrAnyDict) {

        if (str.indexOf(Platform.MAC) >= 0) { // OSX desktop
            obj.platform = Platform.MAC;
            obj.device = Device.DESKTOP;

            if (str.indexOf(Os.OSX) >= 0) {
                /* eslint-disable-next-line*/
                let re = /Mac OS X ([\d\.]+)/,
                    test = re.exec(str);

                obj.os = Os.OSX;
                obj.osVersion = (test && test[1]) ? test[1] : null;
            }

            return true;

        }
        else if (str.indexOf('like Mac OS X') !== -1) {
            // iOS; 'like Mac...' is used for non-OSX Mac devices
            obj.platform = Platform.MAC;
            obj.os = Os.IOS;
            obj.device = Device.UNKNOWN;

            let devices = [/\(iPhone;/, /\(iPad;/, /\(iPod;/],
                names = [Device.IPHONE, Device.IPAD, Device.IPOD],
                i = devices.length,
                re = /\sOS\s([^\s]*)\s/,
                version = re.exec(str);

            if (version && version[1]) {
                obj.osVersion = version[1];
            }

            while (i--) {
                if (devices[i].test(str)) {
                    obj.device = names[i];

                    return true;
                }
            }

            return true;
        }

        return false;
    }

    private static isWin(ua: string, obj: StrAnyDict) {
        /* eslint-disable*/
        let re = /(Windows Phone |Windows Phone OS )([^\s]+);/,
            reNt = /Windows NT ([^\s]+)[;\)]/,
            win = re.exec(ua);
        /* eslint-enable*/
        if (win && win[2]) {
            obj.platform = Platform.WINDOWS;
            obj.os = Os.WINDOWS_PHONE;
            obj.osVersion = win[2];
            obj.device = Device.WINDOWS_PHONE;

            return true;
        }
        else {
            let test = reNt.exec(ua);

            obj.device = Device.DESKTOP;

            if (test && test[1]) {
                obj.platform = Platform.WINDOWS;
                obj.os = Os.WINDOWS;

                let osVersionNbr = test[1];

                if (/Xbox; Xbox One/.test(ua)) {
                    obj.device = Device.XBOX_ONE;
                }

                if (parseInt(osVersionNbr) == 10) {
                    obj.os = Os.WINDOWS10;
                    obj.osVersion = osVersionNbr;
                }
                else {
                    switch (osVersionNbr) {
                        // MS version number/os designations
                        case '6.0':
                            obj.os = Os.WINDOWS_VISTA;
                            break;
                        case '6.1':
                            obj.os = Os.WINDOWS7;
                            obj.osVersion = 7;
                            break;
                        case '6.2':
                            obj.os = Os.WINDOWS8;
                            obj.osVersion = 8;
                            break;
                    }
                }

                return true;
            }
        }

        return false;
    }
}

System.initialize();
