import { QueryString } from '../core/QueryString';
import { cvui } from '../cvui';
import { AsyncDataRequest } from '../dataservice/AsyncDataRequest';
import { AsyncDataRequestOptions } from '../dataservice/AsyncDataRequestOptions';
import { BuildInfoServiceInterface, PlayerDomProxyInterface, PluginServicesOptions, SystemServiceInterface } from '../iface';
import { AsyncDataRequestOptionsInterface } from '../iface/AsyncDataRequestOptionsInterface';
import { LocalizationInterface } from '../iface/LocalizationInterface';
import { PlayerOptionsInterface } from '../iface/PlayerOptionsInterface';
import { StrAnyDict } from '../iface/StrAnyDict';
import { BuildInfoInterface } from '../iface/BuildInfoInterface';

export class PluginServices {
    
    xhr = {
        performXhr: (options: AsyncDataRequestOptionsInterface) => {
            const temp = options.onComplete;
            options.onComplete = (e) => {
                temp(e);
                e.target.destroy();
            }
            new AsyncDataRequest(AsyncDataRequestOptions.create(options));
        }
    };

    event = {
        dispatchPluginEvent: (data: StrAnyDict) => {
            this.dispatch(data);
        }
    };

    util = {
        queryStrFromObj(obj: StrAnyDict, encode?: string[]): string {
            return QueryString.encode(obj);
        }
    }

    private vc: cvui.ViewControllerInterface = null;
    private sys: SystemServiceInterface = null;
    private bi: BuildInfoServiceInterface = null;
    private domProxy: PlayerDomProxyInterface = null;
    private playerOpts: PlayerOptionsInterface = null;
    private loc: LocalizationInterface = null;
    private dispatch: (data: StrAnyDict) => void;

    constructor(options: PluginServicesOptions) {
        this.vc = options.viewController;
        this.sys = options.system;
        this.bi = options.buildInfo;
        this.domProxy = options.domProxy;
        this.playerOpts = options.playerOptions;
        this.loc = options.localization;
        this.dispatch = options.dispatch;
    }

    get viewController(): cvui.ViewControllerInterface {
        return this.vc;
    }

    get system(): SystemServiceInterface {
        return this.sys;
    }

    get playerOptions() {
        return this.playerOpts;
    }

    get buildInfo(): BuildInfoInterface {
        return this.bi;
    }

    get playerDomProxy(): PlayerDomProxyInterface {
        return this.domProxy;
    }

    get localization(): LocalizationInterface {
        return this.loc;
    }
}
