import { Browser } from "../enum/Browser";
import { Device } from "../enum/Device";
import { Os } from "../enum/Os";
import { Platform } from "../enum/Platform";
import { SimpleVideoInterface, SystemServiceInterface, VersionInfoInterface } from "../iface";
import { StrAnyDict } from '../iface/StrAnyDict';
import { SystemInfoInterface } from "../iface/SystemInfoApiInterface";
import { System } from "../util/System";
import { VirtualVideoElement } from '../util/VirtualVideoElement';
import { ServiceBase } from "./ServiceBase";

export class SystemService extends ServiceBase implements SystemServiceInterface {

    private infoCache: StrAnyDict = {};
    private static instance: SystemService;

    protected constructor(name: string) {
        super(name);
    }

    static getInstance(name: string): SystemService {
        if (!this.instance) {
            this.instance = new SystemService(name);
        }

        return this.instance;
    }

    get info(): SystemInfoInterface {
        return System.info;
    }

    get webMafPlayer(): any {
        return this.isWebMaf ? this.global['WM_videoPlayer'] : null;
    }

    get isWebMaf(): boolean {
        return !!(this.global && this.global['WM_videoPlayer']);
    }

    get isWebOs(): boolean {
        return <Os>this.info.os === Os.WEBOS;
    }

    /**
     * Returns the global object, if it exists (currently, 'window' is the only applicable global)
     */
    get global(): any {
        return System.global;
    }

    get document(): HTMLDocument {
        return this.global && (this.global.document || null);
    }

    get isDesktop(): boolean {
        const k = 'isDesktop', c = this.checkCache(k);

        return c !== undefined ? c : this.cache(k, () => {
            return this.info.device === Device.DESKTOP
        });
    }

    get isMobile(): boolean {
        return !this.isDesktop;
    }

    get isTv(): boolean {
        switch (this.device) {
            case Device.LG_SMART_TV:
            case Device.VIZIO_SMART_TV:
            case Device.SAMSUNG_SMART_TV:
            case Device.COMCAST_X1:
                return true;

            default:
                return false;
        }
    }

    get isTizenSmartTv(): boolean {
        return this.device === Device.SAMSUNG_SMART_TV;
    }

    get isXboxOne(): boolean {
        return this.device === Device.XBOX_ONE;
    }

    get isEmbedded(): boolean {
        return this.device == Device.EMBEDDED;
    }

    get browser(): Browser {
        return <Browser>this.info.browser;
    }

    get os(): Os {
        return <Os>this.info.os;
    }

    get device(): Device {
        return <Device>this.info.device;
    }

    get platform(): Platform {
        return <Platform>this.info.platform;
    }

    get isIos(): boolean {
        return this.info.os === Os.IOS;
    }

    get isAndroid(): boolean {
        const k = 'isAndroid', c = this.checkCache(k);

        return c !== undefined ? c : this.cache(k, () => {
            const i = this.info;
            return i.device === Device.ANDROID_PHONE || i.device === Os.ANDROID;
        });
    }

    get browserVersionInfo(): VersionInfoInterface {
        const k = 'browserVersionInfo', c = this.checkCache(k);

        return c !== undefined ? c : this.cache(k, () => {
            return this.parseVerStr(this.info.browserVersion);
        });
    }

    get osVersionInfo(): VersionInfoInterface {
        const k = 'osVersionInfo', c = this.checkCache(k);

        return c !== undefined ? c : this.cache(k, () => {
            return this.parseVerStr(this.info.osVersion)
        });
    }

    createSimpleVideoInterface(): SimpleVideoInterface | null {
        return new VirtualVideoElement();
    }

    private cache(key: string, fn: () => any): any {
        this.infoCache[key] = fn();

        return this.infoCache[key];
    }

    private checkCache(key: string): any {
        return key in this.infoCache ? this.infoCache[key] : undefined;
    }

    private parseVerStr(str: string): VersionInfoInterface {
        const out: VersionInfoInterface = {
            majorVersion: null,
            minorVersion: null,
            versionString: str || null,
        };

        if (str) {
            const spl = str.split('.'),
                mj = parseInt(spl[0], 10),
                mn = parseInt(spl[1], 10);

            !isNaN(mj) && (out.majorVersion = mj);
            !isNaN(mn) && (out.minorVersion = mn);
        }

        return out;
    }
}


