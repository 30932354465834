import { AppResources } from '../app/AppResources';
import { Logger } from '../core/Logger';
import { ErrorCode } from '../enum/ErrorCode';
import { LogLevel } from '../enum/LogLevel';
import { MediatorName } from '../enum/MediatorName';
import { ModelName } from '../enum/ModelName';
import { NotificationName } from '../enum/NotificationName';
import { PlaybackState } from '../enum/PlaybackState';
import { ProxyName } from '../enum/ProxyName';
import { ServiceName } from '../enum/ServiceName';
import { StreamType } from '../enum/StreamType';
import { MediatorInterface, NotificationInterface, PlayerDomProxyInterface, SystemServiceInterface, UiMediatorInterface } from '../iface';
import { AdCuePointInterface } from '../iface/AdCuePointInterface';
import { ContentPlaybackStateInterface } from '../iface/ContentPlaybackStateInterface';
import { ErrorInfoInterface } from '../iface/ErrorInfoInterface';
import { OverridesInterface } from '../iface/OverridesInterface';
import { PlayerOptionsInterface } from '../iface/PlayerOptionsInterface';
import { QualityInterface } from '../iface/QualityInterface';
import { AbstractPresentationMediator } from './AbstractPresentationMediator';


// provides base impl for abstract methods of AbstractPresentationMediator
export class CommonPresentationMediator extends AbstractPresentationMediator {

    private uim: UiMediatorInterface = null;
    private liveTZero: number = null;
    private currentPresentationWidth: number = null;
    protected contentDurationReleased: boolean = false;
    protected resumeTimeMaxProximityToAdBreak: number = 5.0;
    protected isClickToPlay: boolean = false;
    protected fullscreenRestrictedDuringAdPlay: boolean = false;
    protected hasContent: boolean = true;
    protected contentComplete: boolean = false;

    onRemove() {
        this.uim = null;
        super.onRemove();
    }

    close(): Promise<void> {
        this.pause();
        this.log(LogLevel.INFO, 'Closing Presentation');

        this.closeAds();

        return Promise.resolve()
            .then(() => (this.adapter) ? this.adapter.destroy() : null)
            .then(() => {
                const isAdPlaying = this.isAdPlaying();
                if (this.hasContent && (!this.isContentComplete() || isAdPlaying)) {
                    const cps: ContentPlaybackStateInterface = this.contentPlaybackStateProxy.model;
                    this.sendNotification(NotificationName.RESOURCE_INTERRUPTED, {
                        contentTime: cps.time,
                        contentDuration: cps.duration,
                        adInterrupted: isAdPlaying
                    });
                }
                else {
                    this.notify(NotificationName.RESOURCE_END);
                }
                /////////////////////////////////////////////////////////////////////
                // Note - No code may come after the INTERRUPTED or END notification
                /////////////////////////////////////////////////////////////////////
            })
            .catch((e) => {
                Logger.error(e);
                throw e;
            });
    }

    // TODO: Move this to an AdPresentationMediator base class
    closeAds(): void {
        // no-op
    }

    playOnUserGesture(): void {
        this.uiMediator && this.uiMediator.displayPoster(false);
        this.notify(NotificationName.VIDEO_LOAD_START);

        this.prepareForPlayback(true);
    }

    mute(flag: boolean): void {
        this.muteVideo(flag);
        this.uiMediator && this.uiMediator.setMuteState(flag);
    }

    seek(position: number): void {
        this.seekVideo(position);
    }

    setVolume(value: number): void {
        this.domProxy && this.domProxy.setVideoVolume(value);
        super.setVolume(value);
    }

    getAdBreakTimes(): AdCuePointInterface[] {
        return [];
    }

    protected releaseContentDuration(duration: number) {
        this.contentDurationReleased = true;
        this.notify(NotificationName.CONTENT_DURATION_AVAILABLE, {
            contentDuration: duration
        });
    }

    protected getLiveProgress(): number {
        if (this.liveTZero === null) {
            this.liveTZero = Date.now();
        }

        return Date.now() - this.liveTZero;
    }

    protected adjustStartTimeForAdBreakProximity(requestedStartTime: number, breaks: AdCuePointInterface[]): number {
        for (let i = 0, n = breaks.length; i < n; i++) {
            const bs = breaks[i].start,
                diff = bs - requestedStartTime;

            if (diff >= 0 && diff < this.resumeTimeMaxProximityToAdBreak) {
                return requestedStartTime - this.resumeTimeMaxProximityToAdBreak;
            }
        }

        return requestedStartTime;
    }

    protected get uiMediator(): UiMediatorInterface {
        return this.uim;
    }

    protected get domProxy(): PlayerDomProxyInterface {
        return <PlayerDomProxyInterface>this.facade.retrieveProxy(ProxyName.PlayerDomProxy);
    }

    protected muteVideo(flag: boolean) {
        super.muteVideo(flag);
        this.domProxy && this.domProxy.muteVideo(flag);
    }

    protected respondToFullscreenChange(state: boolean) {
        this.checkSize();
        this.notify(state ? NotificationName.FULLSCREEN_ENTER : NotificationName.FULLSCREEN_EXIT);
    }

    protected respondToVideoPaused(): void {
        this.contentPlaybackStateProxy.model.state = PlaybackState.PAUSED;
        this.notify(NotificationName.RESOURCE_PAUSED);
    }

    protected respondToVideoPlaying(): void {
        this.contentPlaybackStateProxy.model.state = PlaybackState.PLAYING;
        this.notify(NotificationName.RESOURCE_PLAYING);
    }

    protected respondToVideoSeeking(): void {
        this.notify(NotificationName.CONTENT_SEEKING);
    }

    protected respondToVideoSeeked(): void {
        this.notify(NotificationName.CONTENT_SEEKED);
    }

    protected respondToVideoProgress(): void {
        this.notify(NotificationName.VIDEO_PROGRESS);
    }

    protected respondToQualityChange(quality: QualityInterface = null): void {
        this.notify(NotificationName.QUALITY_CHANGE, quality);
    }

    protected respondToError(data: ErrorInfoInterface) {
        this.uiMediator && this.uiMediator.displaySpinner(false);

        // TODO: Replace with Short-Circuiting Assignment Operator ||= once upgrade to TS 4.0 is complete
        if (!data.message) {
            data.message = AppResources.messages.UNSPECIFIED_ERROR;
        }

        if (!data.code) {
            data.code = ErrorCode.UNSPECIFIED_VIDEO_PLAYBACK_ERROR;
        }

        this.notify(NotificationName.VIDEO_PLAYBACK_ERROR, data);
    }

    /*
        Resource end only applies when the resource has completed
    */
    protected respondToVideoEnd(): void {
        this.notify(NotificationName.DISABLE_UI);
        this.notify(NotificationName.RESOURCE_COMPLETE);
        ///////////////////////////////////////////////////////////
        // Note - No code may come after the RES_END notification
        ///////////////////////////////////////////////////////////
    }

    protected respondToBufferingStatusCheck(): void {
        const t = this.presoModel.streamTime;
        !isNaN(t) && t > 0 && this.checkVideoBuffering();
    }

    protected respondToId3Data(d: any): void {
        this.notify(NotificationName.METADATA_CUEPOINT, d);
    }

    protected respondToVideoTimeUpdate(streamTime: number): void {
        const cps: ContentPlaybackStateInterface = this.contentPlaybackStateProxy.model;

        let data: Record<string, number> = {
            contentTime: cps.time,
            streamTime: streamTime,
            contentDuration: cps.duration,
            streamDuration: this.presoModel.streamDuration
        };

        const isLive = cps.streamType === StreamType.LIVE;
        if (isLive) {
            data.elapsedTimeMs = this.getLiveProgress();
        }

        this.notify(NotificationName.CONTENT_TIME_UPDATE, data);
    }

    protected respondToDurationChange(dur: number): void {
        // no op
    }

    protected respondToSizeChange(): void {
        if (!this.adapter) {
            return;
        }

        this.adapter.resize();
    }

    protected respondToTextTrackModeChange(enabled: boolean): void {
        if (this.uiMediator) {
            this.uiMediator.setClosedCaptionState(enabled);
        }
    }

    // helpers
    protected checkSize(): void {
        if (!this.domProxy) return;

        const pSz: ClientRect = this.domProxy.getPresentationRect(),
            currWidth = this.currentPresentationWidth;

        this.currentPresentationWidth = pSz.width;

        if ((currWidth && pSz.width !== currWidth)) {
            this.respondToSizeChange();

            this.notify(NotificationName.PRESENTATION_SIZE_CHANGE, {
                width: pSz.width,
                height: pSz.height
            });
        }
    }

    // for now, hard-coded for DVR threshold of 30 minutes (1800 sec)
    protected setTransportType(streamType = this.contentPlaybackStateProxy.model.streamType): void {
        const seekable = streamType != StreamType.LIVE;

        if (this.uiMediator) {
            this.uiMediator.setSeekable(seekable);
            this.uiMediator.setTransportType(streamType);
            this.uiMediator.setIsPlayingLive(this.isPlayingLive);
        }
    }

    protected setForClickToPlay(): void {
        if (this.uiMediator) {
            this.uiMediator.displayPoster(true);
            this.uiMediator.activatePoster();
        }
        else {
            const po = <PlayerOptionsInterface>this.getModel(ModelName.PlayerOptions);
            if (po.useNativeControls) {
                const m = this.domProxy.getMain();
                const lr = () => {
                    m.removeEventListener('click', lr);
                    this.notify(NotificationName.PLAY_ON_USER_GESTURE);
                };
                m.addEventListener('click', lr);
            }
        }
    }

    protected setPausedState() {
        if (this.uiMediator) {
            this.uiMediator.setClickCatcherState(PlaybackState.PAUSED);
            this.uiMediator.hideClickCatcher(false);
        }
    }

    protected setPlayingState() {
        if (this.uiMediator) {
            this.uiMediator.setClickCatcherState(PlaybackState.PLAYING);
            this.uiMediator.hideClickCatcher(false);
        }
    }

    protected isContentComplete(): boolean {
        if (this.contentComplete) {
            return true;
        }
        const cps: ContentPlaybackStateInterface = this.contentPlaybackStateProxy.model;
        return cps.streamType != StreamType.LIVE && cps.streamType != StreamType.DVR && !(isNaN(cps.time) || cps.time < cps.duration)
    }

    // TODO: Move this to an AdPresentationMediator base class
    protected isAdPlaying(): boolean {
        return this.presoModel.isCurrentVideoAd;
    }

    handleNotification(notification: NotificationInterface): void {
        super.handleNotification(notification);
    }

    listNotificationInterests(): string[] {
        return super.listNotificationInterests().concat([]);
    }

    onRegister(): void {
        super.onRegister();

        const o: OverridesInterface = this.playerOptions.overrides;

        if (o.resumeTimeMaxProximityToAdBreak) {
            this.resumeTimeMaxProximityToAdBreak = o.resumeTimeMaxProximityToAdBreak;
        }

        this.isClickToPlay = !this.presoModel.isAutoplay;

        const uim: MediatorInterface = this.facade.retrieveMediator(MediatorName.UI);
        uim && (this.uim = <UiMediatorInterface>uim);

        const sys = <SystemServiceInterface>this.facade.retrieveService(ServiceName.System);

        this.fullscreenRestrictedDuringAdPlay = sys.isIos || sys.isAndroid;
        if (this.uim) {
            this.uim.disableFullscreen();
            this.uim.setFullScreenAccessRestricted(this.fullscreenRestrictedDuringAdPlay);
        }

        this.setTransportType(StreamType.VOD);
    }
}
