
/**
 * For details on using ad settings, see [Ad Play: IMA & DAI](../../guides/ad_play)
 */
export enum AdContext {
    /**
     * Specifies CSAI using IMA
     */
    IMA = 'ima',
    /**
     * Specifies SSAI using DAI SDK
     */
    DAI_SDK = 'dai_sdk',

    /**
     * Specifies SSAI using custom SDK (typically for now web)
     */
    DAI_API = 'dai_api',

    /**
     * Specifies SSAI, non-SDK mediated (ad play not tracked client-side)
     */
    DAI_SSB = 'dai_ssb',

    /**
     * Specifies playback without ads
     */
    NONE = ''
}
