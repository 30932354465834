import { StreamType } from '../../enum/StreamType';
import { StrAnyDict } from '../../iface/StrAnyDict';
import { Validator } from '../vo/Validator';


export const content_playback_state_config: StrAnyDict = {

    manifestQualities: {
        type: 'object',
        defaultValue: null,
    },

    qualityInfo: {
        type: 'object',
        defaultValue: null,
    },

    audioTracks: {
        type: 'object',
        defaultValue: null,
    },

    textTracks: {
        type: 'object',
        defaultValue: null,
    },

    streamType: {
        type: 'string',
        defaultValue: StreamType.VOD,
    },

    liveStreamInfo: {
        type: 'object',
        defaultValue: null
    },

    bufferLength: {
        type: 'number',
        defaultValue: 0,
    },

    cdn: {
        type: 'string',
        defaultValue: null,
        validator: Validator.notEmpty
    },

    drmType: {
        type: 'string',
        defaultValue: null,
        validator: Validator.notEmpty
    },

    contentSegment: {
        type: 'number',
        defaultValue: NaN,
    },

    maxBandwidth: {
        type: 'number',
        defaultValue: NaN,
    },

    time: {
        type: 'number',
        defaultValue: NaN,
    },

    state: {
        type: 'number',
        defaultValue: NaN,
    },

    duration: {
        type: 'number',
        defaultValue: NaN,
    },

    bitrate: {
        type: 'number',
        defaultValue: NaN,
    },

    started: {
        type: 'boolean',
        defaultValue: false,
    },

    averageDroppedFps: {
        type: 'number',
        defaultValue: NaN,
    },

    framerate: {
        type: 'number',
        defaultValue: NaN,
    }
};
