import { Util } from '../core/Util';
import { StrAnyDict } from '../iface/StrAnyDict';
// API decorator factories

export function apiMethod() {
    return function (target: any, propertyKey: string, descriptor: PropertyDescriptor) {
        if (!Util.isObject(target.apiMethods)) {
            target.apiMethods = {};
        }

        target.apiMethods[propertyKey] = descriptor.value;
    }
}

export function apiAccessor(readonly: boolean = false) {
    return function (target: any, propertyKey: string, descriptor: PropertyDescriptor) {
        if (!Util.isObject(target.apiAccessors)) {
            target.apiAccessors = {};
        }

        let obj: PropertyDescriptor = {
            get: descriptor.get,
            set: readonly ? undefined : descriptor.set || undefined
        };

        target.apiAccessors[propertyKey] = obj;
    }
}

export function apiCollection(obj: StrAnyDict, target: any): StrAnyDict {
    for (let i in target.apiMethods) {
        obj[i] = function () {
            const args = Array.prototype.slice.call(arguments);

            return (
                !!target &&
                !!target.apiMethods &&
                !!target.apiMethods[i] &&
                target.apiMethods[i].apply(target, args)
            ) || null;
        }
    }

    for (let p in target.apiAccessors) {
        let desc = target.apiAccessors[p];

        let pd: PropertyDescriptor = {};

        if (desc.get) {
            pd.get = () => {
                return target[p];
            }
        }
        if (desc.set) {
            pd.set = (val: any) => {
                target[p] = val;
            }
        }

        Object.defineProperty(obj, p, pd);
    }

    return obj;
}
