import { event_map } from '../app/event_map';
import { Logger } from '../core/Logger';
import { Util } from '../core/Util';
import { AsyncDataRequest } from '../dataservice/AsyncDataRequest';
import { AsyncDataRequestOptions } from '../dataservice/AsyncDataRequestOptions';
import { LogLevel } from '../enum/LogLevel';
import { MediatorName } from '../enum/MediatorName';
import { ModelName } from '../enum/ModelName';
import { NotificationName } from '../enum/NotificationName';
import { PlayerEvent } from '../enum/PlayerEvent';
import { ProxyName } from '../enum/ProxyName';
import { ServiceName } from '../enum/ServiceName';
import { StreamType } from '../enum/StreamType';
import {
    ApplicationInterface,
    BuildInfoServiceInterface,
    MediatorInterface, NotificationInterface,
    PlayerDomProxyInterface,
    PresentationMediatorInterface,

    SystemServiceInterface, UiMediatorInterface
} from '../iface';
import { AdCuePointInterface } from '../iface/AdCuePointInterface';
import { AsyncDataRequestOptionsInterface } from '../iface/AsyncDataRequestOptionsInterface';
import { AutoplayInfoInterface } from '../iface/AutoplayInfoInterface';
import { DimensionsInterface } from '../iface/DimensionsInterface';
import { EventInterface } from '../iface/EventInterface';
import { ModelSnapshotInterface } from '../iface/ModelSnapshotInterface';
import { PlayerOptionsInterface } from '../iface/PlayerOptionsInterface';
import { PlaylistInterface } from '../iface/PlaylistInterface';
import { PresentationStateInterface } from '../iface/PresentationStateInterface';
import { ResourceConfigurationInterface } from '../iface/ResourceConfigurationInterface';
import { StrAnyDict } from '../iface/StrAnyDict';
import { VideoPlayerInterface } from '../iface/VideoPlayerInterface';
import { ContentPlaybackStateProxy } from '../model/ContentPlaybackStateProxy';
import { ModelCollectionProxy } from '../model/ModelCollectionProxy';
import { PlaylistProxy } from '../model/PlaylistProxy';
import { AutoplayCapabilitiesService } from '../service/AutoplayCapabilitiesService';
import { Utils } from '../util/Utils';
import { FullscreenMediator } from './FullscreenMediator';
import { LogAwareMediator } from "./LogAwareMediator";
import { PluginMediator } from './PluginMediator';


export class AppMediator extends LogAwareMediator implements MediatorInterface {

    private app: ApplicationInterface;

    constructor(name: string, app: ApplicationInterface) {
        super(name);

        this.app = app;
    }

    setFullscreenElement(el: HTMLElement): void {
        const fsm = <FullscreenMediator>this.facade.retrieveMediator(MediatorName.FULLSCREEN);
        fsm.fullscreenElement = el;
    }

    getFullscreenElement(): HTMLElement {
        const fsm = <FullscreenMediator>this.facade.retrieveMediator(MediatorName.FULLSCREEN);

        return fsm.fullscreenElement;
    }

    onRemove() {
        this.app = null;
        super.onRemove();
    }

    getAppApi(): VideoPlayerInterface {
        return this.app.getApi();
    }

    getSnapshot(): ModelSnapshotInterface {
        return (<ModelCollectionProxy>this.getProxy(ProxyName.ModelCollectionProxy)).collection.getSnapshot();
    }

    getConfigAsJson(spacing?: number): string {
        const m = <ModelCollectionProxy>this.getProxy(ProxyName.ModelCollectionProxy);
        const o = <PlayerOptionsInterface>m.getModel(ModelName.PlayerOptions);
        const p = <PlaylistProxy>this.getProxy(ProxyName.Playlist);
        const b = <BuildInfoServiceInterface>this.facade.retrieveService(ServiceName.BuildInfo);
        const s = <SystemServiceInterface>this.facade.retrieveService(ServiceName.System);

        return Utils.serializeConfigSnapshot(o, p, b.toObject(), s, spacing);
    }

    validateSeek(position: number, duration: number): number | null {
        if (isNaN(position)) {
            this.log(LogLevel.WARN, `Invalid seek() time [${position}] supplied`);

            return null;
        }

        const pm = <PresentationStateInterface>this.getModel(ModelName.PresentationState);
        const pbp = <ContentPlaybackStateProxy>this.getProxy(ProxyName.ContentPlaybackStateProxy);
        const linear = pbp && pbp.model.streamType === StreamType.LIVE;

        if (!pm || !pbp || pm.isCurrentVideoAd || linear) {
            this.log(LogLevel.WARN, 'seek() may not be called in the current context');

            return null;
        }

        return Util.clampValue(position, 0, duration);
    }

    isPlaybackSuspended(): boolean {
        const pm = <PresentationStateInterface>this.getModel(ModelName.PresentationState);
        return pm.suspended;
    }

    getAutoplayCapabilities(): Promise<AutoplayInfoInterface> {
        const apc = <AutoplayCapabilitiesService>this.getService(ServiceName.AutoplayCapabilities);

        return apc.detectCapabilities();
    }

    getContainerRect(): ClientRect | null {
        const domProxy = <PlayerDomProxyInterface>this.getProxy(ProxyName.PlayerDomProxy);

        return domProxy ? domProxy.getPresentationRect() : null;
    }

    getCurrentResource(): ResourceConfigurationInterface | null {
        const plist = <PlaylistInterface>this.getProxy(ProxyName.Playlist);

        return plist ? plist.currentResource : null;
    }

    killCurrentResource(): Promise<void> {
        const pm = <PresentationMediatorInterface>this.facade.retrieveMediator(MediatorName.PRESENTATION_MEDIATOR);
        this.log(LogLevel.INFO, 'Killing current resource');

        return Promise.resolve()
            .then(() => pm && pm.close())
            .then(() => {
                const plist = <PlaylistInterface>this.getProxy(ProxyName.Playlist);
                plist && plist.removeResourceAtIndex(plist.currentIndex);
            })
            .catch((e: Error) => {
                Logger.error(e);
            });
    }

    performXhr(options: AsyncDataRequestOptionsInterface): void {
        new AsyncDataRequest(AsyncDataRequestOptions.create(options));
    }

    getPlugin(name: string): any {
        const pim = <PluginMediator>this.facade.retrieveMediator(MediatorName.PLUGIN_MEDIATOR);

        return pim ? pim.getPlugin(name) : null;
    }

    dispatchPluginEvent(data: StrAnyDict) {
        this.app.sendEvent(PlayerEvent.PLUGIN_EVENT, data);
    }

    prepForPlayerRemoval(): void {
        const uim = <UiMediatorInterface>this.facade.retrieveMediator(MediatorName.UI);
        if (!uim) { return; }
        uim.killUi();
        this.log(LogLevel.INFO, 'UI layer destroyed');
    }

    getMuteState(): boolean {
        const presoModel = <PresentationStateInterface>this.getModel(ModelName.PresentationState);
        return presoModel ? presoModel.isMuted : null;
    }

    getVolume(): number {
        const presoModel = <PresentationStateInterface>this.getModel(ModelName.PresentationState);
        return presoModel ? presoModel.volume : NaN;
    }

    getDimensions(): DimensionsInterface {
        const domProxy = <PlayerDomProxyInterface>this.getProxy(ProxyName.PlayerDomProxy);
        return domProxy ? domProxy.getDimensions() : null;
    }

    getAdBreakTimes(): AdCuePointInterface[] {
        const pm = <PresentationMediatorInterface>this.facade.retrieveMediator(MediatorName.PRESENTATION_MEDIATOR);
        return pm ? pm.getAdBreakTimes() : null;
    }

    grabFrame(): HTMLImageElement | null {
        const domProxy = <PlayerDomProxyInterface>this.getProxy(ProxyName.PlayerDomProxy),
            vidEl = domProxy && domProxy.getVideo();

        if (!domProxy || !vidEl) { return null; }

        const cvs = document.createElement('canvas');
        let ctx, dUrl, img;

        ctx = cvs.getContext('2d');
        const w = cvs.width = vidEl.videoWidth;
        const h = cvs.height = vidEl.videoHeight;
        ctx.drawImage(<HTMLVideoElement>vidEl, 0, 0, w, h);
        dUrl = cvs.toDataURL();

        img = document.createElement('img');
        img.setAttribute('src', dUrl);

        return img;
    }

    listNotificationInterests(): string[] {
        return [
            NotificationName.APP_EVENT
        ];
    }

    sendErrorEvent(event: EventInterface): void {
        this.app.sendErrorEvent(event);
    }

    handleNotification(notification: NotificationInterface): void {
        const b = notification.body;
        const pe = event_map[b.notificationName];

        switch (notification.name) {
            case NotificationName.APP_EVENT:
                pe && this.app.sendEvent(pe, b.data);
                break;
        }
    }

    onRegister(): void {
        super.onRegister();
    }
}
