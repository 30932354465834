import { PlayerDom } from '../../enum/PlayerDom';
import { PlayerDomInterface } from '../../iface/PlayerDomInterface';
import { StrAnyDict } from '../../iface/StrAnyDict';


export class PlayerDomCreator {

    private document: HTMLDocument;
    private main: HTMLElement;

    constructor(container: HTMLElement, document: HTMLDocument) {
        this.document = document;
        this.main = container;
    }

    destroy(): void {
        this.main = null;
        this.document = null;
    }

    create(skipEls: PlayerDom[]): PlayerDomInterface {
        const coll: StrAnyDict = {
            [PlayerDom.MAIN_CONTAINER]: this.main
        },
            main = this.main,
            s: StrAnyDict = {
                position: 'absolute',
                top: 0,
                left: 0,
                width: '100%',
                height: '100%',
                padding: 0,
                margin: 0,
                zIndex: 0,
                overflow: 'hidden'
            },
            roles: PlayerDom[] = [
                PlayerDom.VIDEO_CONTAINER,
                PlayerDom.CC_CONTAINER,
                PlayerDom.PANEL_CONTAINER,
                PlayerDom.AD_CONTAINER
            ];

        main.style.position = 'relative';
        main.style.padding = '0';
        main.style.width = '100%';
        main.style.height = '100%';
        main.style.background = '#000';
        main.style.overflow = 'hidden';
        main.classList.add('cvp-container');

        let el: HTMLElement;

        // containers
        for (let i = 0, n = roles.length; i < n; i++) {
            if (skipEls && skipEls.indexOf(roles[i]) >= 0) {
                continue;
            }

            s.zIndex += 10;

            el = this.div(roles[i], s);
            coll[roles[i]] = el;

            main.appendChild(el);
        }

        // video element
        const v = document.createElement("video");
        v.style.height = '100%';
        v.style.width = '100%';

        coll[PlayerDom.VIDEO_CONTAINER].appendChild(v);
        coll[PlayerDom.VIDEO_EL] = v;

        // ad click element
        coll[PlayerDom.AD_CLICK_EL] = this.createAdClickEl(++s.zIndex);
        main.appendChild(coll[PlayerDom.AD_CLICK_EL]);

        return <PlayerDomInterface>{
            [PlayerDom.MAIN_CONTAINER]: coll[PlayerDom.MAIN_CONTAINER],
            [PlayerDom.VIDEO_EL]: coll[PlayerDom.VIDEO_EL],
            [PlayerDom.VIDEO_CONTAINER]: coll[PlayerDom.VIDEO_CONTAINER],
            [PlayerDom.CC_CONTAINER]: coll[PlayerDom.CC_CONTAINER],
            [PlayerDom.AD_CONTAINER]: coll[PlayerDom.AD_CONTAINER],
            [PlayerDom.AD_CLICK_EL]: coll[PlayerDom.AD_CLICK_EL],
            [PlayerDom.PANEL_CONTAINER]: coll[PlayerDom.PANEL_CONTAINER]
        }
    }

    private createAdClickEl(z: number): HTMLElement {
        const style = {
            display: 'none',
            padding: '4px 8px',
            fontSize: '12px',
            background: '#dedede',
            lineHeight: '14px',
            color: '#000',
            position: 'absolute',
            zIndex: z,
            right: '2px',
            top: '2px',
            textAlign: 'center'
        },
            el = this.div('adClickEl', style);

        el.innerHTML = 'Learn More';

        return el;
    }

    private div(role: string, style: StrAnyDict): HTMLElement {
        const el = this.document.createElement('div');

        el.setAttribute('data-role', role);

        for (let q in style) {
            (<StrAnyDict>el).style[q] = style[q];
        }

        return el;
    }
}

