import { ResourceConfiguration } from '../../app/ResourceConfiguration';
import { Util } from '../../core/Util';
import { AdContext } from '../../enum/AdContext';
import { ValidationInterface } from '../../iface';
import { ResourceAdInterface } from '../../iface/ResourceAdInterface';
import { ResourceAdDaiInterface } from '../../iface/ResourceAdDaiInterface';
import { ResourceConfigurationInterface } from '../../iface/ResourceConfigurationInterface';


export class ResourceValidator {

    private static defaultResource: ResourceConfigurationInterface = new ResourceConfiguration();

    static validate(r: ResourceConfigurationInterface): ValidationInterface {
        const hasRootKeys = this.hasRootKeys(r);
        if (hasRootKeys.error) return hasRootKeys;

        const canAccessMedia = this.canAccessMedia(r);
        if (canAccessMedia.error) return canAccessMedia;

        return { error: false, msg: '' }
    }

    private static hasRootKeys(r: ResourceConfigurationInterface): ValidationInterface {
        const ks = Object.keys(this.defaultResource);
        let i = ks.length, msg = '';

        while (i--) {
            if (!(ks[i] in r)) {
                msg = `Property ${ks[i]} not found.`;
                break;
            }
        }

        return {
            error: msg != '',
            msg: msg
        }
    }

    private static canAccessMedia(r: ResourceConfigurationInterface): ValidationInterface {
        const hasCms = !Util.isEmpty(r.location.cms),
            hasIdOrUri = !Util.isEmpty(r.location.tokenMap) || !Util.isEmpty(r.location.cmsUri),
            hasMediaUrl = !Util.isEmpty(r.location.mediaUrl),
            hasSsbMedia = r.ad.context === AdContext.DAI_SSB && !Util.isEmpty(r.ad.dai.ssbStreamUrl),
            hasValidAdSettings = this.hasValidDaiSettings(r),
            mediaNotConfigured = !(hasSsbMedia || hasMediaUrl) && !(hasCms && hasIdOrUri),
            msg = hasValidAdSettings.error ? hasValidAdSettings.msg : (mediaNotConfigured ? 'Media not specified' : '');

        return {
            error: mediaNotConfigured && hasValidAdSettings.error,
            msg: msg
        }
    }

    private static hasValidDaiSettings(r: ResourceConfigurationInterface): ValidationInterface {
        const adSets: ResourceAdInterface = r.ad,
            dp: ResourceAdDaiInterface = adSets.dai;
        let error: boolean = false, msg = '';

        if (adSets.context === AdContext.DAI_SDK || adSets.context === AdContext.DAI_API) {
            error = !this.isValidDaiResource(dp);
            error && (msg = 'Invalid DAI configuration');
        }
        else if (adSets.context === AdContext.DAI_SSB) {
            error = Util.isEmpty(dp.ssbStreamUrl);
            error && (msg = 'SSB stream url missing');
        }

        return { error: error, msg: msg };
    }

    private static isValidDaiResource(p: ResourceAdDaiInterface): boolean {
        if (p.isLive) {
            return !Util.isEmpty(p.assetKey);
        }
        return !Util.isEmpty(p.contentSourceId) && !Util.isEmpty(p.daiVideoId);
    }
}
