import { AppResources } from '../../app/AppResources';
import { Util } from '../../core/Util';
import { ErrorCode } from '../../enum/ErrorCode';
import { LogLevel } from '../../enum/LogLevel';
import { TextTrackKind } from '../../enum/TextTrackKind';
import { MetadataCuepoint, Range, VideoSurfaceInterface } from '../../iface';
import { AudioTrackInterface } from '../../iface/AudioTrackInterface';
import { LoggerInterface } from '../../iface/LoggerInterface';
import { PlaybackAdapterConfigInterface } from '../../iface/PlaybackAdapterConfigInterface';
import { QualityInterface } from '../../iface/QualityInterface';
import { ResourcePlaybackAbrInterface } from '../../iface/ResourcePlaybackAbrInterface';
import { StrAnyDict } from '../../iface/StrAnyDict';
import { Utils } from '../../util/Utils';
import { Playback } from '../enum/Playback';
import { PlaybackAdapterEvents } from '../enum/PlaybackAdapterEvents';
import { PlaybackAdapterType } from '../enum/PlaybackAdapterType';
import { ShakaRobustness } from '../enum/ShakaRobustness';
import { TextTrackEvents } from '../enum/TextTrackEvents';
import { TextTrackMode } from '../enum/TextTrackMode';
import { TextTrackSurfaceEvents } from '../enum/TextTrackSurfaceEvents';
import { Category, Code, EmsgInfo, Error, LanguageRole, NetworkingEngine, Player, PlayerConfiguration, Request, Response, shaka, TimelineRegionInfo, Track } from '../interface/ShakaInterface';
import { BaseHtml5Adapter } from './BaseHtml5Adapter';


export class ShakaAdapter extends BaseHtml5Adapter {

    protected pType: PlaybackAdapterType = PlaybackAdapterType.SHAKA;

    private shaka: shaka = (<any>window).shaka;
    private player!: Player;
    private playerConfig!: PlayerConfiguration;
    private networkEngine: NetworkingEngine;
    private audioTracks!: LanguageRole[];
    private textTracks: Track[] = [];
    private pTextTrack: TextTrack;
    private currentTextTrack: Track;
    private currentTextTrackMode: TextTrackMode;
    private variant: Track;
    private playerEventMap: StrAnyDict[] = [
        this.mapEvent('error', this.onError),
        this.mapEvent('streaming', this.onManifestParsed),
        this.mapEvent('variantchanged', this.onBitrateChanged),
        this.mapEvent('adaptation', this.onBitrateChanged),
        this.mapEvent('emsg', this.onEmsg),
        this.mapEvent('trackschanged', this.onTracksChanged),
        this.mapEvent('variantchanged', this.onVariantChanged),
        this.mapEvent('adaptation', this.onAdaptation),
        this.mapEvent('drmsessionupdate', this.onDrmSessionUpdate),
        this.mapEvent('texttrackvisibility', this.onTextTrackVisibility),
        this.mapEvent('timelineregionenter', this.onTimelineRegionEnter),
    ];
    private onCueChangeHandler: EventListenerOrEventListenerObject = (e: TrackEvent) => this.onCueChange(e);
    private audioSwitching: boolean = false;
    private renderTextTrackNatively: boolean = true;
    private cleanUpVtt: boolean = false;

    constructor(videoSurface: VideoSurfaceInterface, config: PlaybackAdapterConfigInterface, logger: LoggerInterface) {
        super(videoSurface, config, logger);

        this.logger.log(LogLevel.INFO, 'ShakaAdapter created');
        this.updateAudioTracks = Util.debounce(this.updateAudioTracks.bind(this), 25);
        this.updateTextTracks = Util.debounce(this.updateTextTracks.bind(this), 25);
    }

    ////////////////////
    // Public Methods
    ////////////////////

    initialize(): void {
        super.initialize();

        // Shaka polyfill for VTTCue can cause issues when hlsjs is loaded after Shaka. See VTG-2189
        if (typeof VTTCue == 'undefined') {
            this.cleanUpVtt = true;
        }

        // player initialization
        this.shaka.polyfill.installAll();

        this.player = new this.shaka.Player(this.videoSurface.video);
        this.logger.log(LogLevel.INFO, `Shaka version: ${this.shaka.Player.version}`);
        this.playerConfig = this.player.getConfiguration();
        this.addEvents(this.player, this.playerEventMap);

        // retry config
        let retry = this.playerConfig.streaming.retryParameters;
        retry.maxAttempts = ShakaRobustness.FATAL_ERROR_RECOVERY_ATTEMPTS;
        retry.baseDelay = ShakaRobustness.FATAL_ERROR_RECOVERY_DELAY;
        retry.backoffFactor = ShakaRobustness.FATAL_ERROR_RECOVERY_BACKOFF;
        retry.fuzzFactor = ShakaRobustness.FATAL_ERROR_RECOVERY_FUZZ;

        retry = this.playerConfig.manifest.retryParameters;
        retry.maxAttempts = ShakaRobustness.MANIFEST_RETRY_ATTEMPTS;
        retry.baseDelay = ShakaRobustness.MANIFEST_RETRY_INTERVAL;

        // network config
        this.networkEngine = this.player.getNetworkingEngine();
        this.networkEngine.registerRequestFilter(this.onRequest.bind(this));
        this.networkEngine.registerResponseFilter(this.onResponse.bind(this));

        // drm config
        const drm = this.config.resource.location.drm;
        if (drm.widevine && drm.widevine.url) {
            this.playerConfig.drm.servers['com.widevine.alpha'] = drm.widevine.url;
        }

        if (drm.playready && drm.playready.url) {
            this.playerConfig.drm.servers['com.microsoft.playready'] = drm.playready.url;
        }

        // abr config
        const abrConfig: ResourcePlaybackAbrInterface = this.playback.abr;
        const abr = this.playerConfig.abr;

        if (!isNaN(abrConfig.minBitrate)) {
            abr.restrictions.minBandwidth = abrConfig.minBitrate;
        }

        if (!isNaN(abrConfig.maxBitrate)) {
            abr.restrictions.maxBandwidth = abrConfig.maxBitrate;
        }

        if (!isNaN(abrConfig.startBitrate)) {
            abr.defaultBandwidthEstimate = abrConfig.startBitrate * 1.15;
        }

        // performance settings
        const settings = this.config.performanceSettings;
        if (settings.forwardBufferLength != null) {
            this.playerConfig.streaming.bufferingGoal = settings.forwardBufferLength;
        }
        if (settings.backBufferLength != null) {
            this.playerConfig.streaming.bufferBehind = settings.backBufferLength;
        }

        this.renderTextTrackNatively = this.config.playerOptions.renderTextTrackNatively;
        if (this.renderTextTrackNatively == false) {
            this.playerConfig.streaming.alwaysStreamText = true;
        }

        this.playerConfig = this.mergeStreamingConfigs(this.playerConfig, this.config.resource.overrides);

        this.configure();
    }

    resize(): void {
        if (!this.playback.abr.capQualityToScreenSize) {
            return;
        }

        const low = this.getVariantTracks()[0];
        if (!low) {
            return;
        }

        let { clientWidth, clientHeight } = this.videoSurface.video;

        if (clientWidth < low.width || clientHeight < low.height) {
            clientWidth = low.width;
            clientHeight = low.height;
        }

        this.playerConfig.restrictions.maxWidth = clientWidth;
        this.playerConfig.restrictions.maxHeight = clientHeight;
        this.configure();
    }

    destroy(): Promise<void> {
        // WORKAROUND
        this.audioTrackMap = null;

        this.removeEvents(this.player, this.playerEventMap);

        if (this.pTextTrack) {
            this.pTextTrack.removeEventListener(TextTrackEvents.CUE_CHANGE, this.onCueChangeHandler);
        }

        return this.player.destroy()
            .then(() => {
                this.player = null;
                this.networkEngine = null;
                this.playerConfig = null;
                this.shaka = null;
                this.textTracks = null;
                this.audioTracks = null;
                this.variant = null;
                this.pTextTrack = null;
                this.currentTextTrack = null;

                if (this.cleanUpVtt) {
                    delete window.VTTCue;
                }

                return super.destroy();
            });
    }

    suspend(): void {
        this.playerConfig.streaming.bufferingGoal = 1;
        this.playerConfig.streaming.rebufferingGoal = 1;
    }

    resume(): void {
        this.playerConfig.streaming.bufferingGoal = 10;
        this.playerConfig.streaming.rebufferingGoal = 10;
    }

    clearCue(): void {
        Util.clearCue(this.pTextTrack, this.videoSurface.video.currentTime);
    }

    ////////////////////
    // Accessors
    ////////////////////
    get seekable(): Range {
        return this.player.seekRange();
    }

    set autoQualitySwitching(value: boolean) {
        this.playerConfig.abr.enabled = value;
        this.configure();
    }
    get autoQualitySwitching(): boolean {
        return this.playerConfig.abr.enabled;
    }

    set currentIndex(index: number) {
        const track = this.getVariantTracks()[index];
        this.player.selectVariantTrack(track, true);
    }
    get currentIndex(): number {
        const index = Util.findIndex(this.getVariantTracks(), this.isActive);
        return index;
    }

    get segmentDuration(): number {
        return this.player.getManifest().presentationTimeline.getMaxSegmentDuration();
    }

    get manifestQualities(): QualityInterface[] {
        return this.getVariantTracks().map((item: Track, index: number): QualityInterface => ({
            index,
            bitrate: item.bandwidth,
            width: item.width,
            height: item.height,
            codec: item.codecs
        }));
    }

    set maxBitrate(value: number) {
        if (isNaN(value)) {
            value = Infinity;
        }
        this.playerConfig.abr.restrictions.maxBandwidth = value;
        this.configure();
        this.constrainAbr();
    }
    get maxBitrate(): number {
        return this.playerConfig.abr.restrictions.maxBandwidth;
    }

    set minBitrate(value: number) {
        if (isNaN(value)) {
            value = -Infinity;
        }
        this.playerConfig.abr.restrictions.minBandwidth = value;
        this.configure();
        this.constrainAbr();
    }
    get minBitrate(): number {
        return this.playerConfig.abr.restrictions.minBandwidth;
    }

    set audioTrack(track: AudioTrackInterface) {
        this.audioSwitching = true;

        // WORKAROUND
        track = this.audioTrackMap[track.index] || track;

        this.player.selectAudioLanguage(track.lang, track.type);
    }

    get droppedVideoFrames(): number {
        const stats = this.player.getStats();
        return stats.droppedFrames;
    }

    get framerate(): number {
        const track = this.variant;
        return track ? track.frameRate : Number.NaN;
    }

    set textTrackMode(mode: TextTrackMode) {
        this.currentTextTrackMode = mode;

        if (this.renderTextTrackNatively) {
            this.player.setTextTrackVisibility(mode != TextTrackMode.DISABLED);

            // NOTE: The textTrack mode needs to be overridden because Shaka uses showing/hidden, but the player uses showing/disabled. 
            this.pTextTrack.mode = mode;
        }
        else {
            this.onTextTrackVisibility();
        }
    }

    set textTrack(track: TextTrack) {
        // NOTE: We need ts-ignore here because the object being passed in is a Shaka text track, not a DOM text track
        //@ts-ignore
        if (this.currentTextTrack && track.id == this.currentTextTrack.id) {
            return;
        }

        //@ts-ignore
        this.currentTextTrack = track;
        this.emit(TextTrackSurfaceEvents.TEXT_TRACK_CHANGE, this.currentTextTrack);
        this.player.selectTextTrack(<any>track);
    }

    get fragmentType(): string {
        const track = this.getVariantTrack();
        return track ? track.mimeType : '';
    }

    ////////////////////
    // Protected Methods
    ////////////////////

    protected loadMediaUrl(): Promise<void> {
        const startTime = this.playback.startTime;
        return this.player.load(this.mediaUrl, (isNaN(startTime) || startTime < 0) ? 0 : startTime)
            .then(() => {
                this.pIsLiveStream = this.player.isLive();

                this.loadedMetadata();

                // TODO: The player config only allows for a single side car file, with only a URL.
                //       This should be updated to accept an array of track objects.
                const textTrackUrl = this.config.resource.location.textTrackUrl;
                if (!Util.isEmpty(textTrackUrl)) {
                    const mime = Util.getMimeType(textTrackUrl);
                    this.player.addTextTrack(textTrackUrl, 'en', TextTrackKind.CAPTIONS, mime);
                }
            })
            // Shaka does not dispatch an error event when a manifest parse error occurs. Force one here:
            .catch((detail: any) => this.onError({ detail }));
    }

    ////////////////////
    // Event Handlers
    ////////////////////
    private onError(e: any): void {
        const error = e.detail;
        const Error: Error = this.shaka.util.Error;
        const Category: Category = Error.Category;
        const Code: Code = Error.Code;

        // Shaka Player errors do not have a string based message, so find the key associated with numeric error code.
        const message = (msg: string): string => {
            const code = error.code;
            for (const key in Code) {
                const value = Code[key];
                if (value == code) {
                    msg += ` : ${key} / ${value}`;
                    break;
                }
            }
            return msg;
        };

        switch (error.category) {
            case Category.NETWORK:
                this.throwError(ErrorCode.SHAKA_NETWORK_ERROR, message(AppResources.messages.FATAL_PLAYBACK_NETWORK_ERROR), error);
                break;

            case Category.MANIFEST:
                this.throwError(ErrorCode.SHAKA_PARSE_ERROR, message(AppResources.messages.FATAL_PLAYBACK_MEDIA_ERROR), error);
                break;

            case Category.MEDIA:
                const code = (error.code == Code.VIDEO_ERROR && error.data[0] == MediaError.MEDIA_ERR_SRC_NOT_SUPPORTED) ? ErrorCode.SHAKA_SRC_NOT_SUPPORTED : ErrorCode.SHAKA_MEDIA_ERROR;
                this.throwError(code, message(AppResources.messages.FATAL_PLAYBACK_MEDIA_ERROR), error);
                break;

            case Category.DRM:
                this.throwError(ErrorCode.SHAKA_DRM_ERROR, message(AppResources.messages.FATAL_PLAYBACK_MEDIA_ERROR), error);
                break;

            default:
                this.throwError(ErrorCode.UNSPECIFIED_SHAKA_ERROR, message(AppResources.messages.UNSPECIFIED_ERROR), error, false);
        }
    }

    private onManifestParsed(e: any): void {
        this.emit(PlaybackAdapterEvents.MANIFEST_PARSED, { profile: this.manifestQualities });
    }

    private onBitrateChanged(e: any) {
        this.constrainAbr();
        this.emit(PlaybackAdapterEvents.ABR_QUALITY_LOADED, { index: this.currentIndex });
    }

    private onRequest(type: number, request: Request): void {
        const drm = this.config.resource.location.drm;

        if (type === this.shaka.net.NetworkingEngine.RequestType.LICENSE && drm.enabled) {
            if (drm.widevine && drm.widevine.header) {
                Util.assign(request.headers, drm.widevine.header);
            }

            if (drm.playready && drm.playready.header) {
                Util.assign(request.headers, drm.playready.header);
            }
        }
    }

    private onResponse(type: number, response: Response): void {
        if (this.multiCdnHeaderPresent) {
            const cdn = response.headers[Playback.MULTI_CDN];
            this.multiCdnHeaderPresent = (cdn != null);
            if (this.multiCdnHeaderPresent) {
                this.emit(PlaybackAdapterEvents.MULTI_CDN, { cdn });
            }
        }

        if (type === this.shaka.net.NetworkingEngine.RequestType.SEGMENT) {
            const bandwidth = this.player.getStats().estimatedBandwidth;
            this.emit(PlaybackAdapterEvents.FRAGMENT_LOADED, { bandwidth });
        }
    }

    private onEmsg(e: any): void {
        const emsg: EmsgInfo = e.detail;
        const cue: MetadataCuepoint = {
            id: emsg.schemeIdUri,
            info: emsg.value,
            data: emsg.messageData
        };
        this.emit(TextTrackSurfaceEvents.METADATA_CUEPOINT, cue);
    }

    private onTracksChanged(e: any): void {
        this.resize();

        this.updateAudioTracks();
        this.updateTextTracks();
    }

    private onAdaptation(e: any): void {
        this.variant = this.getVariantTrack();
    }

    private onVariantChanged(e: any): void {
        if (this.audioSwitching) {
            this.audioSwitching = false;
            this.emit(PlaybackAdapterEvents.AUDIO_TRACK_CHANGE, { track: this.normalizedAudioTracks[this.getAudioTrackIndex()] });
        }

        this.variant = this.getVariantTrack();
    }

    private onDrmSessionUpdate(e: any): void {
        this.emit(PlaybackAdapterEvents.DRM_KEYSYSTEM_CREATED, { keysystem: this.player.keySystem() });
    }

    private onTextTrackVisibility(): void {
        if (!this.currentTextTrackMode) {
            return;
        }
        this.emit(TextTrackSurfaceEvents.TEXT_TRACK_DISPLAY_MODE_CHANGE, { mode: this.currentTextTrackMode });
    }

    private onCueChange(e: Event): void {
        if (this.renderTextTrackNatively || !this.config.textTrackSettings.enabled) {
            return;
        }
        const t = event.target as TextTrack;
        this.emit(TextTrackSurfaceEvents.TEXT_CUEPOINT, { activeCues: t.activeCues });
    }

    private onTimelineRegionEnter(e: any) {
        if (this.videoSurface.video.seeking) {
            return;
        }

        const info: TimelineRegionInfo = e.detail;
        const cue: MetadataCuepoint = {
            id: info.schemeIdUri,
            info: info.value || info.eventElement.getAttribute('messageData'),
            data: <any>info
        };

        this.emit(TextTrackSurfaceEvents.METADATA_CUEPOINT, cue);
    }

    ////////////////////
    // Private Methods
    ////////////////////
    private isActive(track: Track): boolean {
        return track.active;
    }

    private getAudioTrackIndex(): number {
        const { language, audioRoles } = this.getVariantTracks()[this.currentIndex];
        const role = audioRoles.join(',');
        return Util.findIndex(this.audioTracks, (item: any) => item.language == language && item.role == role);
    }

    private configure(): void {
        this.player.configure(this.playerConfig);
    }

    private constrainAbr(): void {
        const maxIndex = Utils.getIndexForBitrate(this.manifestQualities, this.maxBitrate, false);
        this.checkAbrConstraints(maxIndex);
    }

    private getVariantTracks(): Track[] {
        return this.player.getVariantTracks().sort((a: Track, b: Track): number => a.bandwidth - b.bandwidth);
    }

    private getVariantTrack(): Track {
        return Util.find(this.player.getVariantTracks(), this.isActive);
    }

    // WORKAROUND
    private audioTrackMap: Record<number, AudioTrackInterface> = {};

    private updateAudioTracks(): void {
        //since this fires on bitrate changes... do we want to check if the lang and roles has changed and only then process and emit?
        this.audioTracks = this.player.getAudioLanguagesAndRoles();

        this.normalizedAudioTracks = this.normalizeAudioTracks(this.audioTracks, {
            type: 'role',
            lang: 'language',
            label: 'language'
        });

        // WORKAROUND
        this.normalizedAudioTracks.forEach((track, index, tracks) => {
            if (track.type == 'description' && track.lang == 'und') {
                tracks[index] = Object.assign({}, track, { lang: 'en', type: '' });
                this.audioTrackMap[index] = track;
            }
        });

        this.emit(PlaybackAdapterEvents.AUDIO_TRACK_UPDATED, {
            tracks: this.normalizedAudioTracks,
            track: this.normalizedAudioTracks[this.getAudioTrackIndex()],
        });
    }

    private updateTextTracks(): void {
        this.player.getTextTracks().forEach(track => {
            const hasTrack = this.textTracks.some(t => t.id == track.id);
            if (!hasTrack) {
                this.textTracks.push(track);
                this.emit(TextTrackSurfaceEvents.TEXT_TRACK_ADDED, track);
            }
        });

        const available = !this.currentTextTrack;
        const active = Util.find(this.textTracks, this.isActive);

        if (active && active != this.currentTextTrack) {
            //@ts-ignore
            this.textTrack = active;
        }

        if (available) {
            this.pTextTrack = Util.find(this.videoSurface.video.textTracks, (textTrack: TextTrack) => textTrack.label == 'Shaka Player TextTrack');
            this.pTextTrack.addEventListener(TextTrackEvents.CUE_CHANGE, this.onCueChangeHandler);

            const track = active || this.textTracks[0];
            if (track) {
                //@ts-ignore
                this.currentTextTrack = track;
                this.emit(TextTrackSurfaceEvents.TEXT_TRACK_AVAILABLE);
            }
        }

        // re-apply the text track visibility to workaround issues where
        // visibility was enabled during a period with no text tracks.
        if (this.renderTextTrackNatively) {
            this.player.setTextTrackVisibility(this.config.textTrackSettings.enabled);
        }
    }
}
