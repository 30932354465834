import { AbstractMacroCommand } from '../../mvc/AbstractMacroCommand';
import { PrepModelCommand } from './PrepModelCommand';
import { PrepPluginsCommand } from './PrepPluginsCommand';
import { PrepServiceCommand } from './PrepServiceCommand';
import { PrepTimerCommand } from './PrepTimerCommand';


export class BootWebMafCommand extends AbstractMacroCommand {

    constructor() {
        super();
    }

    initializeMacroCommand(): void {
        this.addSubCommand(PrepModelCommand);
        this.addSubCommand(PrepServiceCommand);
        this.addSubCommand(PrepTimerCommand);
        this.addSubCommand(PrepPluginsCommand);
    }
}
