import { TextTrackSettingsInterface } from '../iface';
import { Proxy } from '../mvc/Proxy';
import { TextTrackMode } from '../playback/enum/TextTrackMode';


export class TextTrackProxy extends Proxy implements TextTrackSettingsInterface {

	constructor(name: string, data: any = {}) {
		super(name, {
			native: data.native !== false,
			mode: TextTrackMode.DISABLED,
			language: (data.language != null) ? data.language : navigator.language
		});
	}

	set mode(value: TextTrackMode) {
		this.data.mode = value;
	}

	get mode(): TextTrackMode {
		return this.data.mode;
	}

	set enabled(value: boolean) {
		if (value) {
			this.mode = this.enabledMode;
		}
		else {
			this.mode = TextTrackMode.DISABLED;
		}
	}

	get enabled(): boolean {
		return this.data.mode != TextTrackMode.DISABLED;
	}

	get enabledMode(): TextTrackMode {
		return (this.data.native) ? TextTrackMode.SHOWING : TextTrackMode.HIDDEN;
	}

	get native(): boolean {
		return this.data.native;
	}

	set language(value: string) {
		this.data.language = value;
	}

	get language(): string {
		return this.data.language;
	}
}
