import { Emitter } from '../../core/Emitter';
import { dai } from '../../dai';
import { AsyncDataRequest } from '../../dataservice/AsyncDataRequest';
import { AsyncDataRequestOptions } from '../../dataservice/AsyncDataRequestOptions';
import { ErrorCode } from '../../enum/ErrorCode';
import { LogLevel } from '../../enum/LogLevel';
import { VideoFormat } from '../../enum/VideoFormat';
import { XhrResponseType } from '../../enum/XhrResponseType';
import { AdEventStatusInterface, SimpleVideoInterface } from '../../iface';
import { ErrorRecoveryInterface } from '../../iface/ErrorRecoveryInterface';
import { EventHandler } from '../../iface/EventHandler';
import { EventInterface } from '../../iface/EventInterface';
import { LoggerInterface } from '../../iface/LoggerInterface';
import { StrAnyDict } from '../../iface/StrAnyDict';
import { Ad } from './Ad';
import { AdPodInfo } from './AdPodInfo';
import { StreamEvent } from './StreamEvent';
import { Util } from '../../core/Util';


//////////////////
// local iface defs
interface AdBreakObject {
    index: number;
    seq: number;
    duration: number;
    start: number;
    end: number;
    type: string;
    ads: dai.AdResponse[];
    played: boolean;
}

interface AdStatus {
    [index: string]: boolean;
}

interface AdStatusByBreak {
    [index: string]: AdStatus;
}

interface BreakInfo {
    break: AdBreakObject,
    index: number;
}

// end iface defs
/////////////////

export class StreamManager extends Emitter implements dai.StreamManager {

    static event: dai.StreamEventType = {
        LOADED: 'loaded',
        STREAM_INITIALIZED: 'streamInitialized',
        CUEPOINTS_CHANGED: 'cuePointsChanged',
        AD_BREAK_STARTED: 'adBreakStarted',
        AD_BREAK_ENDED: 'adBreakEnded',
        STARTED: 'started',
        COMPLETE: 'complete',
        AD_PROGRESS: 'adProgress',
        FIRST_QUARTILE: 'firstQuartile',
        MIDPOINT: 'midpoint',
        THIRD_QUARTILE: 'thirdQuartile',
        CLICK: 'click',
        ERROR: 'error',
        AD_PERIOD_STARTED: 'adPeriodStarted',
        AD_PERIOD_ENDED: 'adPeriodEnded',
    };

    static eventStatusMap: StrAnyDict = {
        start: 'started',
        firstquartile: 'firstQuartile',
        midpoint: 'midpoint',
        thirdquartile: 'thirdQuartile',
        complete: 'complete',
    };

    static DAI_BASE_URL: string = 'https://dai.google.com';
    static STREAM_PATH: string = '/ondemand/v1/{format}/content/{contentSourceId}/vid/{daiVideoId}/stream';

    private adBreaks: AdBreakObject[] = [];
    private currBreak: AdBreakObject = null;
    private currBreakIndex: number = null;
    private currAd: dai.AdResponse = null;
    private currEventStatus: AdEventStatusInterface;
    private breakStatus: AdStatusByBreak = {};
    private breakPoints: number[] = [];
    private pollId: any = null;
    private vidIface: SimpleVideoInterface;
    private logger: LoggerInterface;
    private lastRecordedTime: number = -1;

    private streamId!: string;
    private streamDur!: number;
    private mvu!: string;
    private clickEl!: HTMLElement;
    private clickThruHandler!: EventListenerOrEventListenerObject;

    constructor(SimpleVideoInterface: SimpleVideoInterface, adContainer: HTMLElement, logger?: LoggerInterface) {
        super(null);

        this.currEventStatus = this.createEventStatus();
        this.currBreakIndex = null;
        this.vidIface = SimpleVideoInterface;
        this.logger = logger;
        this.setClickElement(adContainer);
    }

    /////////////////////////////////////////
    // Public API - replicates API of DAI SDK
    addEventListener(name: string, fn: EventHandler): void {
        this.on(name, fn);
    }

    removeEventListener(name: string, fn: EventHandler): void {
        this.off(name, fn);
    }

    requestStream(streamReq: dai.VODStreamRequest | dai.LiveStreamRequest, er: ErrorRecoveryInterface = null): void {
        streamReq.adTagParameters.correlator = this.getCorrelator();

        if (streamReq.apiKey) {
            streamReq.adTagParameters['api-key'] = streamReq.apiKey;
        }

        const reqOpts = AsyncDataRequestOptions.create({
            responseType: XhrResponseType.JSON,
            timeout: 3000,
            method: 'post',
            errorRecovery: er,
            headers: { 'Content-Type': 'application/x-www-form-urlencoded', },
            url: this.getUrl(streamReq.contentSourceId, streamReq.videoId, streamReq.format || 'hls'),
            data: streamReq.adTagParameters,
            encodeList: ['cust_params'],
            onComplete: (e: EventInterface) => {
                this.handleStreamLoaded(e);
            }
        });

        new AsyncDataRequest(reqOpts);
    }

    setClickElement(clickElement: HTMLElement): void {
        if (!clickElement) return;

        this.clickEl = clickElement || null;
        this.clickThruHandler = (e: Event) => { this.hClickThru(e); };
        this.clickEl.addEventListener('click', this.clickThruHandler);
    }

    // supply streamTime in seconds; returns content time
    contentTimeForStreamTime(streamTime?: number): number {
        const bks = this.adBreaks;
        let breakSum = 0, b;

        if (streamTime == undefined) {
            streamTime = this.streamDur;
        }

        for (let i = 0, n = bks.length; i < n; i++) {
            b = bks[i];
            if (streamTime > b.start) {
                if (streamTime >= b.end) {
                    breakSum += b.duration;
                    if (i == n - 1) {
                        return streamTime - breakSum;
                    }
                }
                else {
                    return b.start - breakSum;
                }
            }
            else {
                return streamTime - breakSum;
            }
        }

        return streamTime;
    }

    streamTimeForContentTime(t: number): number {
        const bks = this.adBreaks;

        let sum = 0, bct, b;

        for (let i = 0, n = bks.length; i < n; i++) {
            b = bks[i];
            bct = this.contentTimeForStreamTime(bks[i].start);
            if (t >= bct) sum += b.duration;
            else break;
        }

        return t + sum;
    }

    previousCuePointForStreamTime(streamTime: number): dai.CuePoint {
        const bks = this.adBreaks;
        let i = bks.length, b;

        while (i--) {
            b = bks[i];
            if (streamTime >= b.start) {
                return (!b.played) ? this.cuePointFromBreak(b) : null;
            }
        }

        return null;
    }

    // Pass  processed metadata - used for media_verification call
    onTimedMetadata(metadata: dai.StreamMetadataInterface): void {
        const reqOpts = AsyncDataRequestOptions.create({
            responseType: XhrResponseType.JSON,
            timeout: 2000,
            url: this.mvu + metadata.TXXX,
            onComplete: (e) => { },
            errorRecovery: {}
        });

        new AsyncDataRequest(reqOpts);
    }

    processMetadata(type: string, data: Uint8Array | string, timestamp: number): void {
        // no impl necessary
    }

    // Resets the stream manager and removes any continuous polling.
    reset(): void {
        clearInterval(this.pollId);
        this.clickEl && this.clickEl.removeEventListener('click', this.clickThruHandler);
        this.clickEl = null;
        this.streamId = null;
        this.adBreaks = [];
        this.breakPoints = [];
        this.streamDur = NaN;
        this.currBreak = null;
        this.currBreakIndex = null;
        this.currAd = null;
        this.mvu = null;
        this.resetUponAdComplete();
        this.lastRecordedTime = -1;
        this.pollId = null;
    }
    // END Public API
    ////////////////////////////

    ///////////
    // PRIVATE

    // State
    private beginPoll(): void {
        this.pollId = setInterval(() => { this.checkState(); }, 100);
    }

    private checkState(): void {
        const t = this.vidIface.currentTime;

        if (!isNaN(t) && t > 0 && t > this.lastRecordedTime) {

            if (!this.currBreak) {
                const info = this.getBreakForStreamTime(t);
                const k: AdBreakObject = info.break;
                if (k && k.played) {
                    this.emitAdPeriodStarted(k);
                }
                else {
                    k && this.beginBreak(k, info.index);
                }
            }
            // Note: this.currBreak may become defined
            // in block above, via beginBreak();
            if (this.currBreak) {
                const ck = this.currBreak;
                if (t >= (ck.end)) {
                    this.monitorAdStatus(t, ck);
                    this.endBreak();
                }
                else {
                    this.monitorAdStatus(t, ck);
                }
            }
        }

        !isNaN(t) && t >= 0 && (this.lastRecordedTime = t);
    }

    private monitorAdStatus(t: number, brk: AdBreakObject): void {
        const ads = brk.ads,
            brkSt = brk.start;

        if (this.currAd) {
            this.monitorCurrentAd(t);
            return;
        }

        let adSt: number,
            i = ads.length;
        while (i--) {
            adSt = ads[i].start || 0;
            if (t >= adSt || adSt == undefined) {
                if (!this.breakStatus[`t_${brkSt}`][`t_${adSt}`]) {
                    const btl = brk.type.toLowerCase();

                    this.currBreakIndex = btl === 'pre' ? 0 : (btl === 'mid' ? brk.index : -1);
                    this.beginAd(ads[i], adSt);
                    break;
                }
            }
        }
    }

    //  inspect curr Ad 'events' array for ad life-cycle events
    private monitorCurrentAd(t: number): void {
        const evts = this.currAd.events,
            status = this.currEventStatus;

        let emitProg = true,
            i = evts.length,
            evt: dai.AdEventObject;

        while (i--) {
            evt = evts[i];
            if (t >= evt.time && !status[evt.type]) {
                status[evt.type] = true;
                if (evt.type === 'complete' || t >= (this.currAd.start + this.currAd.duration)) {
                    emitProg = false;
                    this.emitAdComplete();
                    this.resetUponAdComplete();
                    break;
                }
                else if (evt.type !== 'start') {
                    emitProg = false;
                    this.emit(StreamManager.eventStatusMap[evt.type], this.createStreamData());
                    break;
                }
            }
        }

        emitProg && this.emitProgress(t);
    }

    private resetUponAdComplete(): void {
        this.currEventStatus = this.createEventStatus();
        this.currAd = null;
    }

    ///////////////////////////////
    // adBreak/ad life-cycle points
    private beginBreak(brk: AdBreakObject, index: number): void {
        this.currBreak = brk;
        this.currBreak.played = true;

        this.emitCues();

        const d = this.createStreamData();
        d.streamId = this.streamId;
        this.emit(StreamManager.event.AD_BREAK_STARTED, d);
    }

    private endBreak(): void {
        this.emit(StreamManager.event.AD_BREAK_ENDED, this.createStreamData());
        this.currBreak = null;
    }

    private beginAd(ad: dai.AdResponse, adSt: number): void {
        this.currAd = ad;
        this.breakStatus[`t_${this.currBreak.start}`][`t_${adSt}`] = true;
        this.currEventStatus.start = true;

        const sd = this.createStreamData();
        sd.streamId = this.streamId;

        const podInfo = new AdPodInfo(this.currBreak, this.currAd.seq, this.currBreakIndex),
            adInfo = new Ad(this.currAd, podInfo);

        const e = new StreamEvent(StreamManager.event.STARTED, this, sd, adInfo);

        this.dispatchEvt(e);
    }

    /////////////////
    // Event handling
    private handleStreamLoaded(e: EventInterface): void {
        if (e.data.error) {
            const msg = `Error. Status: ${e.data.status || 'Unknown'}; Message: ${e.data.message}`;
            this.logger.log(LogLevel.ERROR, msg);
            this.emitError(msg, ErrorCode.DAI_NETWORK_ERROR);
        }
        else {
            this.gatherStreamInfo(e.data.response);
            this.emitCues();
            this.beginPoll();
            this.emitLoaded(e.data.response);
        }
    }

    private hClickThru(e: Event): void {
        if (!this.currAd) {
            return;
        }
        const u = this.currAd.clickthrough_url;
        u && u !== '' && window.open(u);
        this.emit(StreamManager.event.CLICK);
    }

    /////////////////
    // Event dispatch

    // (override)
    emit(name: string, data: dai.StreamData = null): void {
        let e: EventInterface = new StreamEvent(name, this, data);
        this.dispatchEvt(e);
    }

    private emitCues() {
        const d = this.createStreamData();
        d.cuepoints = this.assembleCuePoints();

        this.emit(StreamManager.event.CUEPOINTS_CHANGED, d);
    }

    // note: passing null will force progress to 100%
    private emitProgress(t: number | null) {
        const ap = this.createAdProgressData(t === null ? (this.currAd.start || 0) + this.currAd.duration : t),
            sd = this.createStreamData();

        sd.adProgressData = ap;

        this.emit(StreamManager.event.AD_PROGRESS, sd);
    }

    private emitLoaded(s: dai.Stream): void {
        const d: dai.StreamData = this.createStreamData();

        d.stream_id = this.streamId;
        d.subtitles = s.subtitles;
        d.manifestFormat = this.getFormat(s.stream_manifest);
        d.url = s.stream_manifest;


        this.emit(StreamManager.event.LOADED, d);
    }

    private emitAdComplete(): void {
        const sd = this.createStreamData();
        sd.streamId = this.streamId;

        this.emitProgress(null);
        this.emit(StreamManager.event.COMPLETE, sd)
    }

    private emitError(msg: string, code: ErrorCode): void {
        const d = this.createStreamData();

        d.streamId = this.streamId;
        d.errorMessage = msg;
        d.code = code;

        this.emit(StreamManager.event.ERROR, d);
    }

    private emitAdPeriodStarted(k: AdBreakObject): void {
        const d = this.createStreamData();
        d.streamResumeTime = k.start + k.duration + 0.1;
        this.emit(StreamManager.event.AD_PERIOD_STARTED, d);
    }
    ///////
    // util
    private createAdProgressData(t: number): dai.AdProgressData {
        return {
            adBreakDuration: this.currBreak.duration,
            adPeriodDuration: null,
            adPosition: this.currAd.seq,
            currentTime: Util.clampValue(t - (this.currAd.start || 0), 0, this.currAd.duration),
            duration: this.currAd.duration,
            totalAds: this.currBreak.ads.length
        }
    }

    private createEventStatus(): AdEventStatusInterface {
        return {
            start: false,
            first: false,
            mid: false,
            third: false,
            complete: false
        };
    }

    private assembleCuePoints(): dai.CuePoint[] {
        const cpa = [];

        for (let i = 0, n = this.adBreaks.length; i < n; i++) {
            cpa.push(this.cuePointFromBreak(this.adBreaks[i]));
        }

        return cpa;
    }

    private getBreakForStreamTime(t: number): BreakInfo {
        let b;
        for (let i = 0, n = this.adBreaks.length; i < n; i++) {
            b = this.adBreaks[i];
            if (t >= b.start && ((t - b.start) <= 1.5)) {
                if (!this.currBreak) {
                    return { break: b, index: i };
                }
            }
        }

        return { break: null, index: null };
    }

    private getUrl(cid: string, vid: string, format: string): string {
        let u =
            StreamManager.DAI_BASE_URL +

            StreamManager.STREAM_PATH
                .replace('{format}', format)
                .replace('{contentSourceId}', cid)
                .replace('{daiVideoId}', vid)
            ;

        return u;
    }

    private getCorrelator(): string {
        let n = String(Math.round(1000000000000000 + Math.random() * 9000000000000000));

        if (n.length < 16) { while (n.length < 16) n += '0'; }
        if (n.length > 16) { n = n.substr(0, 17); }

        return n;
    }

    private cuePointFromBreak(b: AdBreakObject): dai.CuePoint {
        return {
            start: b.start,
            end: b.end,
            played: b.played
        };
    }

    private createStreamData(): dai.StreamData {
        return {
            adProgressData: null,
            cuepoints: null,
            errorMessage: null,
            streamId: this.streamId || null,
            manifestFormat: null,
            subtitles: null,
            url: null
        };
    }

    private getFormat(u: string): VideoFormat {
        const isHls = u.indexOf('.m3u8') >= 0,
            isDsh = !isHls && u.indexOf('.mpd') >= 0;

        return isHls ? VideoFormat.HLS : (isDsh ? VideoFormat.DASH : VideoFormat.UNKNOWN);
    }

    private gatherBreakInfo(b: dai.AdBreakResponse[]): void {
        const nBreaks = (b && b.length) || 0;

        // TODO - djl - For now, adhere to SDK CuePoint interface.
        // This can be made more convenient for cuePoint consumer.
        // (e.g.. by including break type and index)
        let k: dai.AdBreakResponse, s, e, d;
        let midSeq = 1;

        for (let i = 0; i < nBreaks; i++) {
            k = b[i];
            s = k.start;
            d = k.duration;
            e = s + d;

            this.breakPoints.push(s);
            this.adBreaks.push({
                duration: d,
                start: s,
                end: e,
                played: false,
                type: k.type,
                ads: k.ads,
                index: i,
                seq: k.type == 'mid' ? midSeq++ : 0
            });

            const adStat: AdStatus = this.breakStatus['t_' + s] = {};

            let ast;
            for (let q = 0, w = k.ads.length; q < w; q++) {
                ast = k.ads[q].start || 0;
                adStat['t_' + ast] = false;
            }
        }
    }

    private gatherStreamInfo(s: dai.Stream) {
        this.streamDur = s.total_duration;
        this.mvu = s.media_verification_url;
        this.streamId = s.stream_id;

        this.gatherBreakInfo(s.ad_breaks);
    }
}
