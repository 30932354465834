import { CoreEvent } from '../../core/CoreEvent';
import { dai } from '../../dai';


export class StreamEvent extends CoreEvent implements dai.StreamEvent {

    static Type: dai.StreamEventType = {
        LOADED: 'loaded',
        AD_BREAK_STARTED: 'adBreakStarted',
        AD_BREAK_ENDED: 'adBreakEnded',
        STARTED: 'started',
        COMPLETE: 'complete',
        FIRST_QUARTILE: 'firstQuartile',
        MIDPOINT: 'midpoint',
        THIRD_QUARTILE: 'thirdQuartile',
        AD_PERIOD_STARTED: 'adPeriodStarted',
        AD_PERIOD_ENDED: 'adPeriodEnded',
        CUEPOINTS_CHANGED: 'cuePointsChanged',
        STREAM_INITIALIZED: 'streamInitialized',
        ERROR: 'error',
        AD_PROGRESS: 'adProgress',
        CLICK: 'click',
    };

    data: dai.StreamData;
    private ad: dai.Ad = null;

    constructor(type: string, target: any, streamData: dai.StreamData, ad?: dai.Ad) {
        super(type, null, streamData);
        this.data = streamData;
        this.target = target;
        ad && (this.ad = ad);
    }

    get Type(): dai.StreamEventType {
        return StreamEvent.Type;
    }

    getAd(): dai.Ad {
        return this.ad;
    }

    getStreamData(): dai.StreamData {
        return this.data;
    }
}
