import { ImaAdsLoader } from '../ad/ImaAdsLoader';
import { Util } from '../core/Util';
import { AdContext } from '../enum/AdContext';
import { ImaVpaidMode } from '../enum/ImaVpaidMode';
import { ServiceName } from '../enum/ServiceName';
import { BuildInfoServiceInterface, ImaAdErrorInfoInterface, ImaAdServiceInterface, ImaAdsLoaderInterface, ImaAdsLoaderOptions, ImaAdsRenderingSettingsInterface, ImaSdkSettingsInterface, SystemServiceInterface } from '../iface';
import { BuildInfoInterface } from '../iface/BuildInfoInterface';
import { ima } from '../ima';
import { GamAdService } from './GamAdService';


export class ImaAdService extends GamAdService implements ImaAdServiceInterface {

    private pAdsLoader: ImaAdsLoaderInterface;
    private pAdsLoaderOptions: ImaAdsLoaderOptions;

    constructor(name: string) {
        super(name, AdContext.IMA);
    }

    destroy() {
        this.pAdsLoader && this.pAdsLoader.destroy();
        this.pAdsLoader = null;
        this.pAdsLoaderOptions = null;

        super.destroy();
    }

    get sdk(): ima.Sdk | null {
        const sysSvc = <SystemServiceInterface>this.getService(ServiceName.System),
            global = sysSvc.global;

        return (global && global.google && global.google.ima) || null;
    }

    get sdkSettings(): ImaSdkSettingsInterface {
        const biSvc = <BuildInfoServiceInterface>(this.getService(ServiceName.BuildInfo)),
            bi: BuildInfoInterface = biSvc;

        return {
            vpaidMode: ImaVpaidMode.INSECURE,
            disableCustomPlaybackForIOS10Plus: false,
            playerType: bi.playerName,
            playerVersion: bi.playerVersion
        };
    }

    get defaultRenderingSettings(): ImaAdsRenderingSettingsInterface {
        return {
            bitrate: 900,
            enablePreloading: false,
            loadVideoTimeout: -1,     // ms, default 8000 ms
            playAdsAfterTime: undefined,
            restoreCustomPlaybackStateOnAdBreakComplete: false
        };
    }

    set adsLoaderOptions(options: ImaAdsLoaderOptions) {
        this.pAdsLoaderOptions = options;
    }

    get adsLoader(): ImaAdsLoaderInterface {
        return this.pAdsLoader;
    }

    createAdsLoader(): void {
        if (!this.pAdsLoader) {
            this.pAdsLoader = new ImaAdsLoader(this.pAdsLoaderOptions);
        }
    }

    initializeAdsLoader(): boolean {
        this.createViewabilityTracker();
        return this.pAdsLoader.initialize();
    }

    // Error
    parseAdError(e: ima.AdErrorEvent): ImaAdErrorInfoInterface {
        const err = e.getError(),
            reqCtx = e.getUserRequestContext(),
            inner = err.getInnerError();

        return {
            type: this.getAdErrorType(err),
            errorCode: err.getErrorCode(),
            vastErrorCode: err.getVastErrorCode(),
            message: err.getMessage(),
            innerErrorMessage: Util.isString(inner) ? inner : null,
            sdkVersion: this.sdk.VERSION,
            requestContext: reqCtx || null
        }
    }

    //////////
    // PRIVATE
    private getAdErrorType(err: ima.AdError): string {
        const t = err.getType();
        return t == this.sdk.AdError.Type.AD_LOAD ? 'adLoad' : 'adPlay';
    }
}
