import { Emitter } from '../../core/Emitter';
import { Util } from '../../core/Util';
import { ErrorCode } from '../../enum/ErrorCode';
import { LogLevel } from '../../enum/LogLevel';
import { PlaybackAdapterInterface, VideoSurfaceInterface } from '../../iface';
import { AudioTrackInterface } from '../../iface/AudioTrackInterface';
import { EventHandler } from '../../iface/EventHandler';
import { EventInterface } from '../../iface/EventInterface';
import { LiveStreamInfoInterface } from '../../iface/LiveStreamInfoInterface';
import { LoggerInterface } from '../../iface/LoggerInterface';
import { OverridesInterface } from '../../iface/OverridesInterface';
import { PlaybackAdapterConfigInterface } from '../../iface/PlaybackAdapterConfigInterface';
import { QualityInterface } from '../../iface/QualityInterface';
import { ResourcePlaybackInterface } from '../../iface/ResourcePlaybackInterface';
import { StrAnyDict } from '../../iface/StrAnyDict';
import { StreamMetadataInterface } from '../../iface/StreamMetadataInterface';
import { Utils } from '../../util/Utils';
import { PlaybackAdapterEvents } from '../enum/PlaybackAdapterEvents';
import { PlaybackAdapterType } from '../enum/PlaybackAdapterType';
import { TextTrackMode } from '../enum/TextTrackMode';
import { TextTrackSurfaceEvents } from '../enum/TextTrackSurfaceEvents';
import { VideoSurfaceEvents } from '../enum/VideoSurfaceEvents';


export abstract class BasePlaybackAdapter extends Emitter implements PlaybackAdapterInterface {

    protected pType: PlaybackAdapterType;

    //**************************************
    // Developer Override Options
    protected enableLogger: boolean = false;
    //**************************************

    protected videoSurface: VideoSurfaceInterface;
    protected logger: LoggerInterface;
    protected playback: ResourcePlaybackInterface;
    protected config: PlaybackAdapterConfigInterface;
    protected mediaUrl: string;
    protected networkErrorRetryCount: number = 0;
    protected mediaErrorRetryCount: number = 0;
    protected minAutoLevel: number = NaN;
    protected maxAutoLevel: number = NaN;
    protected multiCdnHeaderPresent: boolean = true;
    protected pIsLiveStream: boolean = false;
    protected lowLevelDvrDetails: any = null;
    protected normalizedAudioTracks: AudioTrackInterface[];
    protected liveStreamInfoVO: LiveStreamInfoInterface = {
        isPlayingLive: false,
        liveEdgeOffset: NaN,
        dvrWindowSize: NaN,
        safeSeekingDuration: NaN,
        safeSeekingTime: NaN,
        relativeTime: NaN,
        relativeDuration: NaN,
        absoluteTime: NaN,
        absoluteDuration: NaN
    };

    protected blockTimeUpdateEvent = false;
    protected loaded: boolean = false;
    protected pendingLoad: Promise<void>;

    private videoSurfaceEventHandler: EventHandler = (e: EventInterface) => this.onVideoSurfaceEvent(e);

    constructor(videoSurface: VideoSurfaceInterface, config: PlaybackAdapterConfigInterface, logger: LoggerInterface) {

        super();

        this.videoSurface = videoSurface;
        this.config = config;
        this.playback = this.config.resource.playback;
        this.logger = logger;
        this.mediaUrl = config.resource.location.mediaUrl;

        //**************************************
        // Developer Override Options
        const o: OverridesInterface = this.config.playerOptions.overrides;
        if (!Util.isEmpty(o.enableLowLevelStreamingLogs)) {
            this.enableLogger = o.enableLowLevelStreamingLogs;
        }

        // if (!Util.isEmpty(o.exposePlaybackAdapter) && o.exposePlaybackAdapter === true) {
        //     this.config.system.global.cvp.playbackAdapter = this;
        // }
        //**************************************
    }

    ////////////////////
    //Abstract
    ////////////////////    
    abstract currentIndex: number;
    abstract autoQualitySwitching: boolean;
    abstract maxBitrate: number;
    abstract minBitrate: number;
    abstract liveStreamInfo: LiveStreamInfoInterface;
    abstract manifestQualities: QualityInterface[];

    ////////////////////
    //Public Methods
    ////////////////////

    initialize(): void {
        this.addEvents(this.videoSurface, VideoSurfaceEvents, this.videoSurfaceEventHandler);
        this.addEvents(this.videoSurface, TextTrackSurfaceEvents, this.videoSurfaceEventHandler);
    }

    destroy(): Promise<void> {

        // if (this.config.system.global.cvp.playbackAdapter) {
        //     this.config.system.global.cvp.playbackAdapter = null;
        // }

        this.removeEvents(this.videoSurface, VideoSurfaceEvents, this.videoSurfaceEventHandler);
        this.removeEvents(this.videoSurface, TextTrackSurfaceEvents, this.videoSurfaceEventHandler);
        this.videoSurface.destroy();
        this.videoSurface = null;
        this.logger = null;
        this.config = null;
        this.playback = null;
        this.liveStreamInfoVO = null;
        this.lowLevelDvrDetails = null;
        this.normalizedAudioTracks = null;
        super.destroy();
        return Promise.resolve();
    }

    play(): void {
        this.load().then(() => {
            this.videoSurface.play();
        });
    }

    pause(): void {
        this.videoSurface.pause();
    }

    suspend(): void {
        // no-op
    }

    resume(): void {
        // no-op
    }

    seek(position: number): void {
        this.videoSurface.seek(position);
    }

    load(url?: string): Promise<void> {
        if (this.loaded === true) {
            return Promise.resolve();
        }
        else if (this.pendingLoad) {
            return this.pendingLoad;
        }

        this.videoSurface.addEvents();

        const textTrackUrl = this.config.resource.location.textTrackUrl;
        if (!Util.isEmpty(textTrackUrl)) {
            this.videoSurface.textTrackSrc = textTrackUrl;
        }

        if (url) {
            this.mediaUrl = url;
        }

        this.pendingLoad = this.loadMediaUrl().then(() => {
            this.loadComplete();
        });

        return this.pendingLoad;
    }

    resize(): void {
        // no-op
    }

    clearCue(): void {
        this.videoSurface.clearCue();
    }

    ////////////////////
    //Accessors
    ////////////////////   

    set audioTrack(track: AudioTrackInterface) {
        this.videoSurface.video.audioTracks[track.index].enabled = true;
    }

    set textTrackMode(mode: TextTrackMode) {
        this.videoSurface.textTrackMode = mode
    }

    set textTrack(track: TextTrack) {
        this.videoSurface.textTrack = track;
    }

    get bufferLength(): number {
        return this.videoSurface.bufferLength;
    }

    get time(): number {
        return this.videoSurface.time;
    }

    get duration(): number {
        return this.videoSurface.duration;
    }

    get isLiveStream(): boolean {
        return this.pIsLiveStream;
    }

    get droppedVideoFrames(): number {
        return this.videoSurface.metrics.droppedVideoFrames;
    }

    get framerate(): number {
        return this.videoSurface.framerate;
    }

    get buffering(): boolean {
        return this.videoSurface.buffering;
    }

    get type(): PlaybackAdapterType {
        return this.pType;
    }

    get fragmentType(): string {
        return '';
    }

    ////////////////////
    //Protected Methods
    ////////////////////

    protected loadMediaUrl(): Promise<void> {
        const video = this.videoSurface;
        return new Promise((resolve, reject) => {
            const listener = (action: Function): EventHandler => {
                return (e: EventInterface): void => {
                    // eslint-disable-next-line @typescript-eslint/no-use-before-define
                    video.off(VideoSurfaceEvents.LOADED_METADATA, loadedmetadata);
                    // eslint-disable-next-line @typescript-eslint/no-use-before-define
                    video.off(VideoSurfaceEvents.ERROR, error);

                    action();
                }
            }
            const loadedmetadata = listener(() => {
                this.loadedMetadata();
                resolve();
            });
            const error = listener(reject);

            video.on(VideoSurfaceEvents.LOADED_METADATA, loadedmetadata);
            video.on(VideoSurfaceEvents.ERROR, error);
        });
    }

    protected getStreamMetadata(): StreamMetadataInterface {
        return {
            manifest: {
                mimeType: Util.getMimeType(this.mediaUrl)
            },
            fragment: {
                mimeType: this.fragmentType
            }
        };
    }

    protected loadedMetadata(): void {
        const metadata = this.getStreamMetadata();
        this.emit(PlaybackAdapterEvents.LOADED_METADATA, metadata);
    }

    protected loadComplete(): void {
        this.pendingLoad = null;
        this.loaded = true;
    }

    protected checkAbrConstraints(max: number): void {
        if (this.manifestQualities.length > 0) {
            const constraints: StrAnyDict = this.getCurrentAbrConstraints(max);
            this.updateAbrConstraints(constraints.min, constraints.max, this.manifestQualities);
        }
    }

    protected updateAbrConstraints(minIdx: number, maxIdx: number, profile: StrAnyDict[]): void {
        if (minIdx !== this.minAutoLevel || maxIdx !== this.maxAutoLevel) {
            this.minAutoLevel = minIdx;
            this.maxAutoLevel = maxIdx;

            this.emit(PlaybackAdapterEvents.ABR_CONSTRAINTS_CHANGE, {
                minIndex: this.minAutoLevel,
                maxIndex: this.maxAutoLevel,
                manifestQualities: profile
            });
        }
    }

    protected getCurrentAbrConstraints(max: number): StrAnyDict {
        return {
            min: (max) ? Utils.getIndexForBitrate(this.manifestQualities, this.minBitrate, true) : 0,
            max: max
        }
    }

    protected onVideoSurfaceEvent(e: EventInterface): void {
        if (e.type === VideoSurfaceEvents.ERROR ||
            e.type === VideoSurfaceEvents.STALLED ||
            e.type === VideoSurfaceEvents.ABORT) {
            this.handleVideoSurfaceError(e);
        }
        else if (e.type === VideoSurfaceEvents.TIME_UPDATE && this.blockTimeUpdateEvent) {
            return;
        }

        this.emit(e.type, e.data);
    }

    protected handleVideoSurfaceError(e: EventInterface) {
        const error = this.videoSurface.video.error;
        if (error) {
            switch (error.code) {
                case MediaError.MEDIA_ERR_ABORTED:
                    this.throwError(ErrorCode.MEDIA_ABORTED, error, error);
                    break;
            }
        }
    }

    protected mapEvent(type: string, callback: Function): StrAnyDict {
        return { type, callback: callback.bind(this) };
    }

    protected addEvents(adapter: any, map: any, callback?: Function): void {

        const hasOnMethod = Util.isFunction(adapter.on);

        for (let item in map) {
            const node = map[item];
            if (hasOnMethod) {
                adapter.on(node.type || node, node.callback || callback);
            }
            else {
                try {
                    adapter.addEventListener(node.type || node, node.callback || callback);
                }
                catch (e) { /* */ }
            }
        }
    }

    protected removeEvents(adapter: any, map: any, callback?: Function): void {

        const hasOnMethod = Util.isFunction(adapter.on);

        for (let item in map) {
            const node = map[item];
            if (hasOnMethod) {
                adapter.off(node.type || node, node.callback || callback);
            }
            else {
                try {
                    adapter.removeEventListener(node.type || node, node.callback || callback);
                }
                catch (e) { /* */ }
            }
        }
    }

    protected throwError(code: string, message: string, data: any, fatal: boolean = true) {
        this.log(LogLevel.ERROR, message);
        this.emit(PlaybackAdapterEvents.ERROR, { code, message, data, fatal });
    }

    protected log(...args: any[]) {
        this.logger.log.apply(this.logger, args);
    }

    protected getErrorMessage(msg: string, isFatal: boolean, retry: string = 'n/a'): string {
        return `${msg} fatal: ${isFatal} retry: ${retry}`
    }

    protected normalizeAudioTracks(tracks: StrAnyDict, map?: Partial<AudioTrackInterface>): AudioTrackInterface[] {
        //tracks.push({ lang: 'en', codec: 'dolby:ec-3' }, { lang: 'klingon', codec: 'ac-3' }) testing non web audio codecs filtering. 
        return tracks.map((item: StrAnyDict, index: number): AudioTrackInterface => ({
            index: index,
            id: !isNaN(item[map.id]) ? item[map.id].toString() : !isNaN(item.id) ? item.id.toString() : '',
            type: item[map.type] || item.type || '',
            lang: item[map.lang] || item.lang || '',
            codec: item[map.codec] || item.codec || '',
            label: item[map.label] || item.label || ''
        })).filter((item: AudioTrackInterface) => {
            const c = item.codec;
            return c === '' || c.indexOf('mp4a') !== -1;
        });
    }

    protected normalizeQuality(item: any, index: number): QualityInterface {
        return {
            index,
            bitrate: item.bitrate,
            height: item.height,
            width: item.width,
            codec: item.codec || item.codecs
        };
    }

    protected mergeStreamingConfigs(base: any, override: any,) {

        if (!Util.isEmpty(override?.[this.pType]?.config)) {

            return Util.merge(base, override[this.pType].config);
        }

        return base;
    }
}
