
export enum Os {
    /** */
    OSX = 'OS X',

    /** */
    WINDOWS = 'Windows',

    /** */
    WINDOWS7 = 'Windows 7',

    /** */
    WINDOWS8 = 'Windows 8',

    /** */
    WINDOWS10 = 'Windows 10',

    /** */
    WINDOWS_VISTA = 'Windows Vista',

    /** */
    WINDOWS_PHONE = 'Windows Phone',

    /** */
    IOS = 'iOS',

    /** */
    ANDROID = 'Android',

    /** */
    CHROMIUM = 'Chromium',

    /** LG Smart Tv. Support for 3.5 and above only. */
    WEBOS = 'Web0S',

    /** Vizio SmartTV OS. */
    SMARTCAST = 'SmartCast',

    /** Samsung Tizen OS. */
    TIZEN = 'Tizen',

    /** */
    UNKNOWN = 'Unknown'
}
