import { ModelName } from '../enum/ModelName';
import { PerformanceMode } from '../enum/PerformanceMode';
import { ProxyName } from '../enum/ProxyName';
import { ServiceName } from '../enum/ServiceName';
import { PerformanceSettingsInterface, SystemServiceInterface } from '../iface';
import { PlayerOptionsInterface } from '../iface/PlayerOptionsInterface';
import { Proxy } from '../mvc/Proxy';
import { ModelCollectionProxy } from './ModelCollectionProxy';


export class PerformanceProxy extends Proxy implements PerformanceSettingsInterface {

    private pMode: PerformanceMode;

    constructor(name: string, data?: any) {
        super(name, data);
    }

    onRegister() {
        const mcp = <ModelCollectionProxy>this.facade.retrieveProxy(ProxyName.ModelCollectionProxy);
        const options = <PlayerOptionsInterface>mcp.getModel(ModelName.PlayerOptions);

        this.pMode = (options.performanceMode == PerformanceMode.AUTO) ? this.calculatePerformanceMode() : options.performanceMode;
    }

    private calculatePerformanceMode(): PerformanceMode {
        const sys = <SystemServiceInterface>this.facade.retrieveService(ServiceName.System);
        switch (true) {
            case sys.isTv:
            case sys.isEmbedded:
                return PerformanceMode.TV;

            case sys.isMobile:
                return PerformanceMode.MOBILE;

            default:
                return PerformanceMode.DESKTOP;
        }
    }

    get mode(): PerformanceMode {
        return this.pMode;
    }

    get forwardBufferLength(): number {
        switch (this.pMode) {
            case PerformanceMode.MOBILE:
            case PerformanceMode.TV:
                return 20;

            default:
                return null;
        }
    }

    get backBufferLength(): number {
        switch (this.pMode) {
            case PerformanceMode.MOBILE:
            case PerformanceMode.TV:
                return 30;

            default:
                return 60;
        }
    }

    get topQualityForwardBufferLength(): number {
        switch (this.pMode) {
            case PerformanceMode.MOBILE:
            case PerformanceMode.TV:
                return 60;

            default:
                return null;
        }
    }

}
