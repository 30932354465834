import { LogLevel } from '../enum/LogLevel';
import { LogEventInterface } from '../iface/LogEventInterface';
import { LoggerInterface } from "../iface/LoggerInterface";
import { LoggerOptions } from "../iface/LoggerOptions";
import { StrAnyDict } from "../iface/StrAnyDict";
import { Emitter } from './Emitter';
import { Util } from './Util';


const Levels = {
    [LogLevel.OFF]: 0,
    [LogLevel.ERROR]: 100,
    [LogLevel.WARN]: 200,
    [LogLevel.INFO]: 300,
    [LogLevel.DEBUG]: 400,
}

export class Logger extends Emitter implements LoggerInterface {

    static LOG_EVENT: string = 'logEvent';

    static error(e: any): void {
        console && console.error(e);
    }

    static warn(m: any): void {
        console && console.warn(m);
    }

    static log(m: any): void {
        console && console.log(m);
    }

    protected opts: LoggerOptions;
    protected tzero: number;

    private useConsole: boolean = false;
    private pLogLevel: LogLevel;
    private readonly id: string;

    constructor(opts: LoggerOptions) {
        super(opts);

        this.useConsole = Util.isObject(console);
        this.id = opts.id || Util.uid8();
        this.tzero = Date.now();
        this.logLevel = Util.isEmpty(this.opts.logLevel) ? LogLevel.OFF : this.opts.logLevel;
    }

    get logLevel(): LogLevel {
        return this.pLogLevel;
    }

    set logLevel(level: LogLevel) {
        this.pLogLevel = level;
    }

    assert(expression: boolean | any, label?: string): void {
        if (!expression) {
            this.error(`Assertion failed! - "${label || ''}"`);
        }
    }

    log(logLevel: LogLevel, ...items: any[]): void {
        for (let i = 0, n = items.length; i < n; i++) {
            this.emit(Logger.LOG_EVENT, this.assembleEvent(items[i], logLevel));
        }

        if (!this.shouldLog(logLevel)) { return; }

        items.unshift(this.getStamp());
        console.log.apply(console, items);
    }

    dir(logLevel: LogLevel, obj: StrAnyDict, label: string): void {
        const lbl = label || 'Unnamed Object';
        this.emit(Logger.LOG_EVENT, this.assembleEvent(obj, logLevel, lbl));

        if (!this.shouldLog(logLevel)) { return; }

        console.log(this.getStamp() + ` Object: ${lbl}`);
        console.dir(obj);
    }

    error(msg: string): void {
        this.emit(Logger.LOG_EVENT, this.assembleEvent(msg, LogLevel.ERROR));

        if (!this.shouldLog(LogLevel.ERROR)) { return; }

        console.error(this.getStamp() + msg);
    }

    warn(msg: string): void {
        this.emit(Logger.LOG_EVENT, this.assembleEvent(msg, LogLevel.WARN));

        if (!this.shouldLog(LogLevel.WARN)) { return; }

        console.warn(this.getStamp() + msg);
    }

    private assembleEvent(item: any, level: LogLevel, label: string = null): LogEventInterface {
        return {
            item: item,
            id: this.id,
            level: level,
            label: label
        }
    }

    private shouldLog(level: LogLevel) {
        return this.useConsole && Levels[this.pLogLevel] >= Levels[level];
    }

    private getStamp() {
        return `[${this.id + ']  ' + Util.msToHms(Date.now() - this.tzero)} > `;
    }
}
