import { AppResources } from '../app/AppResources';
import { PluginServices } from '../app/PluginServices';
import { Util } from '../core/Util';
import { ScriptLoader } from '../dataservice/ScriptLoader';
import { LogLevel } from '../enum/LogLevel';
import { MediatorName } from '../enum/MediatorName';
import { ModelName } from '../enum/ModelName';
import { NotificationName } from '../enum/NotificationName';
import { PluginPriority } from '../enum/PluginPriority';
import { ProxyName } from '../enum/ProxyName';
import { ServiceName } from '../enum/ServiceName';
import { BuildInfoServiceInterface, LoadPluginsNotificationInterface, NotificationInterface, PlayerDomProxyInterface, SystemServiceInterface, UiMediatorInterface } from '../iface';
import { EventInterface } from '../iface/EventInterface';
import { PlayerOptionsInterface } from '../iface/PlayerOptionsInterface';
import { PluginConfigInterface } from '../iface/PluginConfigInterface';
import { PresentationStateInterface } from '../iface/PresentationStateInterface';
import { StrAnyDict } from '../iface/StrAnyDict';
import { LocalizationProxy } from '../model/LocalizationProxy';
import { Utils } from '../util/Utils';
import { AppMediator } from './AppMediator';
import { LogAwareMediator } from './LogAwareMediator';


interface PrioritizedPluginsInterface {
    high: PluginConfigInterface[];
    low: PluginConfigInterface[];
}

export class PluginMediator extends LogAwareMediator {
    private plugins: StrAnyDict = {};
    private pendingLoads: PluginConfigInterface[] = null;
    private services: PluginServices = null;
    private diagnosticPlugin: any = null;
    private diagnosticPluginVisible: boolean = false;

    constructor(name: string) {
        super(name);
    }

    onRemove(): void {
        if (this.plugins) {
            for (const q in this.plugins) {
                this.killPlugin(q);
            }
        }
        this.plugins = null;
        super.onRemove();
    }

    removePlugin(name: string): void {
        this.killPlugin(name);
    }

    getPlugin(name: string): any {
        return this.plugins[name] || null;
    }

    toggleDiagnosticPlugin() {
        if (!this.diagnosticPlugin) {
            this.createDiagnosticPlugin();
        }
        else {
            this.diagnosticPluginVisible = !this.diagnosticPluginVisible;
            this.diagnosticPluginVisible ? this.diagnosticPlugin.show() : this.diagnosticPlugin.hide();
        }
    }

    listNotificationInterests(): string[] {
        return [
            NotificationName.LOAD_PLUGINS,
            NotificationName.AD_BREAK_START,
            NotificationName.CONTENT_START,
            NotificationName.REMOVE_PLUGIN
        ];
    }

    handleNotification(notification: NotificationInterface): void {
        switch (notification.name) {

            case NotificationName.LOAD_PLUGINS:
                this.loadPlugins(<LoadPluginsNotificationInterface>notification.body);
                break;

            case NotificationName.REMOVE_PLUGIN:
                this.removePlugin(notification.body.name);
                break;

            // intentional fall-thru
            case NotificationName.AD_BREAK_START:
            case NotificationName.CONTENT_START:
                this.pendingLoads !== null && this.loadPendingPlugins();
                break;
        }
    }

    private createDiagnosticPlugin(): void {
        const dCfg = AppResources.nativePluginConfig.diagnostic;

        this.createPluginServices();

        dCfg && this.loadScripts([dCfg], null, () => {
            this.diagnosticPlugin = this.plugins[dCfg.name];
            this.diagnosticPluginVisible = true;
        });
    }

    private createPluginServices(): void {
        if (this.services) { return; }
        const am = <AppMediator>this.facade.retrieveMediator(MediatorName.APPLICATION),
            uim = <UiMediatorInterface>this.facade.retrieveMediator(MediatorName.UI),
            pOptsMdl = <PlayerOptionsInterface>this.getModel(ModelName.PlayerOptions);

        this.services = new PluginServices({
            viewController: uim ? uim.viewController : null,
            buildInfo: <BuildInfoServiceInterface>this.facade.retrieveService(ServiceName.BuildInfo),
            system: <SystemServiceInterface>this.facade.retrieveService(ServiceName.System),
            domProxy: <PlayerDomProxyInterface>this.facade.retrieveProxy(ProxyName.PlayerDomProxy),
            localization: (<LocalizationProxy>this.facade.retrieveProxy(ProxyName.LocalizationProxy)).getApi(),
            playerOptions: pOptsMdl.model.data,
            dispatch: (data: StrAnyDict) => {
                am.dispatchPluginEvent(data);
            }
        });
    }

    private createPlugin(cfg: PluginConfigInterface): void {
        const am = <AppMediator>this.facade.retrieveMediator(MediatorName.APPLICATION),
            c: any = cfg.classRef ? cfg.classRef : Utils.classFromQualifiedName(cfg.qualifiedClassName),
            opts = cfg.options || {}

        if (cfg.name) {
            opts.name = cfg.name;
        }
        if (c) {
            let p;
            if (Util.isFunction(c.onRegister)) {
                p = c;
            }
            else {
                p = new c();
            }
            this.plugins[cfg.name] = p;
            p.onRegister !== undefined && p.onRegister(am.getAppApi(), opts, this.services);
            this.log(LogLevel.INFO, `Created plugin '${cfg.name}'`);
        }
    }

    private killPlugin(name: string): void {
        if (!this.plugins[name]) {
            return;
        }
        Util.isFunction(this.plugins[name].destroy) && this.plugins[name].destroy();
        delete this.plugins[name];
    }

    private loadPendingPlugins(): void {
        this.loadScripts(this.pendingLoads.slice());
        this.pendingLoads = null;
    }

    private loadPlugins(obj: LoadPluginsNotificationInterface): void {
        const playerOpts = <PlayerOptionsInterface>this.getModel(ModelName.PlayerOptions),
            plugins = <PluginConfigInterface[]>obj.plugins || <PluginConfigInterface[]>playerOpts.plugins,
            data: PrioritizedPluginsInterface = this.processCfg(plugins),
            ps = <PresentationStateInterface>this.getModel(ModelName.PresentationState),
            useP = !ps.started,
            next = (!!obj.nextNotification && obj.nextNotification) || null,
            callback = (!!obj.callback && obj.callback) || null;

        if (!data.high.length && !data.low.length) {
            next && this.sendNotification(next);
            callback && callback();
            return;
        }

        this.createPluginServices();

        let loading = false;

        if (useP) {
            if (data.low.length) {
                this.pendingLoads = data.low;
            }

            if (data.high.length) {
                loading = true;
                this.loadScripts(data.high, next, callback);
            }
        }
        else {
            const c: PluginConfigInterface[] = data.low.concat(data.high);
            loading = true;
            this.loadScripts(c, next, callback);
        }

        if (!loading) {
            next && this.sendNotification(next);
            callback && callback();
        }
    }

    private loadScripts(pc: PluginConfigInterface[], nextNotification: NotificationName = null, callback: () => void = null): void {
        const map: StrAnyDict = {},
            urls: string[] = [];

        let i = pc.length;
        while (i--) {
            const cfg = pc[i];
            if (!cfg.loaded) {
                map[cfg.url] = i;
                urls.push(cfg.url);
            }
            else {
                this.createPlugin(cfg);
            }
        }

        if (!urls.length) {
            nextNotification && this.sendNotification(nextNotification);
            callback && callback();

            return;
        }

        const playerOpts = <PlayerOptionsInterface>this.getModel(ModelName.PlayerOptions);

        new ScriptLoader({
            urls: urls,
            errorRecovery: playerOpts.networkErrorRecovery,
            onComplete: (e: EventInterface) => {
                if (!e.data.error && e.data.url) {
                    map[e.data.url] !== undefined && this.createPlugin(pc[map[e.data.url]]);
                    delete map[e.data.url];
                }
                else {
                    delete map[e.data.url];
                    this.log(LogLevel.ERROR, AppResources.messages.PLUGIN_LOAD_ERROR)
                }
                if (Util.isEmpty(map)) {
                    nextNotification && this.sendNotification(nextNotification);
                    callback && callback();
                }
            }
        });
    }

    private processCfg(cfg: PluginConfigInterface[]): PrioritizedPluginsInterface {
        if (!cfg) {
            return { high: [], low: [] };
        }
        const ps = <PresentationStateInterface>this.getModel(ModelName.PresentationState),
            useP = !ps.started,
            hi: PluginConfigInterface[] = [],
            lo: PluginConfigInterface[] = [],
            ret = { high: hi, low: lo };

        let i = cfg.length;
        while (i--) {
            const pc = <PluginConfigInterface>cfg[i],
                loadReq = !Util.isEmpty(pc.url) && !Util.isEmpty(pc.qualifiedClassName),
                hasRef = !Util.isEmpty(pc.classRef);

            if (loadReq || hasRef) {
                useP && pc.priority === PluginPriority.HIGH ? hi.unshift(pc) : lo.unshift(pc);
                pc.loaded = hasRef;
            }
        }

        return ret;
    }
}
