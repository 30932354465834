import { request } from '../../core/Request';
import { Util } from "../../core/Util";
import { ThumbnailCueInterface } from "../../iface/ThumbnailCueInterface";
import { WebVTTParser } from "../util/WebVTTParser";

export class ThumbnailTrackSurface {

  url: string;
  cues: ThumbnailCueInterface[];

  constructor(url: string, cues: ThumbnailCueInterface[]) {
    this.url = url;
    this.cues = cues;
  }

  getCueAt(time: number) {
    return Util.find(this.cues, cue => cue.startTime <= time && cue.endTime >= time);
  }

  renderCueAt(time: number, container: HTMLElement) {
    ThumbnailTrackSurface.renderThumbnail(this.getCueAt(time), container);
  }

  static create(url: string) {
    const a = document.createElement("a");
    a.href = url;
    const absolute = a.protocol + "//" + a.hostname;
    const relative = absolute + a.pathname.split("/").slice(0, -1).join("/") + "/";

    return request({ url })
      .then((response) => {
        const cues = WebVTTParser.parse(response);
        cues.forEach((cue) => {
          const [file, params] = cue.text.split("#");
          let src = file;
          if (!/^(https?:)?\/\//.test(src)) {
            if (/^\//.test(src)) {
              src = absolute + src;
            } else {
              src = relative + src;
            }
          }
          const kv = params.split("=");
          const keys = kv[0];
          const values = kv[1].split(",");
          const style: Record<string, string> = {
            backgroundImage: `url(${src})`

          };
          const data: Record<string, any> = { url: src };

          Util.forEach(keys, (key, i) => {
            const value = values[i];

            switch (key) {
              case "w":
                data.width = parseInt(value);
                style.width = `${value}px`;
                break;

              case "h":
                data.height = parseInt(value);
                style.height = `${value}px`;
                break;

              case "x":
                data.x = parseInt(value);
                style.backgroundPositionX = `-${value}px`;
                break;

              case "y":
                data.y = parseInt(value);
                style.backgroundPositionY = `-${value}px`;
            }
          });

          cue.style = style;
          cue.data = data;
        });

        return new ThumbnailTrackSurface(url, cues);
      });
  }

  static renderThumbnail(cue: ThumbnailCueInterface, container: HTMLElement) {
    if (cue?.style && container?.style) {
      Util.assign(container.style, cue.style);
    }
  }
}
