import { VideoSurfaceInterface } from '../../iface';
import { AudioTrackInterface } from '../../iface/AudioTrackInterface';
import { EventInterface } from '../../iface/EventInterface';
import { LiveStreamInfoInterface } from '../../iface/LiveStreamInfoInterface';
import { LoggerInterface } from '../../iface/LoggerInterface';
import { PlaybackAdapterConfigInterface } from '../../iface/PlaybackAdapterConfigInterface';
import { QualityInterface } from '../../iface/QualityInterface';
import { StrAnyDict } from '../../iface/StrAnyDict';
import { PlaybackAdapterEvents } from '../enum/PlaybackAdapterEvents';
import { PlaybackAdapterType } from '../enum/PlaybackAdapterType';
import { WebMafVideoSurface } from '../surface/WebMafVideoSurface';
import { BasePlaybackAdapter } from './BasePlaybackAdapter';


export class PlayStationAdapter extends BasePlaybackAdapter {

    protected pType = PlaybackAdapterType.PLAY_STATION;

    private psEventMap: StrAnyDict = [
        {
            type: PlaybackAdapterEvents.MANIFEST_PARSED,
            callback: (e: EventInterface) => this.onManifestParsed(e)
        },
        {
            type: PlaybackAdapterEvents.ABR_QUALITY_LOADED,
            callback: (e: EventInterface) => this.onBubble(e)
        },
        {
            type: PlaybackAdapterEvents.AUDIO_TRACK_UPDATED,
            callback: (e: EventInterface) => this.onBubble(e)
        }
    ]

    constructor(videoSurface: VideoSurfaceInterface, config: PlaybackAdapterConfigInterface, logger: LoggerInterface) {
        super(videoSurface, config, logger);
        this.log(this.pType, this.videoSurface.video.version, this.videoSurface.video.TTYLevel, this.videoSurface.metrics);
    }

    ////////////////////
    //Public Methods
    ////////////////////
    initialize(): void {
        super.initialize();

        //set options
        const opts = this.playback;
        const surface: WebMafVideoSurface = <WebMafVideoSurface>this.videoSurface;

        !isNaN(opts.abr.startBitrate) && (surface.startingBitrate = opts.abr.startBitrate);
        !isNaN(opts.abr.minBitrate) && (surface.minBitrate = opts.abr.minBitrate);
        !isNaN(opts.abr.maxBitrate) && (surface.maxBitrate = opts.abr.maxBitrate);
        !isNaN(opts.startTime) && (surface.startTime = opts.startTime);

        this.addEvents(this.videoSurface, this.psEventMap);
    }

    protected loadMediaUrl(): Promise<void> {
        return Promise.resolve().then(() => {
            const drm = this.config.resource.location.drm;
            this.videoSurface.src = '{"command":"load", "contentUri":"' + this.mediaUrl + '", "licenseUri":"' + drm.playready.url + '","customData": "","sourceType":' + 0 + '}';
        });
    }

    destroy(): Promise<void> {
        this.removeEvents(this.videoSurface, this.psEventMap);
        this.videoSurface.destroy();
        return super.destroy();
    }

    play(): void {
        this.videoSurface.play();
    }

    pause(): void {
        this.videoSurface.pause();
    }

    seek(position: number): void {
        this.videoSurface.seek(position);
    }
    ////////////////////
    //Accessors
    ////////////////////

    set audioTrack(track: AudioTrackInterface) {
        (<WebMafVideoSurface>this.videoSurface).audioTrack = track;
    }

    get type(): PlaybackAdapterType {
        return this.pType;
    }

    get time(): number {
        return this.videoSurface.time;
    }

    get duration(): number {
        return this.videoSurface.duration;
    }

    get droppedVideoFrames(): number {
        return this.videoSurface.metrics.droppedFrame; //TODO check name
    }
    get buffering(): boolean {
        return this.videoSurface.buffering;
    }

    set currentIndex(index: number) {
        //setRepresentationEnable
        //setRepresentationDisable
    }
    // get currentIndex(): number {        
    // }

    get currentBitrate(): number {
        return this.videoSurface.metrics.bitrate; //TODO check this works
    }



    set autoQualitySwitching(value: boolean) {
        //setAdaptiveStreamingParameters
        //setFixVideoRepresentations
    }
    // get autoBitrateSwitchEnabled(): boolean {

    // }

    set minBitrate(value: number) {
        //setAdaptiveStreamingParameters
        //setFixVideoRepresentations
    }
    // get minBitrateAllowed(): number {

    // }

    set maxBitrate(value: number) {
        //setAdaptiveStreamingParameters
        //setFixVideoRepresentations
    }
    // get maxBitrateAllowed(): number {    
    // }

    get manifestQualities(): QualityInterface[] {
        return (<WebMafVideoSurface>this.videoSurface).masterBitrateProfile.map(this.normalizeQuality);
    }

    get liveStreamInfo(): LiveStreamInfoInterface {
        //getSeekWindow!
        return this.liveStreamInfoVO;
    }

    private onManifestParsed(e: EventInterface) {
        this.emit(PlaybackAdapterEvents.MANIFEST_PARSED, { profile: this.manifestQualities });
    }

    private onBubble(e: EventInterface) {
        this.emit(e.type, e.data);
    }
}             
