import { AppResources } from '../app/AppResources';
import { Util } from '../core/Util';
import { LogLevel } from '../enum/LogLevel';
import { MediatorName } from '../enum/MediatorName';
import { NotificationName } from '../enum/NotificationName';
import { ProxyName } from '../enum/ProxyName';
import { NotificationInterface, PlaybackAdapterInterface, PresentationMediatorInterface } from '../iface';
import { ContentPlaybackStateProxy } from '../model/ContentPlaybackStateProxy';
import { Playback } from '../playback/enum/Playback';
import { Utils } from '../util/Utils';
import { LogAwareSimpleCommand } from './LogAwareSimpleCommand';

export class AbrSwitchCommand extends LogAwareSimpleCommand {

    execute(notification: NotificationInterface) {

        const presoMediator = <PresentationMediatorInterface>this.facade.retrieveMediator(MediatorName.PRESENTATION_MEDIATOR);
        const stateProxy = <ContentPlaybackStateProxy>this.facade.retrieveProxy(ProxyName.ContentPlaybackStateProxy);
        const adapter: PlaybackAdapterInterface = presoMediator.adapter;
        const value: any = notification.body.value;

        //Support SBR streams VTG-2024
        if (!stateProxy.isAbrSwitchingAvailable) {
            stateProxy.qualitySwitchingMode = Playback.ABR_SWITCHING_MODE_UNAVAILABLE;
            return;
        }

        // All ABR addressed directly via preso mediator's adapter
        switch (notification.name) {

            case NotificationName.SWITCH_BITRATE:
                const idx = Utils.getIndexForBitrate(stateProxy.model.manifestQualities, value, false);
                this.switchToIndex(idx, stateProxy, adapter);
                break;

            case NotificationName.SWITCH_QUALITY_CATEGORY:
                stateProxy.userQualityCategory = value;
                this.switchQualityCategory(stateProxy, value, adapter)
                break;

            case NotificationName.AUTO_QUALITY_SWITCHING:
                adapter.autoQualitySwitching = value;
                stateProxy.qualitySwitchingMode = this.getAbrMode(value);
                break;

            case NotificationName.MIN_BITRATE:
                adapter.minBitrate = value;
                break;

            case NotificationName.MAX_BITRATE:
                adapter.maxBitrate = value;
                break;
        }
    }

    private switchToIndex(index: number, stateProxy: ContentPlaybackStateProxy, adapter: PlaybackAdapterInterface) {
        //TODO Need answer from PM. do we lift max or min bitrate set by developer when user manually switches?
        // Do we lift capping, NO! Need to setup time to discuss with manual switching users like cbs Ent.

        const minIndex = stateProxy.minIndex;
        const maxIndex = stateProxy.maxIndex;
        if (Util.inRange(index, minIndex, maxIndex)) {
            adapter.autoQualitySwitching = false;
            stateProxy.qualitySwitchingMode = this.getAbrMode(false);
            adapter.currentIndex = index;
        }
        else {
            this.logger.log(LogLevel.WARN, index >= maxIndex ?
                AppResources.messages.ABR_MANUAL_SWITCHING_REACHED_UPPER_BOUNDS :
                AppResources.messages.ABR_MANUAL_SWITCHING_REACHED_LOWER_BOUNDS);
        }
    }

    private switchQualityCategory(stateProxy: ContentPlaybackStateProxy, category: string, adapter: PlaybackAdapterInterface) {
        const qia = stateProxy.model.manifestQualities;
        const { minBitrate, maxBitrate, minIndex, maxIndex } = Utils.getMinAndMaxValuesForCategory(qia, category);

        if (minIndex === maxIndex) {
            this.switchToIndex(minIndex, stateProxy, adapter);
        }
        else {
            adapter.minBitrate = minBitrate;
            adapter.maxBitrate = maxBitrate;
        }
    }

    private getAbrMode(enabled: boolean): string {
        return enabled ? Playback.ABR_SWITCHING_MODE_AUTO : Playback.ABR_SWITCHING_MODE_MANUAL;
    }
}
