import { Util } from '../core/Util';
import { Env } from '../enum/Env';
import { AdPlaybackContextInterface } from '../iface';
import { ResourceConfigurationInterface } from '../iface/ResourceConfigurationInterface';
import { StrAnyDict } from '../iface/StrAnyDict';
import { AdParamAssembler } from './AdParamAssembler';

/*
adTagParameters: {
    description_url: string;
    sz: string;
    url: string;
    iu: string;
    ad_rule: string;
    ciu_szs: string;
    pp: string;
    cmsid: string;
    vid: string;
    cust_params: object,
    hl: string;
    msid: string;
    an: string;
    nofb: string;
    ppid: string;
    tfcd: string;
    npa:  string;
}
*/

export class ImaAdCallAssembler extends AdParamAssembler {

    static adCallTemplate: string = 'http://pubads.g.doubleclick.net/gampad/ads?';

    constructor() {
        super();
    }

    assembleAdCall(rc: ResourceConfigurationInterface, contextInfo: AdPlaybackContextInterface): string {
        const baseUrl = ImaAdCallAssembler.adCallTemplate,
            stdParamsObj = this.assembleStdParams(rc, contextInfo),
            obj: StrAnyDict = Util.assign({}, stdParamsObj);

        let custParamsStr = this.queryStrFromObj(rc.ad.adTagParameters.cust_params);
        obj.cust_params = custParamsStr;

        // vpaid is turned on for development
        (contextInfo.buildEnv === Env.DEV) && (obj.pp = 'vpaid_js');

        const qs = this.queryStrFromObj(obj, [
            "description_url", "url", 'iu', "partner", "cust_params"
        ]);

        return baseUrl + qs;
    }

}
