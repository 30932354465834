import { NotificationName } from '../enum/NotificationName';
import { AbstractKeyCommandMediator } from './AbstractKeyCommandMediator';
import { AppResources } from '../app/AppResources';

export class UserKeyCommandMediator extends AbstractKeyCommandMediator {

    constructor(name: string) {
        super(name);
    }

    hKeyUp(e: KeyboardEvent): void {
        const def = this.getCommand(e);

        if (def) {
            this.sendNotification(NotificationName.USER_KEY_COMMAND, {
                action: def.action
            });
        }
    }

    hKeyDown(e: KeyboardEvent) {
        // no impl
    }

    onRegister(): void {
        super.onRegister();

        const ucd = AppResources.userKeyCmdDefs;

        let i = ucd.length;
        while (i--) {
            this.registerCommandDef(ucd[i]);
        }
    }
}
