import { Logger } from '../core/Logger';
import { Util } from '../core/Util';
import { LogLevel } from '../enum/LogLevel';
import { MediatorName } from '../enum/MediatorName';
import { ModelName } from '../enum/ModelName';
import { NativePlugin } from '../enum/NativePlugin';
import { NotificationName } from '../enum/NotificationName';
import { NotificationType } from '../enum/NotificationType';
import { PlaybackState } from '../enum/PlaybackState';
import { PlayerEvent } from '../enum/PlayerEvent';
import { ProxyName } from '../enum/ProxyName';
import { ServiceName } from '../enum/ServiceName';
import { StreamType } from '../enum/StreamType';
import { ApplicationOptionsInterface, BuildInfoServiceInterface, NotificationInterface, PlayerDomProxyInterface, SystemServiceInterface, VideoPlayerApplicationOptions } from '../iface';
import { AdCuePointInterface } from '../iface/AdCuePointInterface';
import { AudioTrackInterface } from '../iface/AudioTrackInterface';
import { AudioTracksInterface } from '../iface/AudioTracksInterface';
import { AutoplayInfoInterface } from '../iface/AutoplayInfoInterface';
import { DimensionsInterface } from '../iface/DimensionsInterface';
import { EventHandler } from '../iface/EventHandler';
import { EventInterface } from '../iface/EventInterface';
import { LiveStreamInfoInterface } from '../iface/LiveStreamInfoInterface';
import { LocalizationInterface } from '../iface/LocalizationInterface';
import { ModelSnapshotInterface } from '../iface/ModelSnapshotInterface';
import { PlayerOptionsInterface } from '../iface/PlayerOptionsInterface';
import { PlaylistInterface } from '../iface/PlaylistInterface';
import { PluginConfigInterface } from '../iface/PluginConfigInterface';
import { PresentationStateInterface } from '../iface/PresentationStateInterface';
import { QualityInfoInterface } from '../iface/QualityInfoInterface';
import { ResourceConfigurationInterface } from '../iface/ResourceConfigurationInterface';
import { StrAnyDict } from '../iface/StrAnyDict';
import { TextTrackInterface } from '../iface/TextTrackInterface';
import { TextTracksInterface } from '../iface/TextTracksInterface';
import { ThumbnailCueInterface } from '../iface/ThumbnailCueInterface';
import { VideoPlayerInterface } from '../iface/VideoPlayerInterface';
import { ContentPlaybackStateProxy } from '../model/ContentPlaybackStateProxy';
import { LocalizationProxy } from '../model/LocalizationProxy';
import { PlayerDomProxy } from '../model/PlayerDomProxy';
import { PlaylistProxy } from '../model/PlaylistProxy';
import { TextTrackProxy } from '../model/TextTrackProxy';
import { Playback } from '../playback/enum/Playback';
import { AbstractApplication } from './AbstractApplication';
import { apiAccessor, apiCollection, apiMethod } from './ApiDecorators';
import { AppResources } from './AppResources';
import { command_map } from './command_map';
import { Deprecated } from './Deprecated';


export class VideoPlayer extends AbstractApplication implements VideoPlayerInterface {

    /**
     * @ignore
     */
    apiMethods!: StrAnyDict;
    /**
     * @ignore
     */
    apiAccessors!: StrAnyDict;

    private pContentPlaybackStateProxy!: ContentPlaybackStateProxy;
    private pIsReady: boolean = false;

    /**
    * @hidden
    */
    constructor(options: VideoPlayerApplicationOptions) {
        super(<ApplicationOptionsInterface>Util.assign({
            commandMap: command_map,
            id: options.playerOptions.id || null
        }, options));

        this.init();
    }

    initialize(): Promise<VideoPlayerInterface> {
        const options = (this.opts.playerOptions || {}) as PlayerOptionsInterface;
        const plugins = (options.plugins || []) as PluginConfigInterface[];
        const api = this.getApi();
        const note = { name: NotificationName.CHANGE_LANGUAGE, body: { value: options.language }, type: NotificationType.INTERNAL };

        this.addNativePlugins(plugins, options);

        return api.registerPlugins(plugins)
            .then(() => this.sendAsyncNotification(note, [PlayerEvent.LANGUAGE_CHANGE]))
            .then(() => {
                this.localization.registerLocalizationData(options.localization);

                this.pIsReady = true;
                this.sendNotification(NotificationName.READY, api);

                return api;
            })
            .catch(error => {
                Logger.error(error);

                throw error;
            });
    }

    /**
     * @ignore
     */
    destroy(): Promise<void> {
        const destroy = super.destroy.bind(this);
        return this.killCurrentResource()
            .then(() => {
                this.appMediator && this.appMediator.prepForPlayerRemoval();
                this.pContentPlaybackStateProxy = null;
                this.apiAccessors = null;
                this.apiMethods = null;
                destroy();
            })
            .catch(e => { Logger.error(e); });
    }

    /**
     * @ignore
     */
    getApi(): VideoPlayerInterface {
        const obj: StrAnyDict = apiCollection({}, this);
        Deprecated.decorateEventType(obj);

        return <VideoPlayerInterface>obj;
    }

    /**
    * @ignore
    */
    killCurrentResource(): Promise<void> {
        return this.appMediator.killCurrentResource();
    }

    //////////////////////
    // BEGIN PUBLIC API //
    //////////////////////

    ////////////////
    // Inherited
    ////////////////
    @apiMethod()
    hasListenerFor(name: string): boolean {
        return super.hasListenerFor(Deprecated.checkEventName(name));
    }

    @apiMethod()
    on(name: string, func: EventHandler): void {
        super.on(Deprecated.checkEventName(name), func);
    }

    @apiMethod()
    once(name: string, func: EventHandler): void {
        super.once(Deprecated.checkEventName(name), func);
    }

    @apiMethod()
    off(name: string, func?: EventHandler): void {
        super.off(Deprecated.checkEventName(name), func);
    }

    ////////////////
    // Accessors
    ////////////////
    @apiAccessor()
    get isReady(): boolean {
        return this.pIsReady;
    }

    @apiAccessor()
    get id(): string {
        return this.appId;
    }

    @apiAccessor()
    get dimensions(): DimensionsInterface {
        return this.appMediator.getDimensions();
    }

    @apiAccessor()
    get muted(): boolean {
        return this.appMediator.getMuteState();
    }
    set muted(value: boolean) {
        const note = value ? NotificationName.MUTE : NotificationName.UNMUTE;
        this.transmitExtRequest(note);
    }

    @apiAccessor()
    get volume(): number {
        return this.appMediator.getVolume();
    }
    set volume(value: number) {
        this.transmitExtRequest(NotificationName.VOLUME_CHANGE, { value: value });
    }

    @apiAccessor()
    get language(): string {
        return this.localization.language;
    }
    set language(value: string) {
        this.transmitExtRequest(NotificationName.CHANGE_LANGUAGE, { value });
    }

    @apiAccessor()
    get contentTime(): number {
        return this.contentPlaybackStateProxy ? this.contentPlaybackStateProxy.model.time : NaN;
    }

    @apiAccessor()
    get contentDuration(): number {
        return this.contentPlaybackStateProxy ? this.contentPlaybackStateProxy.model.duration : NaN;
    }

    @apiAccessor()
    get streamTime(): number {
        const presoModel = <PresentationStateInterface>this.modelCollectionProxy.getModel(ModelName.PresentationState);
        return presoModel.streamTime;
    }

    @apiAccessor()
    get streamDuration(): number {
        const presoModel = <PresentationStateInterface>this.modelCollectionProxy.getModel(ModelName.PresentationState);
        return presoModel.streamDuration;
    }

    @apiAccessor()
    get playbackState(): PlaybackState {
        return this.contentPlaybackStateProxy ? this.contentPlaybackStateProxy.model.state : null;
    }

    @apiAccessor()
    get playlist(): PlaylistInterface {
        const proxy = <PlaylistProxy>this.facade.retrieveProxy(ProxyName.Playlist);

        return proxy ? proxy.getApi() : null;
    }

    @apiAccessor()
    get autoQualitySwitching(): boolean {
        return this.contentPlaybackStateProxy ? this.contentPlaybackStateProxy.qualitySwitchingMode === Playback.ABR_SWITCHING_MODE_AUTO : false;
    }
    set autoQualitySwitching(value: boolean) {
        if (this.contentPlaybackStateProxy) {
            this.transmitExtRequest(NotificationName.AUTO_QUALITY_SWITCHING, { value: value });
        }
        else {
            this.log(AppResources.messages.CONTENT_PLAYBACK_SETTING_IGNORED);
        }
    }

    @apiAccessor()
    get qualityInfo(): QualityInfoInterface {
        return this.contentPlaybackStateProxy ? this.contentPlaybackStateProxy.qualityInfo : null;
    }

    @apiAccessor()
    get bitrate(): number {
        const quality = this.qualityInfo.quality || { bitrate: NaN };
        const bitrate = (quality.bitrate != null) ? quality.bitrate : NaN;
        return bitrate;
    }
    set bitrate(bitrate: number) {
        if (this.contentPlaybackStateProxy) {
            this.transmitExtRequest(NotificationName.SWITCH_BITRATE, { value: bitrate });
        }
        else {
            this.log(AppResources.messages.CONTENT_PLAYBACK_SETTING_IGNORED);
        }
    }

    @apiAccessor()
    get qualityCategory(): string {
        return this.contentPlaybackStateProxy ? this.contentPlaybackStateProxy.userQualityCategory : null;
    }
    set qualityCategory(category: string) {
        if (this.contentPlaybackStateProxy) {
            this.transmitExtRequest(NotificationName.SWITCH_QUALITY_CATEGORY, { value: category });
        }
        else {
            this.log(AppResources.messages.CONTENT_PLAYBACK_SETTING_IGNORED);
        }
    }

    @apiAccessor()
    get minBitrate(): number {
        return this.contentPlaybackStateProxy ? this.contentPlaybackStateProxy.minBitrate : NaN;
    }
    set minBitrate(value: number) {
        if (this.contentPlaybackStateProxy) {
            this.transmitExtRequest(NotificationName.MIN_BITRATE, { value: value });
        }
        else {
            this.log(AppResources.messages.CONTENT_PLAYBACK_SETTING_IGNORED);
        }
    }

    @apiAccessor()
    get maxBitrate(): number {
        return this.contentPlaybackStateProxy ? this.contentPlaybackStateProxy.maxBitrate : NaN;
    }
    set maxBitrate(value: number) {
        if (this.contentPlaybackStateProxy) {
            this.transmitExtRequest(NotificationName.MAX_BITRATE, { value: value });
        }
        else {
            this.log(AppResources.messages.CONTENT_PLAYBACK_SETTING_IGNORED);
        }
    }

    @apiAccessor()
    get audioTrackInfo(): AudioTracksInterface {
        return this.contentPlaybackStateProxy ? this.contentPlaybackStateProxy.model.audioTracks : null;
    }

    @apiAccessor()
    get audioTrack(): AudioTrackInterface {
        return this.contentPlaybackStateProxy ? this.contentPlaybackStateProxy.model.audioTracks.track : null;
    }
    set audioTrack(track: AudioTrackInterface) {
        if (this.contentPlaybackStateProxy) {
            this.transmitExtRequest(NotificationName.SWITCH_AUDIO_TRACK, { value: track });
        }
        else {
            this.log(AppResources.messages.CONTENT_PLAYBACK_SETTING_IGNORED);
        }
    }

    @apiAccessor()
    get resource(): ResourceConfigurationInterface {
        return this.appMediator.getCurrentResource();
    }
    set resource(value: ResourceConfigurationInterface) {
        this.transmitExtRequest(NotificationName.PREP_RESOURCE_COLLECTION, { resources: [value], start: true, clear: true });
    }

    @apiAccessor()
    get textTrackEnabled(): boolean {
        return (<TextTrackProxy>this.facade.retrieveProxy(ProxyName.TextTrackProxy)).enabled;
    }
    set textTrackEnabled(value: boolean) {
        if (this.contentPlaybackStateProxy) {
            this.transmitExtRequest(NotificationName.SWITCH_TEXT_MODE, { value: value });
        }
        else {
            this.log(AppResources.messages.CONTENT_PLAYBACK_SETTING_IGNORED);
        }
    }

    @apiAccessor()
    get textTrack(): TextTrackInterface {
        return this.textTrackInfo ? this.textTrackInfo.track : null;
    }
    set textTrack(value: TextTrackInterface) {
        if (this.contentPlaybackStateProxy) {
            this.transmitExtRequest(NotificationName.SWITCH_TEXT_TRACK, { value: value });
        }
        else {
            this.log(AppResources.messages.CONTENT_PLAYBACK_SETTING_IGNORED);
        }
    }

    @apiAccessor()
    get textTrackInfo(): TextTracksInterface {
        return this.contentPlaybackStateProxy ? this.contentPlaybackStateProxy.textTrackInfo : null;
    }

    @apiAccessor()
    get streamType(): StreamType {
        return this.contentPlaybackStateProxy ? this.contentPlaybackStateProxy.model.streamType : null;
    }

    @apiAccessor()
    get isPlayingLive(): boolean {
        const lsi = this.contentPlaybackStateProxy ? this.contentPlaybackStateProxy.model.liveStreamInfo : null;
        return lsi && lsi.isPlayingLive === true;
    }

    @apiAccessor()
    get liveStreamUtcTime(): number {
        const lsi: LiveStreamInfoInterface = this.contentPlaybackStateProxy ? this.contentPlaybackStateProxy.model.liveStreamInfo : null;
        return lsi ? lsi.absoluteTime : NaN;
    }

    @apiAccessor()
    get liveStreamUtcDuration(): number {
        const lsi: LiveStreamInfoInterface = this.contentPlaybackStateProxy ? this.contentPlaybackStateProxy.model.liveStreamInfo : null;
        return lsi ? lsi.absoluteDuration : NaN;
    }

    @apiAccessor()
    get isSuspended(): boolean {
        return this.appMediator.isPlaybackSuspended();
    }

    @apiAccessor()
    get fullscreenElement(): HTMLElement {
        return this.appMediator.getFullscreenElement();
    }
    set fullscreenElement(value: HTMLElement) {
        this.appMediator.setFullscreenElement(value);
    }

    // end accessors

    // methods

    @apiMethod()
    enterFullscreen(): void {
        this.transmitExtRequest(NotificationName.ENTER_FULLSCREEN_REQUEST);
    }

    @apiMethod()
    exitFullscreen(): void {
        this.transmitExtRequest(NotificationName.EXIT_FULLSCREEN_REQUEST);
    }

    @apiMethod()
    play(): Promise<void> {
        if (this.appMediator.isPlaybackSuspended()) {
            this.transmitExtRequest(NotificationName.RESUME_PLAYBACK);
        }

        const note = { name: NotificationName.PLAY, type: NotificationType.EXTERNAL };
        return this.sendAsyncNotification(note, [PlayerEvent.CONTENT_PLAYING, PlayerEvent.AD_PLAYING], [PlayerEvent.AUTOPLAY_BLOCKED, PlayerEvent.VIDEO_PLAYBACK_ERROR, PlayerEvent.AD_ERROR]);
    }

    @apiMethod()
    pause(): Promise<void> {
        if (this.streamType === StreamType.LIVE) {
            this.log(AppResources.messages.PAUSING_LINEAR_LIVE_STREAM_NOT_ALLOWED);
            return this.stop();
        }
        else {
            const note = { name: NotificationName.PAUSE, type: NotificationType.EXTERNAL };
            return this.sendAsyncNotification(note, [PlayerEvent.CONTENT_PAUSED, PlayerEvent.AD_PAUSED]);
        }
    }

    @apiMethod()
    togglePlayPause(): void {
        const s = this.playbackState;
        const req = s === PlaybackState.PLAYING ? NotificationName.PAUSE :
            (s === PlaybackState.PAUSED ? NotificationName.PLAY : null);

        req && this.transmitExtRequest(req);
    }

    @apiMethod()
    seek(position: number): Promise<any> {
        const seekPos = this.appMediator.validateSeek(position, this.contentDuration);
        if (seekPos === null) {
            return Promise.resolve();
        }

        const note = { name: NotificationName.SEEK, body: { value: seekPos }, type: NotificationType.EXTERNAL };

        return this.sendAsyncNotification(note, [PlayerEvent.SEEK_COMPLETE], [PlayerEvent.VIDEO_PLAYBACK_ERROR, PlayerEvent.AD_ERROR]);
    }

    @apiMethod()
    getModelSnapshot(): ModelSnapshotInterface {
        return this.appMediator.getSnapshot();
    }

    @apiMethod()
    getAdBreakTimes(): AdCuePointInterface[] {
        return this.appMediator.getAdBreakTimes() || []
    }

    @apiMethod()
    grabFrame(): HTMLImageElement {
        return this.appMediator.grabFrame();
    }

    @apiMethod()
    getThumbnail(time: number): ThumbnailCueInterface { 
        return this.contentPlaybackStateProxy.getThumbnail(time);
    }

    @apiMethod()
    getContainerRect(): ClientRect | null {
        return this.appMediator.getContainerRect();
    }

    @apiMethod()
    registerPlugins(pc: PluginConfigInterface[], callback?: () => void): Promise<void> {
        return new Promise((resolve, reject) => {
            this.transmitExtRequest(NotificationName.LOAD_PLUGINS, {
                plugins: pc,
                callback: () => {
                    if (typeof callback === 'function') {
                        callback();
                    }
                    resolve();
                }
            });
        });
    }

    @apiMethod()
    removePlugin(name: string): void {
        this.transmitExtRequest(NotificationName.REMOVE_PLUGIN, {
            name: name
        });
    }

    @apiMethod()
    getPlugin(name: string): void {
        return this.appMediator.getPlugin(name);
    }

    @apiMethod()
    stop(): Promise<void> {
        const note = { name: NotificationName.STOP, type: NotificationType.EXTERNAL };
        return this.sendAsyncNotification(note, [PlayerEvent.RESOURCE_INTERRUPTED, PlayerEvent.RESOURCE_END]);
    }

    @apiMethod()
    goLive(): void {
        if (this.streamType && this.streamType !== StreamType.VOD) {
            this.transmitExtRequest(NotificationName.SEEK_TO_LIVE);
        }
    }

    @apiMethod()
    focus(options?: { preventScroll: boolean }): void {
        const main = this.dom.getMain();
        if (main) {
            main.focus(options);
        }
    }

    @apiMethod()
    blur(): void {
        const main = this.dom.getMain();
        if (main) {
            main.blur();
        }
    }

    @apiMethod()
    getConfigAsJson(spacing?: number): string {
        return this.appMediator.getConfigAsJson(spacing);
    }

    @apiMethod()
    getAutoplayCapabilities(): Promise<AutoplayInfoInterface> {
        return this.appMediator.getAutoplayCapabilities();
    }

    @apiMethod()
    suspendPlayback(): void {
        if (!this.appMediator.isPlaybackSuspended()) {
            this.pause().then(() => {
                this.transmitExtRequest(NotificationName.SUSPEND_PLAYBACK);
            });
        }
    }

    @apiMethod()
    resumePlayback(): void {
        if (this.appMediator.isPlaybackSuspended()) {
            this.transmitExtRequest(NotificationName.RESUME_PLAYBACK);
        }
    }

    // end methods
    ////////////////////
    // END PUBLIC API //
    ////////////////////

    /**
    * @ignore
    */
    sendEvent(name: string, data: StrAnyDict): void {
        this.emit(name, data);
    }

    /**
    * @ignore
    */
    sendErrorEvent(event: EventInterface): void {
        this.dispatchEvt(event);
    }

    private addNativePlugins(pluginsArray: PluginConfigInterface[], playerOptions: PlayerOptionsInterface): void {
        const bi = <BuildInfoServiceInterface>this.facade.retrieveService(ServiceName.BuildInfo),
            ver = bi.playerVersion,
            pi = playerOptions.nativePlugins;

        let i = pi && pi.length || 0, cfg;
        while (i--) {
            switch (pi[i]) {
                case NativePlugin.REPLAY:
                    cfg = AppResources.nativePluginConfig.replay;

                    cfg.url = cfg.url.replace('{{VER}}', ver);
                    pluginsArray.push(AppResources.nativePluginConfig.replay);

                    break;

                case NativePlugin.DIAGNOSTIC:
                    cfg = AppResources.nativePluginConfig.diagnostic;

                    cfg.url = cfg.url.replace('{{VER}}', ver);
                    pluginsArray.push(AppResources.nativePluginConfig.diagnostic);

                    break;
            }
        }
    }

    private get contentPlaybackStateProxy(): ContentPlaybackStateProxy {
        if (!this.pContentPlaybackStateProxy) {
            this.pContentPlaybackStateProxy = <ContentPlaybackStateProxy>this.facade.retrieveProxy(ProxyName.ContentPlaybackStateProxy);
        }
        return this.pContentPlaybackStateProxy;
    }

    private get localization(): LocalizationInterface {
        return this.facade.retrieveProxy(ProxyName.LocalizationProxy) as LocalizationProxy;
    }

    private get dom(): PlayerDomProxyInterface {
        return this.facade.retrieveProxy(ProxyName.PlayerDomProxy) as PlayerDomProxy;
    }

    private transmitExtRequest(name: NotificationName, data?: any): void {
        this.sendNotification(name, data, NotificationType.EXTERNAL);
    }

    private log(message: string, logLevelOverride?: LogLevel): void {
        const lv = logLevelOverride ? logLevelOverride : LogLevel.DEBUG;
        this.facade.log(lv, message)
    }

    private init(): void {

        const gServices = this.opts.globalServices,
            logLvl = this.opts.playerOptions.logLevel ? this.opts.playerOptions.logLevel : LogLevel.OFF;

        this.registerGlobalServices(gServices);
        this.createLoggingService(logLvl);
        this.createAutoplayCapabilitiesService();
        this.createApplicationMediator(MediatorName.APPLICATION);

        const sys = <SystemServiceInterface>gServices[ServiceName.System],
            isWebMaf = sys.isWebMaf,
            bootCom = isWebMaf ? NotificationName.BOOT_WEBMAF_APP : NotificationName.BOOT_APP;

        delete this.opts.globalServices;

        this.sendNotification(bootCom, {
            playerOptions: this.opts.playerOptions || null,
            app: this
        }, NotificationType.INTERNAL);
    }

    private sendAsyncNotification(notification: NotificationInterface, event: string[], errorEvent: string[] = []): Promise<any> {
        return new Promise((resolve, reject) => {
            errorEvent.push(PlayerEvent.FATAL_ERROR);

            const success = (event: EventInterface) => {
                remove(); //eslint-disable-line
                resolve(event);
            };

            const fail = (error: any) => {
                remove(); //eslint-disable-line
                reject(error);
            };

            const remove = () => {
                event.forEach(type => this.off(type, success));
                errorEvent.forEach(type => this.off(type, fail));
            };

            try {
                event.forEach(type => this.on(type, success));
                errorEvent.forEach(type => this.on(type, fail));
                this.sendNotification(notification.name, notification.body, notification.type);
            }
            catch (error) {
                fail(error);
            }
        });
    }
}
