import { AbrSwitchCommand } from '../controller/AbrSwitchCommand';
import { BootCommand } from "../controller/boot/BootCommand";
import { BootWebMafCommand } from "../controller/boot/BootWebMafCommand";
import { HandleErrorCommand } from '../controller/HandleErrorCommand';
import { KillResourcePresentationCommand } from '../controller/KillResourcePresentationCommand';
import { LocalizationCommand } from "../controller/LocalizationCommand";
import { PlaybackCommand } from "../controller/PlaybackCommand";
import { PlaylistCommand } from '../controller/PlaylistCommand';
import { PrepPlaybackCommand } from "../controller/PrepPlaybackCommand";
import { PrepResourceCommand } from "../controller/PrepResourceCommand";
import { PresentationStateChangeCommand } from '../controller/PresentationStateChangeCommand';
import { ReadyCommand } from '../controller/ReadyCommand';
import { ResumePlaybackCommand } from '../controller/ResumePlaybackCommand';
import { RetrieveResourceCommand } from '../controller/RetrieveResourceCommand';
import { StartPresentationCommand } from '../controller/StartPresentationCommand';
import { SuspendPlaybackCommand } from '../controller/SuspendPlaybackCommand';
import { TransformResourceCommand } from '../controller/TransformResourceCommand';
import { UserKeyboardCommand } from '../controller/UserKeyboardCommand';
import { NotificationName } from "../enum/NotificationName";
import { NotificationCommandMapInterface } from '../iface';


export const command_map: NotificationCommandMapInterface = {
    [NotificationName.DEFAULT]: null,
    [NotificationName.BOOT_APP]: BootCommand,
    [NotificationName.BOOT_WEBMAF_APP]: BootWebMafCommand,
    [NotificationName.READY]: ReadyCommand,

    [NotificationName.PLAY_ON_USER_GESTURE]: PlaybackCommand,
    [NotificationName.PLAY]: PlaybackCommand,
    [NotificationName.PAUSE]: PlaybackCommand,
    [NotificationName.STOP]: PlaybackCommand,
    [NotificationName.SEEK]: PlaybackCommand,
    [NotificationName.SEEK_FORWARD]: PlaybackCommand,
    [NotificationName.SEEK_BACK]: PlaybackCommand,
    [NotificationName.SEEK_TO_LIVE]: PlaybackCommand,
    [NotificationName.MUTE]: PlaybackCommand,
    [NotificationName.UNMUTE]: PlaybackCommand,
    [NotificationName.VOLUME_CHANGE]: PlaybackCommand,
    [NotificationName.SWITCH_AUDIO_TRACK]: PlaybackCommand,
    [NotificationName.SWITCH_TEXT_TRACK]: PlaybackCommand,
    [NotificationName.SWITCH_TEXT_MODE]: PlaybackCommand,

    [NotificationName.SUSPEND_PLAYBACK]: SuspendPlaybackCommand,
    [NotificationName.RESUME_PLAYBACK]: ResumePlaybackCommand,

    [NotificationName.SWITCH_BITRATE]: AbrSwitchCommand,
    [NotificationName.SWITCH_QUALITY_CATEGORY]: AbrSwitchCommand,
    [NotificationName.AUTO_QUALITY_SWITCHING]: AbrSwitchCommand,
    [NotificationName.MIN_BITRATE]: AbrSwitchCommand,
    [NotificationName.MAX_BITRATE]: AbrSwitchCommand,


    [NotificationName.PREP_RESOURCE_COLLECTION]: PrepResourceCommand,
    [NotificationName.VALIDATE_RESOURCE]: PrepResourceCommand,
    [NotificationName.REGISTER_RESOURCE]: PrepResourceCommand,
    [NotificationName.REGISTER_RESOURCE]: PrepResourceCommand,
    [NotificationName.RETRIEVE_RESOURCE]: RetrieveResourceCommand,
    [NotificationName.TRANSFORM_RESOURCE]: TransformResourceCommand,
    [NotificationName.START_PRESENTATION]: StartPresentationCommand,

    [NotificationName.PLAYLIST_ADVANCED]: PlaylistCommand,
    [NotificationName.PLAYLIST_COMPLETE]: PlaylistCommand,
    [NotificationName.PLAYLIST_CLEARED]: PlaylistCommand,
    [NotificationName.PLAYLIST_CURRENT_RESOURCE_CLEARED]: PlaylistCommand,

    [NotificationName.PLAY_RESOURCE]: PrepPlaybackCommand,
    [NotificationName.KILL_RESOURCE_PRESENTATION]: KillResourcePresentationCommand,

    [NotificationName.RESOURCE_INTERRUPTED]: PresentationStateChangeCommand,

    [NotificationName.VIDEO_START_ERROR]: HandleErrorCommand,
    [NotificationName.VIDEO_PLAYBACK_ERROR]: HandleErrorCommand,
    [NotificationName.RESOURCE_ERROR]: HandleErrorCommand,
    [NotificationName.AD_ERROR]: HandleErrorCommand,
    [NotificationName.AD_STALLED]: HandleErrorCommand,

    // PresentationStateChangeCommand is a sink for any events 
    // which are to be emitted externally.  If special treatment is needed
    // a custom command may be used.
    [NotificationName.PLAYBACK_SUSPENDED]: PresentationStateChangeCommand,
    [NotificationName.PLAYBACK_RESUMED]: PresentationStateChangeCommand,

    [NotificationName.POSTER_CLICK]: PresentationStateChangeCommand,
    [NotificationName.PLAYLIST_CHANGE]: PresentationStateChangeCommand,

    [NotificationName.RESOURCE_START]: PresentationStateChangeCommand,
    [NotificationName.VIDEO_PROGRESS]: PresentationStateChangeCommand,
    [NotificationName.QUALITY_CHANGE]: PresentationStateChangeCommand,
    [NotificationName.QUALITY_INFO_CHANGE]: PresentationStateChangeCommand,
    [NotificationName.CDN_CHANGE]: PresentationStateChangeCommand,
    [NotificationName.DRM_KEYSYSTEM_CREATED]: PresentationStateChangeCommand,

    [NotificationName.PRESENTATION_SIZE_CHANGE]: PresentationStateChangeCommand,
    [NotificationName.FULLSCREEN_ENTER]: PresentationStateChangeCommand,
    [NotificationName.FULLSCREEN_EXIT]: PresentationStateChangeCommand,
    [NotificationName.CONTENT_TIME_UPDATE]: PresentationStateChangeCommand,
    [NotificationName.CONTENT_BUFFERING]: PresentationStateChangeCommand,
    [NotificationName.CONTENT_START]: PresentationStateChangeCommand,
    [NotificationName.CONTENT_DURATION_AVAILABLE]: PresentationStateChangeCommand,
    [NotificationName.CONTENT_SEGMENT_START]: PresentationStateChangeCommand,
    [NotificationName.CONTENT_PLAYING]: PresentationStateChangeCommand,
    [NotificationName.CONTENT_PAUSED]: PresentationStateChangeCommand,
    [NotificationName.CONTENT_SEEKING]: PresentationStateChangeCommand,
    [NotificationName.CONTENT_SEEKED]: PresentationStateChangeCommand,
    [NotificationName.SEEK_REDIRECT_START]: PresentationStateChangeCommand,
    [NotificationName.SEEK_REDIRECT_COMPLETE]: PresentationStateChangeCommand,
    [NotificationName.CONTENT_SEGMENT_END]: PresentationStateChangeCommand,
    [NotificationName.CONTENT_COMPLETE]: PresentationStateChangeCommand,
    [NotificationName.CONTENT_IS_LIVE_CHANGED]: PresentationStateChangeCommand,
    [NotificationName.STREAM_TYPE_CHANGE]: PresentationStateChangeCommand,
    [NotificationName.STREAM_METADATA]: PresentationStateChangeCommand,
    [NotificationName.RESOURCE_END]: PresentationStateChangeCommand,
    [NotificationName.RESOURCE_COMPLETE]: PresentationStateChangeCommand,

    [NotificationName.AD_CUEPOINTS_AVAILABLE]: PresentationStateChangeCommand,
    [NotificationName.AD_BREAK_START]: PresentationStateChangeCommand,
    [NotificationName.AD_BREAK_METADATA]: PresentationStateChangeCommand,
    [NotificationName.AD_BREAK_COMPLETE]: PresentationStateChangeCommand,
    [NotificationName.AD_START]: PresentationStateChangeCommand,
    [NotificationName.AD_PAUSED]: PresentationStateChangeCommand,
    [NotificationName.AD_PLAYING]: PresentationStateChangeCommand,
    [NotificationName.AD_TIME_UPDATE]: PresentationStateChangeCommand,
    [NotificationName.AD_FIRST_QUARTILE]: PresentationStateChangeCommand,
    [NotificationName.AD_MIDPOINT]: PresentationStateChangeCommand,
    [NotificationName.AD_THIRD_QUARTILE]: PresentationStateChangeCommand,
    [NotificationName.AD_COMPLETE]: PresentationStateChangeCommand,
    [NotificationName.AD_BREAK_COMPLETE]: PresentationStateChangeCommand,

    [NotificationName.AD_STALLED]: PresentationStateChangeCommand,
    [NotificationName.AD_STALLED]: PresentationStateChangeCommand,
    [NotificationName.AD_BUFFERING]: PresentationStateChangeCommand,
    [NotificationName.AD_CLICK]: PresentationStateChangeCommand,
    [NotificationName.AD_SKIPPED]: PresentationStateChangeCommand,

    [NotificationName.VIDEO_LOAD_COMPLETE]: PresentationStateChangeCommand,
    [NotificationName.METADATA_CUEPOINT]: PresentationStateChangeCommand,
    [NotificationName.TEXT_CUEPOINT]: PresentationStateChangeCommand,
    [NotificationName.TEXT_TRACK_DISPLAY_MODE_CHANGE]: PresentationStateChangeCommand,
    [NotificationName.TEXT_TRACK_CHANGE]: PresentationStateChangeCommand,
    [NotificationName.TEXT_TRACK_INFO_CHANGE]: PresentationStateChangeCommand,
    [NotificationName.TEXT_TRACK_AVAILABLE]: PresentationStateChangeCommand,
    [NotificationName.AUDIO_TRACK_INFO_CHANGE]: PresentationStateChangeCommand,
    [NotificationName.AUDIO_TRACK_CHANGE]: PresentationStateChangeCommand,
    [NotificationName.THUMBNAIL_TRACK_AVAILABLE]: PresentationStateChangeCommand,

    [NotificationName.BEFORE_CONTROLS_VISIBLE]: PresentationStateChangeCommand,
    [NotificationName.BEFORE_CONTROLS_HIDDEN]: PresentationStateChangeCommand,
    [NotificationName.CONTROLS_VISIBLE]: PresentationStateChangeCommand,
    [NotificationName.CONTROLS_HIDDEN]: PresentationStateChangeCommand,

    [NotificationName.MOUSE_ENTER_PRESENTATION]: PresentationStateChangeCommand,
    [NotificationName.MOUSE_LEAVE_PRESENTATION]: PresentationStateChangeCommand,
    [NotificationName.USER_PAUSE]: PresentationStateChangeCommand,
    [NotificationName.USER_SETTINGS_REQUEST]: PresentationStateChangeCommand,

    [NotificationName.AUTOPLAY_BLOCKED]: PresentationStateChangeCommand,
    [NotificationName.PLAYLIST_OUT_OF_RANGE]: PresentationStateChangeCommand,
    // END PresentationStateChangeCommand 

    [NotificationName.CHANGE_LANGUAGE]: LocalizationCommand,
    [NotificationName.LANGUAGE_CHANGE]: LocalizationCommand,
    [NotificationName.USER_KEY_COMMAND]: UserKeyboardCommand,

}
