
export enum Browser {

    /** */
    ANDROID = 'Android',

    /** */
    CHROME = 'Chrome',

    /** */
    CHROME_IOS = "Chrome-iOS",

    /** */
    FIREFOX = 'Firefox',

    /** */
    FIREFOX_IOS = 'Firefox-iOS',

    /** */
    EDGE = 'Edge',

    /** */
    MSIE = 'MSIE',

    /** */
    SAFARI = 'Safari',

    /** */
    OPERA = 'Opera',

    /** */
    OPERA_MOBILE = 'Opera-Mobile',

    /** */
    SILK = 'Silk',

    /** PlayStation 4 WebMaf*/
    PLAYSTATION = 'PlayStation',

    /** The browser is unknown */
    UNKNOWN = 'Unknown'
}
